<template>
  <a href="/">
    <el-image :src="defaultLogo"></el-image>
  </a>
</template>

<script>
import defaultLogo from '@/static/images/logo-black.png';

export default {
  data() {
    return { defaultLogo };
  }
};
</script>

<style lang="scss" scoped>
img {
  display: inline-block;
  margin-top: 6px;
  height: 36px;
  border-radius: 4px;
}

a {
  height: 100%;
  color: white;
  transition: color 0.5s;
}

::v-deep .el-image {
  height: 80%;
  .el-image__inner {
    width: auto;
    vertical-align: middle;
  }
}
</style>
