import { Enum } from '@/common/config';
import DefaultLogo from './default/Logo.vue';
import DefaultAvatar from './default/Avatar.vue';

const Layout = {};

switch (process.env.VUE_APP_CURRENT_CONTEXT) {
  case Enum.APP_CONTEXT.RABYTE:
    Layout.Logo = DefaultLogo;
    Layout.Avatar = DefaultAvatar;
    Layout.MenuItems = null;
    break;
}

export default Layout;
