import { createApp } from '@vue/composition-api';
import Toast from './toast';

export default function(content, { el, duration } = {}) {
  // console.log(content, el, duration);
  return new Promise(resolve => {
    const $dom = document.createElement('div');
    (el || document.body).appendChild($dom);
    const instance = createApp(Toast, {
      el,
      content
    });
    instance.mount($dom);
    setTimeout(() => {
      instance.unmount();
      resolve();
    }, duration || 2000);
  });
}
