<template>
  <div class="cp-side-adv-modal-wrap" v-if="isVisible">
    <i class="el-icon-close btn-close" @click="handleClose"></i>
    <div class="item-wrap">
      <h2>更多精彩尽在Alpha派APP</h2>
      <dl>
        <dt>
          <img
            src="https://alphalink.rabyte.cn/external/alpha/data_storage/file/calling-card/2023-07-19/web-side-download-qrcode"
            alt=""
          />
        </dt>
        <dd>扫描二维码<br />下载Alpha派</dd>
      </dl>
    </div>
    <div
      v-if="customerManager && customerManager.mobile"
      class="item-wrap with-border"
    >
      <h2>联系专属客户经理了解更多</h2>
      <dl>
        <dt><img :src="customerManager.wxCardUrl" alt="" /></dt>
        <dd>
          <b>{{ customerManager.name }}</b>
          <br />{{ customerManager.mobile }}
        </dd>
      </dl>
    </div>
    <el-checkbox v-model="isChecked" class="checkbox-wrap">
      不再显示
    </el-checkbox>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

const storageKey = 'pc-admin-side-adv-modal-storage';

export default {
  data() {
    return {
      isVisible: false,
      isChecked: false
    };
  },
  computed: {
    ...mapGetters(['user']),
    customerManager() {
      return this.$store.state.readingStore.customerManager;
    }
  },
  mounted() {
    // 用户未登录时，不执行
    if (!this.user) {
      return;
    }
    let data = localStorage.getItem(storageKey);
    // 没有缓存，说明之前没有访问过，展示弹窗
    if (!data) {
      this.showModal();
      return;
    }
    const curDate = this.getCurrentDate();
    try {
      data = JSON.parse(data);
      // 如果当前用户与历史记录用户不同 || 未勾「不再展示」并且当前日期与上次展示日期不同
      if (
        this.user.mobile !== data.flag ||
        (!data.neverShow && curDate !== data.dateOflastTime)
      ) {
        this.showModal(curDate);
      }
    } catch (e) {
      // 出现运行错误时，展示弹窗，防止弹窗永远不展示
      this.showModal(curDate);
    }
  },
  methods: {
    getCurrentDate() {
      return dayjs().format('YYYY-MM-DD');
    },
    showModal(date) {
      this.isVisible = true;
      localStorage.setItem(
        storageKey,
        JSON.stringify({
          dateOflastTime: date || this.getCurrentDate(),
          flag: this.user.mobile
        })
      );
    },
    handleClose() {
      this.isVisible = false;
      if (this.isChecked) {
        localStorage.setItem(
          storageKey,
          JSON.stringify({
            neverShow: 1,
            flag: this.user.mobile
          })
        );
      } else {
        localStorage.setItem(
          storageKey,
          JSON.stringify({
            dateOflastTime: this.getCurrentDate(),
            flag: this.user.mobile
          })
        );
      }
    }
  }
};
</script>
<style lang="scss">
.cp-side-adv-modal-wrap {
  z-index: 10000;
  position: fixed;
  right: 20px;
  bottom: 28px;
  padding: 24px 20px 16px;
  width: 203px;
  border-radius: 10px;
  background: linear-gradient(180deg, #dfeaff 0%, #fff 20.83%), #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  .btn-close {
    position: absolute;
    top: 12px;
    right: 12px;
    color: $font-b-3;
    cursor: pointer;
    &:hover {
      color: $theme-b-2;
    }
  }
  .item-wrap {
    padding: 16px 0;
    &.with-border {
      border-top: 1px solid $font-b-5;
    }
    h2 {
      margin-bottom: 8px;
      line-height: 20px;
      font-size: 12px;
      font-weight: 500;
      color: $theme-b-1;
      text-align: center;
    }
    dl {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 20px;
      font-size: 12px;
      dt {
        width: 80px;
        height: 80px;
        margin-right: 8px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      dd {
        color: $font-b-2;
      }
    }
  }
  .checkbox-wrap {
    display: table;
    margin: auto;
    line-height: 22px;
    color: $font-b-4;
    .el-checkbox__label {
      padding-left: 6px;
    }
    .el-checkbox__inner {
      border-color: $font-b-4 !important;
    }
    .is-checked .el-checkbox__inner {
      border-color: $theme-b-1 !important;
    }
  }
}
</style>
