import axios from 'axios';
import { prefetchApps, registerMicroApps, start } from 'qiankun';
import { AppModules } from './modules';
import { Constants } from '@/adapters/config';

// 注册子应用路由
export async function registerMicroRoutes(router) {
  let apps;

  if (Constants.MICRO_APPS_REGISTERED.length) {
    apps = await axios(Constants.MICRO_APPS_MANIFEST)
      .then(({ data = {} }) => {
        const { apps = [] } = data;
        return [...apps];
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error('[microapp getAsyncRoutes Error]', err);
        return [];
      });
  } else {
    apps = [];
  }

  apps = apps.filter(app =>
    (Constants.MICRO_APPS_REGISTERED || []).includes(app.name)
  );

  if (apps.length) {
    [...AppModules].forEach(async app => {
      if (app.getAsyncRoutes && typeof app.getAsyncRoutes === 'function') {
        await app.getAsyncRoutes(router, apps);
      }
    });
  }

  return apps;
}

// 启动子应用
export function startMicroApps(apps) {
  prefetchApps(apps.map(({ name, entry }) => ({ name, entry })));

  apps.forEach(app => {
    const container = document.createElement('div');
    container.id = app.name;
    document.body.appendChild(container);
  });

  registerMicroApps(
    apps.map(app => {
      return {
        ...app,
        entry: app.entry,
        container: '#' + app.name,
        activeRule: ({ pathname = '' } = {}) => pathname.startsWith(app.path),
        props: { appConfig: app }
      };
    })
  );

  start();
}
