<template>
  <div>
    <ModalWrap
      title="温馨提示"
      :closeable="false"
      confirmText="手机在身边，立即验证"
      cancelText="手机不在身边，稍后验证"
      @confirm="handleConfirm"
      @cancel="handleCancel"
    >
      <div slot="content">
        您的登录手机号（{{
          mobile
        }}）尚未通过验证码校验，超过48小时未验证，将无法继续使用Alpha派
      </div>
    </ModalWrap>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  computed,
  getCurrentInstance,
  onMounted
} from '@vue/composition-api';
import SetTracker from '@/common/utils/setTracker';
import ModalWrap from '@/common/components/ui/password-modal-wrap';

export default defineComponent({
  components: {
    ModalWrap
  },
  emits: ['close', 'showModal'],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const $vm = getCurrentInstance();
    const action = ref(0);
    const isVisible = ref(false);
    const isSubmitting = ref(false);
    const prevForm = ref({});
    const mobile = computed(() => {
      const { user } = $vm.proxy.$store.state.global;
      return user ? `${user.regionCode} ${user.mobile}` : '';
    });
    const handleCancel = () => {
      SetTracker('Web_Login_First_Verification_Pop_Click', {
        button_name: '稍后验证'
      });
      context.emit('close');
    };
    const handleConfirm = () => {
      SetTracker('Web_Login_First_Verification_Pop_Click', {
        button_name: '立即验证'
      });
      context.emit('showModal');
    };
    onMounted(() => {
      isVisible.value = true;
    });
    return {
      isVisible,
      action,
      isSubmitting,
      prevForm,
      mobile,
      handleCancel,
      handleConfirm
    };
  }
});
</script>
