/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:55
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-04-01 14:47:47
 */
import { Service } from '@alpha-pai/base';
import { showPvText, showDateText } from '../utils';

class ReadProfileService extends Service {
  // 获取我的收藏-列表
  async getFavoriteList(options = {}) {
    const { readProfileDao } = this.dataflow.dao;
    const res = await readProfileDao.getFavoriteList(options);
    return this.collectListFormat(res.data);
  }

  // 获取阅读历史-列表
  async getHistoryList(options = {}) {
    const { readProfileDao } = this.dataflow.dao;
    const { HistoryListResponse } = this.dataflow.formatters;
    const res = await readProfileDao.getHistoryList(options);
    return new HistoryListResponse(res.data);
  }

  collectListFormat(data) {
    return {
      total: data?.total || 0,
      list: (data?.list || []).map(l => {
        let item = {
          ...l,
          title: l?.title,
          industrys: (l.industry || []).map(i => i.name),
          stocks: (l.stock || []).map(i => i.name),
          time: l?.date || '',
          data: showDateText(l.date),
          avatar: l.url,
          name: l?.sourceName || '',
          pv: showPvText(l.pv),
          rank: l.accountRank,
          featureHas: l.feature,
          feature: l.feature
            ? l.feature
                .filter(x => x !== '机构观点')
                .filter(x => x !== '产业资讯')
            : []
        };
        if (l.fullJson) {
          if (l.type === 321) {
            item = {
              ...item,
              ...JSON.parse(item.fullJson)
            };
          } else if (l.type === 35) {
            const d = JSON.parse(item.fullJson);
            item = {
              ...item,
              ...d,
              industry: l.industry || [],
              teamName: d?.teamName?.[0]?.name,
              analyst: d?.analyst?.[0]
                ? {
                    icon: d?.analyst?.[0]?.logo,
                    name: d?.analyst?.[0]?.name
                  }
                : {
                    icon: '',
                    name: ''
                  },
              sector: d?.sector || [],
              commentType:
                JSON.parse(item.fullJson).type === 'txt' ? 'text' : 'img',
              type: 35
            };
          } else {
            const {
              aiFlag,
              pptFlag,
              radio,
              recorder,
              hasCoreData,
              roadshowDate,
              institutionalReservation,
              marketTypeV2,
              containsPpt
            } = JSON.parse(l.fullJson);
            item = {
              ...item,
              pptFlag,
              aiFlag,
              radio,
              recorder,
              hasCoreData,
              roadshowDate,
              institutionalReservation,
              marketTypeV2,
              containsPpt
            };
          }
        }
        return item;
      })
    };
  }
}

export default ReadProfileService;
