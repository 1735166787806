const plateStore = {
  namespaced: true,
  state: {
    count: 0,
    addedStockCode: '' // 个股主页-自选管理-搜索 加自选时修改，在个股列表中读取，实现添加的个股自动进入视口
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    setAddedStockCode(state, data) {
      state.addedStockCode = data;
    }
  },
  actions: {
    incrementAsync({ commit }) {
      setTimeout(() => {
        commit('increment');
      }, 1000);
    }
  },
  getters: {
    getCount(state) {
      return state.count;
    }
  }
};

export default plateStore;
