import AppLayout from '@alpha-pai/ui/src/layout';

const MicroApp = () =>
  import(/* webpackChunkName: "micro-app" */ '../pages/MicroApp').then(
    component => component
  );

const routes = [
  // {
  //   path: '/',
  //   component: AppLayout,
  //   children: [
  //     {
  //       path: '/micro-seed*',
  //       component: MicroApp,
  //       meta: {
  //         name: 'micro-seed',
  //         title: '种子项目',
  //         requiresAuth: true
  //         // requiresURIAuth: true
  //       }
  //     }
  //   ]
  // }
];

export default routes;

export async function getAsyncRoutes(router, apps) {
  const routes = [
    {
      path: '*',
      redirect: '/error/404'
    }
  ];

  apps.forEach(app => {
    const { path, meta } = app;
    routes.push({
      path: '/',
      component: AppLayout,
      children: [
        {
          path: `${path}*`,
          component: MicroApp,
          meta: { ...meta, appConfig: app }
        }
      ]
    });
  });

  router.addRoutes(routes);
}
