const nav = [
  {
    key: 'examples',
    name: '示例模块',
    icon: 'el-icon-video-camera',
    children: [
      { name: '示例列表', path: '/examples', icon: 'el-icon-star-on' },
      {
        name: '示例10001',
        path: '/examples/100071',
        icon: 'el-icon-star-on'
      },
      {
        name: '示例10002',
        path: '/examples/100072'
      }
    ]
  },
  {
    key: 'point',
    name: '机构观点',
    icon: 'el-icon-video-camera',
    path: '/reading/home/report',
    children: []
  },
  {
    key: 'news',
    name: '产业资讯',
    icon: 'el-icon-video-camera',
    path: '/reading/home/news',
    children: []
  },
  {
    key: 'meeting',
    name: '会议速记',
    icon: 'el-icon-video-camera',
    path: '/reading/home/meeting',
    children: []
  }
];

export default nav;
