<template>
  <dialog-wrap
    dialog-width="486px"
    body-padding="0"
    confirmText="添加"
    :visible.sync="dialogVisible"
    :title="title"
    :showBtn="false"
  >
    <div slot="content" class="content">
      <div class="dialog-title">关于我们</div>
      <div class="logo-container">
        <img
          class="logo-img"
          :src="require('../../assets/images/alpha-logo-text-describe.png')"
          alt=""
        />
        <div class="version">版本 V2.0.16</div>
      </div>
      <div class="contact-info">
        <div class="row">
          <span class="title">联系邮箱</span>
          <span class="value">alphapai_support@rabyte.cn</span>
        </div>
        <div class="row">
          <span class="title">联系电话</span>
          <span class="value">{{ mobile }}</span>
        </div>
        <div class="row">
          <span class="title">专属客户经理</span>
          <div class="top">
            <div class="left">
              <div class="name">{{ innerInfo.name }}</div>
              <div class="tel">{{ innerInfo.mobile }}</div>
            </div>
            <div class="right">
              <div class="qr-code">
                <img :src="innerInfo.wxCardUrl" alt="" />
              </div>
              <div class="tip">扫码添加好友</div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <span class="item">讯兔科技有限公司</span>
        <span class="item"
          >官网：<span class="linked">www.rabyte.cn</span></span
        >
      </div>
    </div>
  </dialog-wrap>
</template>

<script>
import { MOBILE_SHOW } from '@/common/config/constants';
import DialogWrap from '@/common/components/dialog/DialogWrap.vue';

export default {
  components: {
    DialogWrap
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mobile: MOBILE_SHOW
    };
  },
  computed: {
    innerInfo() {
      return this.$store.state.readingStore.customerManager;
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      }
    },
    title() {
      return '';
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  background: linear-gradient(181.02deg, #dfeaff 0.87%, #ffffff 51.26%), #ffffff;
  border-radius: 10px;
  min-height: 100px;
  padding-bottom: 24px;
  .dialog-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    letter-spacing: 0.06em;
    color: $font-b-1;
    padding: 24px;
  }
  .logo-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .logo-img {
      width: 199px;
    }
    .description {
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.6em;
      color: $theme-b-1;
    }
    .version {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $font-b-3;
      margin-top: 18px;
    }
  }
  .contact-info {
    margin-top: 24px;
    margin-left: 80px;
    font-size: 14px;
    line-height: 22px;
    color: $font-b-1;
    .row {
      margin-top: 8px;
      .title {
        margin-right: 16px;
      }
      .value {
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 324px;
        height: 124px;
        padding: 0 16px;
        border-radius: 5px;
        border: 1px solid #fff;
        background: url('@/static/images/card-background.png') no-repeat center
          center;
        background-size: 100% 100%;
        .left {
          .name {
            color: $theme-b--4;
            font-family: PingFang SC;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 140% */
          }
          .tel {
            margin-top: 2px;
            color: $theme-b--4;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
        .right {
          .qr-code {
            width: 82px;
            height: 82px;
            border-radius: 5px;
            border: 1px solid $theme-b-2;
            overflow: hidden;
            > img {
              height: 100%;
              width: 100%;
            }
          }
          .tip {
            margin-top: 8px;
            color: $theme-b--4;
            text-align: center;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px; /* 100% */
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    margin-top: 24px;
    .item {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: $font-b-2;
      padding: 0 12px;
      position: relative;
      &::after {
        content: '';
        width: 1px;
        height: 12px;
        background: $border-color;
        position: absolute;
        right: 0;
        top: 5px;
      }
      &:last-of-type {
        &::after {
          background: transparent;
        }
      }
      .linked {
        color: $theme-b-1;
      }
    }
  }
}
</style>
