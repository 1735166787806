<template>
  <div class="form-item-wrap">
    <div class="form-label">
      <span>{{ label }}</span>
      <slot name="label" />
    </div>
    <el-form-item size="medium">
      <slot />
    </el-form-item>
    <div class="form-error">
      <slot name="error" />
      <div v-if="!$slots.error && errorMsg" class="form-error-msg">
        {{ errorMsg }}
      </div>
      <slot name="tips" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    errorMsg: {
      type: String,
      default: ''
    }
  }
});
</script>
<style lang="scss" scoped>
.form-item-wrap {
  position: relative;
  .form-label {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    line-height: 22px;
    color: $font-b-2;
    font-size: 14px;
  }
  .form-error {
    min-height: 16px;
    .form-error-msg {
      overflow: hidden;
      font-size: 12px;
      line-height: 1em;
      padding-top: 4px;
      color: #ef404a;
    }
  }
  ::v-deep {
    + .cp-saas-admin-button {
      margin-top: 8px;
    }
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__content {
        line-height: 22px;
        .el-input--medium {
          .el-input__inner {
            height: 34px;
            line-height: 34px;
          }
        }
      }
    }
  }
}
</style>
