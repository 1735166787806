import state from '@/modules/ai-chat/store/chat/state';
import getters from '@/modules/ai-chat/store/chat/getters';
import mutations from '@/modules/ai-chat/store/chat/mutations';
import actions from '@/modules/ai-chat/store/chat/actions';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
