<template>
  <el-form>
    <FormItem :label="label" :error-msg="errorMsg">
      <el-input
        style="width: 60%; float: left"
        class="user-code"
        maxlength="10"
        :placeholder="placeholder"
        :value="value"
        @input="handleInput"
      />
      <Button
        type="primary"
        class="btn"
        :content="buttonText"
        v-loading="smsLoading"
        :disabled="disabled"
        @click="getCode"
      />
      <div slot="tips" class="tips">
        <span>如未收到验证码，请</span>
        <Button
          v-if="isLogined"
          type="link"
          underline
          @click="handleShowManager"
          >联系专属客户经理</Button
        >
        <span v-else class="service-tel">联系客服：400-082-1099</span>
      </div>
    </FormItem>
  </el-form>
</template>

<script>
import FormItem from '@/common/components/ui/form-item';
import Button from '@/common/components/ui/button';
import dam from '@/common/components/dedicated-account-manager';
import { sendMobileAuthorizationCode } from '@/services/user';

export default {
  components: {
    FormItem,
    Button
  },

  props: {
    label: {
      type: String,
      default: '验证码'
    },
    placeholder: {
      type: String,
      default: '请输入短信验证码'
    },
    errorMsg: {
      type: String,
      default: ''
    },
    mobile: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      smsLoading: false,
      count: 0,
      code: '',
      alreadySendCode: false
    };
  },

  computed: {
    isLogined() {
      return !!this.$store.state.global?.user?.uid;
    },
    disabled() {
      return this.smsLoading || this.count > 0;
    },
    buttonText() {
      return this.count > 0
        ? `重新发送(${this.count}s)`
        : this.alreadySendCode
        ? '重新发送'
        : '获取验证码';
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.trim());
    },
    handleShowManager() {
      dam(this.$store, {
        enterBy: '手机验证码校验弹窗'
      });
    },
    getCode() {
      if (this.smsLoading || !this.mobile) {
        return;
      }
      this.smsLoading = true;
      this.alreadySendCode = true;
      sendMobileAuthorizationCode({
        mobile: this.mobile
      })
        .then(res => {
          if (res) {
            this.$message({
              type: 'success',
              message: '验证码已发送，请注意查收！'
            });
            this.countdown(59);
            return Promise.resolve();
          }
          return Promise.reject(res);
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: err.message
          });
        })
        .finally(() => {
          this.smsLoading = false;
        });
    },
    countdown(x) {
      this.count = x;
      if (x > 0) {
        setTimeout(() => this.countdown(x - 1), 1000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';
.btn {
  width: 37%;
  padding-left: 0;
  padding-right: 0;
  float: right;
  text-align: center;
}

.tips {
  position: relative;
  padding-top: 4px;
  padding-bottom: 16px;
  line-height: 20px;
  color: $font-b-3;
  font-size: 12px;
  .service-tel {
    color: $font-b-2;
  }

  ::v-deep {
    .cp-saas-admin-button {
      line-height: inherit;
      font-size: inherit;
    }
  }
}
</style>
