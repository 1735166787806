<template>
  <FreeVersionModal v-if="isVisible" class="mei-shi-modal-wrap">
    <div class="mei-shi-modal">
      <div class="benefits-desc">
        恭喜您获得<span class="benefits-duration">60天</span>Alpha派专业版体验期
      </div>
      <Button
        class="benefits-btn"
        block
        type="primary"
        content="我知道了"
        @click="handleClose"
      />
    </div>
  </FreeVersionModal>
</template>
<script>
import {
  computed,
  ref,
  defineComponent,
  onMounted,
  getCurrentInstance
} from '@vue/composition-api';
import { removeUserFlag } from '@/services/user';
import FreeVersionModal from '@/common/components/free-version-modal';
import Button from '@/common/components/ui/button';

export default defineComponent({
  components: {
    FreeVersionModal,
    Button
  },
  setup() {
    const $vm = getCurrentInstance();
    const { $store } = $vm.proxy;
    const isVisible = ref(false);
    const user = computed(() => $store.state.global.user || {});
    const handleClose = () => {
      isVisible.value = false;
      removeUserFlag({
        propertyName: 'threeMonthExperienceFlag'
      });
    };
    onMounted(() => {
      if (user.value.threeMonthExperienceFlag === 1) {
        isVisible.value = true;
      }
    });
    return {
      isVisible,
      handleClose
    };
  }
});
</script>
<style lang="scss">
@import '~@/themes/default/variables/index.scss';
.mei-shi-modal-wrap {
  z-index: 10000;
  .mei-shi-modal {
    .benefits-desc {
      text-align: center;
      color: $font-b-2;
    }
    .benefits-duration {
      color: $theme-b-1;
    }
    .benefits-btn {
      width: 200px !important;
      margin: 32px auto;
    }
  }
}
</style>
