import { Enum } from '@/common/config';
import DefaultAuthStrategy from './default/AuthStrategy';

let AuthStrategy;

switch (process.env.VUE_APP_CURRENT_CONTEXT) {
  case Enum.APP_CONTEXT.RABYTE:
  default:
    AuthStrategy = DefaultAuthStrategy;
    break;
}

export default AuthStrategy;
