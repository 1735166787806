import { FormatEntity } from '@alpha-pai/base';
import Constants from '@/modules/reading/config/constants';
import { showDateText, showPvText } from '@/modules/reading/utils';

// *************1
const formatters = {
  list(source) {
    const data = source.list || [];
    const type = source.type;
    return data.map(item => {
      const {
        id,
        title,
        content,
        sourceId,
        sourceName,
        url,
        date,
        industryName,
        pv,
        industry,
        stock,
        feature,
        rank,
        read = false
      } = item;
      return {
        ...item,
        id,
        title,
        content,
        type,
        typeText:
          type === Constants.READING_SUMMARY_TYPE ? 'roadshow' : 'public',
        author: {
          id: sourceId,
          name: sourceName,
          url,
          industryName
        },
        date: showDateText(date),
        pv: showPvText(pv),
        industry: industry || [],
        stock: stock || [],
        // feature: feature || [],
        featureHas: feature,
        feature: feature
          ? feature.filter(x => x !== '机构观点').filter(x => x !== '产业资讯')
          : [],
        rank,
        hasRead: read,
        time: date
      };
    });
  },
  total(source) {
    return source.total || 0;
  }
};

class ReadingSearchListResponse extends FormatEntity {
  constructor(source = {}, customFormatters) {
    super();
    this.define(Object.assign({}, source));
    this.init(source, Object.assign({}, formatters, customFormatters));
  }
}

export default ReadingSearchListResponse;
