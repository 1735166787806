/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-23 15:00:23
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-05-23 15:06:34
 */
import Dao from '@/adapters/dao';

class ConvertMeetingDao extends Dao {
  // 上市公司搜素
  async searchCompany(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/investigation/company/search',
      method: 'get',
      ...options
    });
  }

  async uploadFile(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/record/convert/file/upload',
      method: 'POST',
      ...options
    });
  }

  async queryConvertTask(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/record/convert/task/newest',
      method: 'get',
      ...options
    });
  }

  async startConvert(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/record/convert/task/add',
      method: 'post',
      ...options
    });
  }

  async cancelConvert(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/record/convert/task/cancel',
      method: 'get',
      ...options
    });
  }

  async getBusyStatus(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/record/convert/task/busy/status',
      method: 'get',
      ...options
    });
  }

  async updDownloadStatus(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/record/convert/task/download/status/update',
      method: 'get',
      ...options
    });
  }

  async getTodaySuccessCount(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/record/convert/today/success/count',
      method: 'get',
      ...options
    });
  }

  async updReUploadClickStatus(options = {}) {
    return await this.fetch({
      url:
        '/external/alpha/api/mix/record/convert/task/click/reUpload/status/update',
      method: 'get',
      ...options
    });
  }

  async addComment(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/record/convert/task/comment/add',
      method: 'post',
      ...options
    });
  }

  async getUpdateDescription(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/web/version/get',
      method: 'get',
      ...options
    });
  }
}
export default ConvertMeetingDao;
