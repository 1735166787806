import { InstitutionRoleEnums } from '@/common/config/enum';

export function setSecretKey(key) {
  return localStorage.setItem('SECRET_KEY', key);
}

export function getSecretKey() {
  return localStorage.getItem('SECRET_KEY');
}

// 当前用户是否是基础试用版
export function isBasicTrailVersion(user) {
  const { type, informationFlag } = user || {};
  return type === InstitutionRoleEnums.purchasers && !informationFlag;
}

export default {
  setSecretKey,
  getSecretKey
};
