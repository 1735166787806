<template>
  <el-popover
    v-if="customerManager && customerManager.mobile"
    trigger="hover"
    popper-class="cp-customer-service-wrap"
    :visible-arrow="false"
    placement="bottom-start"
    @show="handlePoperShow"
    @hide="handlePoperHide"
  >
    <div slot="reference" class="cp-customer-service">
      <i class="iconfont icon-zhuanshukefu2"></i>专属客服
    </div>
    <div>
      <img :src="customerManager.wxCardUrl" alt="" />
      <p>
        <b>{{ customerManager.name }}</b>
        <br />
        {{ customerManager.mobile }}
      </p>
    </div>
  </el-popover>
</template>
<script>
import { mapGetters } from 'vuex';
import { getCustomerManager } from '@/services/user';

export default {
  components: {},
  data() {
    return {
      actived: false
    };
  },
  computed: {
    ...mapGetters(['user']),
    customerManager() {
      return this.$store.state.readingStore.customerManager;
    }
  },
  created() {
    // 买方显示
    if (this.user) {
      getCustomerManager().then(res => {
        if (res && res.data) {
          this.$store.commit('readingStore/setCustomerManager', res.data);
        }
      });
    }
  },
  methods: {
    handlePoperShow() {
      this.actived = true;
    },
    handlePoperHide() {
      this.actived = false;
    }
  }
};
</script>
<style scoped lang="scss">
.cp-customer-service {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.06em;
  color: $white;
  cursor: pointer;
  padding: 0 12px;
  &:hover {
    background: $theme-bg-2;
  }
  .iconfont {
    font-size: 16px;
    margin-right: 4px;
  }
}
.cp-customer-service-wrap {
  img {
    margin-top: 12px;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    margin-top: 8px;
    font-size: inherit;
    color: $theme-b-1;
  }
}
</style>
<style lang="scss">
.cp-customer-service-wrap {
  border: 0;
  top: 38px !important;
  padding: 0 !important;
  width: 124px;
  height: 168px;
  border-radius: 4px;
  background: linear-gradient(180deg, $theme-b-5 0%, #fff 46.05%),
    linear-gradient(180deg, $theme-b-5 0%, #fff 19.79%), #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
</style>
