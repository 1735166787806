/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-11-03 18:37:19
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-12-05 15:45:38
 */
import { store } from '@alpha-pai/core';

import sensors from 'sa-sdk-javascript';
import profileFormat from '@/common/utils/profileFormat';
import * as Sentry from '@sentry/vue';

const { mutationTypes: types } = store;

export default {
  async setGlobalAppData({ commit }, data) {
    const { dataflow } = this;
    const { commonDao } = this.dataflow.dao;
    try {
      // 获取用户数据
      const appUserInfo = data || (await commonDao.getAppUserInfo());
      const { global = {} } = appUserInfo || {};

      dataflow.services.userService.setUser(global.user);
      dataflow.services.authService.setAuthList(global.auth);

      commit(types.SET_GLOBAL_APP_DATA, global, { root: true });
      sensors.login(global?.user?.uid);
      if (global?.user?.uid) {
        sensors.setProfile(profileFormat(global.user));
        if (process.env.NODE_ENV !== 'local') {
          Sentry.setUser({
            id: global?.user?.uid,
            username: global?.user?.name,
            email: global?.user?.email,
            phone: global?.user?.mobile
          });
        }
      }
      return true;
    } catch (e) {
      commit(types.SET_GLOBAL_APP_DATA, {}, { root: true });
    }
  },

  async removeGlobalAppData({ commit }) {
    const { dataflow } = this;

    return dataflow.dao.commonDao
      .logout()
      .then(() => {
        dataflow.services.userService.setUser({});
        dataflow.services.authService.setAuthList([]);

        commit(types.SET_GLOBAL_APP_DATA, {}, { root: true });
        return true;
      })
      .catch(e => {
        throw e;
      });
  }
};
