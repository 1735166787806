// 职位角色枚举
export const PositionRoleEnums = {
  purchasersOtherRole: 10,
  marketingTeam: 7,
  researcher: 6,
  fundManager: 5,
  chiefAnalyst: 3,
  analyst: 4,
  sales: 2
};

// 名片认证过程枚举
export const BusinessCardVerificationEnums = {
  noVerify: 0,
  verifying: 1,
  success: 2,
  failed: 3
};

export const BusinessCardVerificationFailedReasonEnums = {
  0: '',
  1: '名片不清晰，请重新上传',
  2: '非投研用户，暂未开放使用',
  3: '非目标用户，暂未开放使用',
  4: '名片未包含关键信息，如姓名、公司、部门、职位、邮箱'
};

export default {
  PositionRoleEnums,
  BusinessCardVerificationEnums,
  BusinessCardVerificationFailedReasonEnums
};
