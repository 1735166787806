<!--el-button和设计图颜色差距多，并且点击后focus属性不能及时去除-->
<template>
  <button
    class="custom-button"
    type="button"
    :plain="plain"
    :class="className"
    @click="debounceClick"
  >
    <i v-if="loading" class="el-icon-loading"></i>
    <i v-if="icon && !loading" :class="iconClassName"></i>
    <span>{{ label }}</span>
    <slot name="extra"></slot>
  </button>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'custom-button',
  props: {
    label: {
      type: String,
      default: '确定'
    },
    type: {
      type: String,
      default: 'primary' // default info plain plain-grey text text-grey
    },
    size: {
      type: String,
      default: 'small' // mini small medium larger
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    debounceWait: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      debounceClick: () => {}
    };
  },
  computed: {
    className() {
      const { type, size, disabled, loading } = this;
      const classNames = [`custom-button--${type}`, `custom-button--${size}`];
      if (disabled) {
        classNames.push('is-disabled');
      }
      if (loading) {
        classNames.push('is-loading');
      }
      return classNames;
    },
    iconClassName() {
      if (this.icon.startsWith('el-icon')) {
        return [this.icon];
      } else {
        return ['iconfont', this.icon];
      }
    }
  },
  methods: {
    onClick() {
      if (this.disabled || this.loading) {
        return false;
      }
      this.$emit('click');
    }
  },
  created() {
    this.debounceClick = debounce(this.onClick, this.debounceWait);
  }
};
</script>

<style scoped lang="scss">
.custom-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: $white;
  border: 1px solid $color-bg-1;
  color: $font-b-1;
  appearance: none;
  text-align: center;
  outline: 0;
  margin: 0;
  transition: all 0.1s ease 0s;
  user-select: none;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  &.is-disabled,
  &.is-loading {
    cursor: not-allowed;
  }
}

.custom-button--mini {
  padding: 6px 15px;
  font-size: 14px;
  // border-radius: 6px;
}

.custom-button--small {
  padding: 8px 15px;
  font-size: 14px;
  // border-radius: 6px;
}

.custom-button--medium {
  padding: 9px 20px;
  font-size: 14px;
  // border-radius: 6px;
}

.custom-button--larger {
  padding: 11px 31px;
  font-size: 16px;
  // border-radius: 6px;
}

.custom-button + .custom-button {
  margin-left: 16px;
}

.custom-button [class*='iconfont'] + span,
.custom-button [class*='el-icon-'] + span {
  margin-left: 4px;
}

.custom-button [class*='iconfont'],
.custom-button [class*='el-icon-'] {
  font-size: 12px;
}

.custom-button--primary {
  background: $theme-b-1;
  border-color: $theme-b-1;
  color: $white;
  &:hover {
    background: $theme-b-2;
    border-color: $theme-b-2;
    color: $white;
  }

  &.is-disabled,
  &.is-loading {
    background: $theme-b-4;
    border-color: $theme-b-4;
    color: $white;
  }
}

.custom-button--default {
  background: $white;
  border-color: $theme-b-1;
  color: $theme-b-1;
  &:hover {
    background: $white;
    border-color: $theme-b-2;
    color: $theme-b-2;
  }

  &.is-disabled,
  &.is-loading {
    background: $white;
    border-color: $theme-b-4;
    color: $theme-b-4;
  }
}

.custom-button--info {
  background: $background-color;
  border-color: $background-color;
  color: $font-b-2;
  &:hover {
    background: $font-b-5;
    border-color: $font-b-5;
  }

  &.is-disabled,
  &.is-loading {
    background: $background-color;
    border-color: $background-color;
    color: $font-b-4;
  }
}

.custom-button--plain {
  background: $white;
  border-color: $border-color;
  color: $font-b-1;
  &:hover {
    border-color: $background-color;
    background: $background-color;
    color: $font-b-2;
  }

  &.is-disabled,
  &.is-loading {
    background: $white;
    border-color: $border-color;
    color: $font-b-3;
  }
}

.custom-button--plain-grey {
  background: $white;
  border-color: $font-b-4;
  color: $font-b-3;
  &:hover {
    border-color: $font-b-4;
    background: $white;
    color: $font-b-4;
  }

  &.is-disabled,
  &.is-loading {
    background: $white;
    border-color: $font-b-5;

    color: $font-b-5;
  }
}

.custom-button--text {
  border-color: transparent;
  color: $theme-b-1;
  background: 0 0;
  padding: 0;
  &:hover {
    color: $theme-b-2;
  }

  &.is-disabled,
  &.is-loading {
    color: $theme-b-4;
  }
}

.custom-button--text-normal {
  border-color: transparent;
  color: $font-b-2;
  background: 0 0;
  padding: 0;
  &:hover {
    color: $theme-b-2;
  }

  &.is-disabled,
  &.is-loading {
    color: $font-b-3;
  }
}

.custom-button--text-grey {
  border-color: transparent;
  color: $font-b-1;
  background: 0 0;
  padding: 0;
  .iconfont {
    color: $font-b-2;
  }
  &:hover {
    color: $theme-b-2;
    .iconfont {
      color: $theme-b-2;
    }
  }

  &.is-disabled,
  &.is-loading {
    color: $font-b-3;
    .iconfont {
      color: $font-b-3;
    }
  }
}
</style>
