import { Service } from '@alpha-pai/base';
import Formatter from '@/common/utils/findDataFormat';

class StockService extends Service {
  async getArticleList(options) {
    const { stockDao } = this.dataflow.dao;
    const { data } = await stockDao.getArticleList(options);
    return data;
  }

  async getStockList(options) {
    const { stockDao } = this.dataflow.dao;
    const { data } = await stockDao.getStockList(options);
    return data;
  }

  async searchStock(options) {
    const { stockDao } = this.dataflow.dao;
    const { data } = await stockDao.searchStock(options);
    return data;
  }

  async getFollow(options) {
    const { stockDao } = this.dataflow.dao;
    const { data } = await stockDao.getFollow(options);
    return data;
  }

  async multiFollow(options) {
    const { stockDao } = this.dataflow.dao;
    const { data } = await stockDao.multiFollow(options);
    return data;
  }

  async unfollowStock(options) {
    const { stockDao } = this.dataflow.dao;
    const { data } = await stockDao.unfollowStock(options);
    return data;
  }

  async getDetailList(options) {
    const { stockDao } = this.dataflow.dao;
    const { data } = await stockDao.getDetailList(options);
    return data;
  }

  async getStockValuation(options) {
    const { stockDao } = this.dataflow.dao;
    const res = await stockDao.getStockValuation(options);
    return res;
  }

  async getHortStockChartData(options) {
    const { stockDao } = this.dataflow.dao;
    const { data } = await stockDao.getHortStockChartData(options);
    return Formatter.handleChartData(data, 'stock');
  }

  async getPriceSummary(options) {
    const { stockDao } = this.dataflow.dao;
    const res = await stockDao.getPriceSummary(options);
    return res;
  }

  async getStockOverview(options) {
    const { stockDao } = this.dataflow.dao;
    const res = await stockDao.getStockOverview(options);
    return res;
  }
}

export default StockService;
