import dayjs from 'dayjs';

export const dateFormat = (date, fmt = 'YYYY-MM-DD') => {
  if (typeof date === 'string') {
    return dayjs(date).format(fmt);
  } else if (!date) {
    return dayjs().format(fmt);
  } else {
    return dayjs(date).format(fmt);
  }
};

// 发布时间距当前时点1小时内，显示“刚刚”
// 发布时间距当前时点1小时外[1h,24h)，显示“xx小时前”
// 今天、昨天、今年的日期不显示年份
export const showDateText = (date, showTime = false) => {
  if (!date) {
    return '';
  }
  const timeFormat = showTime ? ' HH:mm' : '';
  let dateText = dayjs(date).format(`YYYY-MM-DD${timeFormat}`);
  const dateStamp = dayjs(date).valueOf();
  const curStamp = dayjs().valueOf();
  const tenMinutesStamp = curStamp - 1000 * 60 * 10;
  const anHourStamp = curStamp - 1000 * 60 * 60;
  const oneDayStamp = curStamp - 1000 * 60 * 60 * 24;

  if (dateStamp > curStamp) {
    dateText = dayjs(date).format(`MM-DD${timeFormat}`);
  } else if (dateStamp > tenMinutesStamp) {
    dateText = '刚刚';
  } else if (dateStamp < tenMinutesStamp && dateStamp > anHourStamp) {
    const diffMinutes = (curStamp - dateStamp) / 60000;
    dateText = `${Math.floor(diffMinutes)}分钟前`;
  } else if (dateStamp > oneDayStamp) {
    const diffHours = (curStamp - dateStamp) / 3600000;
    dateText = `${Math.floor(diffHours)}小时前`;
  } else {
    const dateYear = dayjs(date).format('YYYY');
    const curYear = dayjs().format('YYYY');
    const yesterday = dayjs()
      .add(-1, 'd')
      .format('YYYY-MM-DD');
    if (dateText === yesterday) {
      dateText = '昨天';
    } else if (dateYear === curYear) {
      dateText = dateFormat(date, `MM-DD${timeFormat}`);
    }
  }

  return dateText;
};

// 今天、昨天、今年的日期不显示年份
export const showSimpleDateText = date => {
  if (!date) {
    return '';
  }

  let dateText = dayjs(date).format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD');
  const yesterday = dayjs()
    .add(-1, 'd')
    .format('YYYY-MM-DD');
  const dateYear = dayjs(date).format('YYYY');
  const curYear = dayjs().format('YYYY');
  if (dateText === today) {
    dateText = '今天';
  } else if (dateText === yesterday) {
    dateText = '昨天';
  } else if (dateYear === curYear) {
    dateText = dayjs(date).format('MM-DD');
  }

  return dateText;
};

// 昨天/今天/明天 hh:mm
// 今年日期：mm-dd hh:mm
// 非今年日期：yyyy-mm-dd hh:mm
export const showDateTimeText = date => {
  if (!date) {
    return '';
  }
  const dateDay = dayjs(date).format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD');
  const yesterday = dayjs()
    .add(-1, 'd')
    .format('YYYY-MM-DD');
  const tomorrow = dayjs()
    .add(1, 'd')
    .format('YYYY-MM-DD');
  const dateYear = dayjs(date).format('YYYY');
  const curYear = dayjs().format('YYYY');
  if (dateDay === today) {
    return `今天 ${dayjs(date).format('HH:mm')}`;
  } else if (dateDay === tomorrow) {
    return `明天 ${dayjs(date).format('HH:mm')}`;
  } else if (dateDay === yesterday) {
    return `昨天 ${dayjs(date).format('HH:mm')}`;
  } else if (dateYear === curYear) {
    return dayjs(date).format('MM-DD HH:mm');
  } else {
    return dayjs(date).format('YYYY-MM-DD HH:mm');
  }
};
// 昨天/今天/明天
// 今年日期：mm-dd
// 非今年日期：yyyy-mm-dd
export const showDateTextSpec = date => {
  if (!date) {
    return '';
  }
  const dateDay = dayjs(date).format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD');
  const yesterday = dayjs()
    .add(-1, 'd')
    .format('YYYY-MM-DD');
  const tomorrow = dayjs()
    .add(1, 'd')
    .format('YYYY-MM-DD');
  const dateYear = dayjs(date).format('YYYY');
  const curYear = dayjs().format('YYYY');

  if (dateDay === today) {
    return `今天`;
  } else if (dateDay === tomorrow) {
    return `明天`;
  } else if (dateDay === yesterday) {
    return `昨天`;
  } else if (dateYear === curYear) {
    return dayjs(date).format('MM-DD');
  } else {
    return dayjs(date).format('YYYY-MM-DD');
  }
};
// 昨天/今天 hh:mm
// 今年日期：mm-dd hh:mm
// 非今年日期：yyyy-mm-dd hh:mm
export const showDateTextSpecTime = date => {
  if (!date) {
    return '';
  }
  const dateDay = dayjs(date).format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD');
  const yesterday = dayjs()
    .add(-1, 'd')
    .format('YYYY-MM-DD');
  const dateYear = dayjs(date).format('YYYY');
  const curYear = dayjs().format('YYYY');

  if (dateDay === today) {
    return `今天 ${dayjs(date).format('HH:mm')}`;
  } else if (dateDay === yesterday) {
    return `昨天 ${dayjs(date).format('HH:mm')}`;
  } else if (dateYear === curYear) {
    return dayjs(date).format('MM-DD HH:mm');
  } else {
    return dayjs(date).format('YYYY-MM-DD HH:mm');
  }
};
// 昨天/今天
// 今年日期：mm-dd
// 非今年日期：yyyy-mm-dd
export const showDateTextSpecOnlyDate = date => {
  if (!date) {
    return '';
  }
  const dateDay = dayjs(date).format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD');
  const yesterday = dayjs()
    .add(-1, 'd')
    .format('YYYY-MM-DD');
  const dateYear = dayjs(date).format('YYYY');
  const curYear = dayjs().format('YYYY');

  if (dateDay === today) {
    return `今天`;
  } else if (dateDay === yesterday) {
    return `昨天`;
  } else if (dateYear === curYear) {
    return dayjs(date).format('MM-DD');
  } else {
    return dayjs(date).format('YYYY-MM-DD');
  }
};

// 大于10万显示10万+，大于1万显示**万，其他正常显示
export const showPvText = pv => {
  if (!pv || typeof pv !== 'number') {
    return pv;
  }

  if (pv < 10000) {
    return pv;
  } else if (pv < 100000) {
    const numStr = (pv / 10000).toFixed(1);
    return `${Number(numStr)}万`;
  } else {
    return '10万+';
  }
};

export default {
  dateFormat,
  showDateText,
  showSimpleDateText,
  showDateTimeText,
  showDateTextSpec,
  showPvText
};
