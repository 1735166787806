import { Service } from '@alpha-pai/base';

class TranslateToolService extends Service {
  async getCurrentStatus(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getCurrentStatus(options);
    return res.data;
  }

  async postUploadFile(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.postUploadFile(options);
    return res.data;
  }

  async postTaskAdd(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.postTaskAdd(options);
    return res.data;
  }

  async getTaskCancel(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getTaskCancel(options);
    return res.data;
  }

  async postTaskList(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.postTaskList(options);
    return res.data;
  }

  async getTaskProgress(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getTaskProgress(options);
    return res.data;
  }

  async getTaskResultConfirm(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getTaskResultConfirm(options);
    return res.data;
  }

  async getTaskSendMail(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getTaskSendMail(options);
    return res.data;
  }

  async getTodayPage(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getTodayPage(options);
    return res.data;
  }

  async getLanguageList(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getLanguageList(options);
    return res.data;
  }

  async getTaskGet(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getTaskGet(options);
    return res.data;
  }

  async getTaskDelete(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.getTaskDelete(options);
    return res.data;
  }

  async addForeignReportTranslateTask(options = {}) {
    const { translateToolDao } = this.dataflow.dao;
    const res = await translateToolDao.addForeignReportTranslateTask(options);
    return res.data;
  }
}

export default TranslateToolService;
