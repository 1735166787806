<template>
  <el-dialog
    class="dialog-wrap"
    append-to-body
    :visible.sync="dialogVisible"
    :title="title"
    :close-on-click-modal="false"
    :width="dialogWidth"
    :show-close="showClose"
    :class="{
      'show-header-Line': headerLineVis,
      'hide-header-Line': !headerLineVis,
      [extraCss]: true
    }"
    :top="top"
  >
    <div class="body-cont" :style="{ padding: bodyPadding }">
      <slot name="content"></slot>
    </div>
    <div slot="footer" class="footer-warp" v-if="showBtn">
      <custom-button
        type="info"
        class="cancel-btn"
        :label="cancelText"
        :loading="loading"
        :size="buttonSize"
        @click="onCancel"
      />
      <custom-button
        type="primary"
        class="confirm-btn"
        :label="confirmText"
        :disabled="disabled"
        :loading="loading"
        :size="buttonSize"
        @click="onConfirm"
      />
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: String,
    visible: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
    bodyPadding: {
      type: String,
      default: '20px'
    },
    dialogWidth: {
      type: String,
      default: '60%'
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    top: {
      type: String,
      default: '15vh'
    },
    extraCss: {
      type: String,
      default: ''
    },
    headerLine: {
      type: Boolean,
      default: true
    },
    buttonSize: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      }
    },
    headerLineVis() {
      return !!this.title && this.headerLine;
    }
  },
  methods: {
    onCancel() {
      this.dialogVisible = false;
    },
    onConfirm() {
      this.$emit('confirm');
    }
  }
};
</script>

<style scoped lang="scss">
.dialog-wrap {
  ::v-deep .el-dialog__title {
    font-size: 16px;
    font-weight: 500;
    color: $font-b-1;
  }

  ::v-deep .el-dialog__header {
    font-weight: 500;
    font-size: 16px;
  }

  ::v-deep .el-dialog__headerbtn {
    top: 10px;
    .el-dialog__close {
      color: $font-b-2;
    }
    .el-dialog__close:hover {
      color: $theme-b-2;
    }
  }

  &.show-header-Line {
    ::v-deep .el-dialog__header {
      border-bottom: solid 1px #e4e7ed;
      padding: 8.5px 20px;
    }
  }

  &.hide-header-Line {
    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    ::v-deep .el-dialog__header {
      padding: 0 20px;
    }
    ::v-deep .el-dialog__headerbtn {
      top: 24px;
      right: 24px;
    }
  }

  ::v-deep .el-dialog__body {
    overflow: auto;
    padding: 0 !important;
    max-height: 66vh;
  }

  ::v-deep .el-dialog__footer {
    padding: 20px 0;
    margin: 0 20px;
    border-top: 1px solid #e4e7ed;
  }

  .footer-warp {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .custom-button + .custom-button {
      margin-left: 12px;
    }
  }
}
</style>
