/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-08-17 11:24:05
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-19 18:43:00
 */
const SUCCESS_CODE = 200000;
const ERROR_CODE = 500003;

const HEADERS_TOKEN_KEY = 'Authorization';
const COOKIE_DECRYPT_KEY = 'sk';
const HEADERS_SECRETE_KEY = 'x-access-sk';

export const BASE_URL = process.env.VUE_APP_BASE_URL || '';
const ROUTER_BASE = process.env.VUE_APP_PUBLIC_PATH || '';
const VUE_APP_DEFAULT_PATH = process.env.VUE_APP_DEFAULT_PATH || '';
const VUE_APP_LOGIN_PATH = process.env.VUE_APP_LOGIN_PATH || '';
const VUE_APP_X_FROM = process.env.VUE_APP_X_FROM || '';
// 工程组S3地址
const S3_BASE_URL =
  process.env.NODE_ENV === 'local'
    ? 'https://cloudfront-test-rabyte-bucket.rabyte.cn/'
    : 'https://cloudfront-prod-rabyte-saas.rabyte.cn/';
// 数据组S3地址
const S3_DATA_TEAM_BASE_URL = 'https://cloudfront-s3.rabyte.cn/';

// 本地环境 vs 测试环境
const MICRO_APPS_MANIFEST =
  process.env.NODE_ENV === 'local'
    ? '//localhost:5000/apps-dev.json'
    : BASE_URL + '/micro-apps/apps.json';

const MICRO_APPS_REGISTERED = [];

// alpha mind 地址
const VUE_ALPHAMIND_BASE_URL =
  process.env.NODE_ENV !== 'production'
    ? 'https://alpha-test.rabyte.cn'
    : 'https://alpha.rabyte.cn';

export default {
  SUCCESS_CODE,
  ERROR_CODE,
  BASE_URL,
  VUE_ALPHAMIND_BASE_URL,
  HEADERS_TOKEN_KEY,
  COOKIE_DECRYPT_KEY,
  HEADERS_SECRETE_KEY,
  ROUTER_BASE,
  MICRO_APPS_MANIFEST,
  MICRO_APPS_REGISTERED,
  VUE_APP_DEFAULT_PATH,
  VUE_APP_LOGIN_PATH,
  VUE_APP_X_FROM,
  S3_BASE_URL,
  S3_DATA_TEAM_BASE_URL
};
