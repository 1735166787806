/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:55
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-10-18 16:19:18
 */
import Watermark from '@/modules/reading/external/Watermark';
import { DateUtils } from '@/common/utils';

export * from './checkDocFile';

export const showDateText = DateUtils.showDateText;

export const showSimpleDateText = DateUtils.showSimpleDateText;

export const showDateTimeText = DateUtils.showDateTimeText;

export const showDateTextSpec = DateUtils.showDateTextSpec;

export const showPvText = DateUtils.showPvText;

export const dateFormat = DateUtils.dateFormat;

// 24小时之内不展示原文阅读，大于24小时展示原文阅读
export const showPv = date => {
  const day = 24 * 60 * 60 * 1000;
  const dateStamp = new Date(date).getTime();
  const curStamp = new Date().getTime();
  return curStamp - dateStamp >= day;
};
// 水印
export function addWaterMark({
  text,
  container,
  gwm = new Watermark(),
  options = {}
}) {
  gwm.creation({
    txt: `${text}`,
    width: 300,
    height: 200,
    color: '#000',
    fontSize: 14,
    font: 'PingFang SC',
    x: 24,
    container,
    ...options
  });
  return gwm;
}

export * from './getFocus';

export const getRouteName = ({ meta, name } = {}) => {
  const title = meta?.title || '';
  if (title && title !== 'Alpha派') {
    return title;
  }
  switch (name) {
    case 'homeMeeting':
      return '纪要';
    case 'myOrder':
      return '我的预约';
    case 'homePoint':
      return '研报';
    case 'homeNews':
      return '产业资讯';
    case 'homeConvertMeeting':
      return 'AI纪要助理';
    case 'homeMyFocus':
      return '自选股';
    default:
      return '';
  }
};

export * from './format';
