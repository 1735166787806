import SetTracker from '@/common/utils/setTracker';

export default function AfterHook(store) {
  return function(to, from, next) {
    const f = to?.query?.from || '';
    const type = to?.query?.type || '';
    const module = to?.query?.module || '';
    console.log(f, type, module);
    if (f && type && module) {
      const { institutionName = '', mobile = '', name = '' } =
        store?.getters?.user || {};
      console.log(institutionName, mobile, name);
      SetTracker('Web_function_advertise_link', {
        origin_from: f,
        origin_type: type,
        institution: institutionName,
        mobile,
        name,
        module
      });
    }
    if (from.name === to.name) {
      location.reload();
    }
  };
}
