import { Enum } from '@/common/config';
import DefaultConstants from './default/constants';

let Constants;

switch (process.env.VUE_APP_CURRENT_CONTEXT) {
  case Enum.APP_CONTEXT.RABYTE:
  default:
    Constants = DefaultConstants;
    break;
}

export { Constants };
