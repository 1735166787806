<template>
  <div class="toast" :style="styles">{{ content }}</div>
</template>
<script>
import {
  defineComponent,
  computed,
  onUnmounted,
  getCurrentInstance
} from '@vue/composition-api';

export default defineComponent({
  props: {
    el: {
      type: HTMLDivElement,
      default: null
    },
    content: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const $vm = getCurrentInstance();
    const styles = computed(() => {
      const { el } = props;
      const root = !!(!el || el === document.body);
      console.log('root', root);
      if (!root) {
        const rect = el.getBoundingClientRect();
        const top = rect.height / 2 + rect.top;
        const left = rect.width / 2 + rect.left;
        const res = `top: ${top}px; left:${left}px`;
        return res;
      }
      return '';
    });

    onUnmounted(() => {
      const { $el } = $vm.proxy;
      $el.parentNode.removeChild($el);
    });
    return {
      styles
    };
  }
});
</script>
<style lang="scss" scoped>
.toast {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 16px;
  line-height: 22px;
  border-radius: 6px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  white-space: nowrap;
}
</style>
