/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-06-28 15:50:47
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-02-26 14:52:50
 */
import service from '@/modules/reading/http/read.js';
import plateService from '@/modules/reading/http/plate.js';
import calendarService from '@/modules/reading/http/calendar';
import { uniqBy } from 'lodash';

export async function getFocusMap(stockList, groupCode = '') {
  const map = {};
  const code = [...new Set(stockList.map(item => item.code))] || [];
  if (!code.length) return map;
  try {
    const result = await service.getFocusStatus({
      data: {
        code,
        groupCode
      }
    });
    result.data.forEach(item => {
      map[item.code] = item.follow;
    });
  } catch (error) {
    console.error(error);
  }
  return map;
}

/***
 * @description: 查询板块关注状态
 * @param {Array<{code: Number, name: String}>} plateList
 * @return {Object} map
 ***/
export async function getSectorFocusMap(plateList) {
  const map = {};
  const code = [...new Set(plateList.map(item => item.code))] || [];
  if (!code.length) return map;
  try {
    const result = await plateService.getFollow({
      data: {
        code
      }
    });
    result.data.forEach(item => {
      map[item.id] = item.follow;
    });
  } catch (error) {
    console.error(error);
  }
  return map;
}

/***
 * @description: 查询加入日历状态
 * @param {Array<{code: Number, name: String}>} list
 * @return {Object} map
 ***/
export async function geCalendarFocusMap(list) {
  const map = {};
  const data = uniqBy(
    list.map(item => ({ code: item.id, type: item.type })),
    'code'
  );
  if (!data.length) return map;
  try {
    const result = await calendarService.getFollowList({ list: data });
    result.data.forEach(item => {
      map[item.code] = item.follow;
    });
  } catch (error) {
    console.error(error);
  }
  return map;
}
