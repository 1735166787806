/*
 * @Copyright © 2022 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : silverbulllet@163.com
 * @Date          : 2022-06-02 14:02:10
 * @LastEditors   : silverbulllet silverbulllet@163.com
 * @LastEditTime  : 2022-07-08 14:30:11
 */
import { Service } from '@alpha-pai/base';

class ExampleListService extends Service {
  async exportTable(options = {}) {
    const { aiChatDao } = this.dataflow.dao;
    aiChatDao.exportTable(options);
  }

  // 操作交互
  async operate(options = {}) {
    const { aiChatDao } = this.dataflow.dao;
    const data = await aiChatDao.operate(options);
    return data;
  }
}

export default ExampleListService;
