import CryptoJS from 'crypto-js';

const IV = '\x01\x02\x03\x04\x05\x06\x07\b';

// CBC模式解密
function decryptByDESModeCBC(ciphertext2, key, iv = IV) {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const ivHex = CryptoJS.enc.Utf8.parse(iv);
  const decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(ciphertext2)
    },
    keyHex,
    {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export default { IV, decryptByDESModeCBC };
