/**
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : luyue01@rabyte.cn
 * @Date          : 2024-01-26 14:37:08
 * @LastEditors   : luyue luyue01@rabyte.cn
 * @LastEditTime  : 2024-01-26 14:37:08
 */
import { http } from '@/adapters/dao';

const service = {
  /***
   * @description: 板块基本信息
   * @param {*} options
   * @return {*}
   ***/
  getBaseInfo(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/detail',
      ...options
    });
  },

  /***
   * @description: 板块-相关产业链
   * @param {*} options
   * @return {*}
   ***/
  getRelatedChain(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/chain/related',
      ...options
    });
  },

  /***
   * @description: 板块所在产业链
   * @param {*} options
   * @return {*}
   ***/
  getRecognitionChain(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/recognition/chain',
      ...options
    });
  },

  /***
   * @description: 板块产业链成分股
   * @param {*} options
   * @return {*}
   ***/
  getChainStock(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/chain/form',
      ...options
    });
  },

  /***
   * @description: 全景-是否自选 查询板块关注状态
   * @param {*} options
   * @return {*}
   ***/
  getFollow(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/sector/follow/query',
      ...options
    });
  },

  /***
   * @description: 全景-取消自选
   * @param {*} options
   * @return {*}
   ***/
  unfollowStock(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/information/flow/sector/follow',
      ...options
    });
  },

  /***
   * @description: 全景-加入自选
   * @param {*} options
   * @return {*}
   ***/
  addFollow(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/information/flow/sector/follow',
      ...options
    });
  },

  /***
   * @description: 全景-加入自选 覆盖性的
   * @param {*} options
   * @return {*}
   ***/
  multiFollow(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/sector/follow/multi',
      ...options
    });
  },

  /***
   * @description: 全景-研究精选tab
   * @param {*} options
   * @return {*}
   ***/
  getDailyFileTypes(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/research/count',
      ...options
    });
  },

  /***
   * @description: 全景-研究精选
   * @param {*} options
   * @return {*}
   ***/
  getDailyFileData(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/research',
      ...options
    });
  },

  /***
   * @description: 全景-最新活动
   * @param {*} options
   * @return {*}
   ***/
  getRecentActivity(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/activity',
      ...options
    });
  },

  /***
   * @description: 全景-公司活动tab
   * @param {*} options
   * @return {*}
   ***/
  // getOverallTypes(options) {
  getCompanyActivityTypes(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/activity/count',
      ...options
    });
  },

  /***
   * @description: 全景-公司活动tab
   * @param {*} options
   * @return {*}
   ***/
  getRecentTrackingTypes(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/trace/count',
      ...options
    });
  },

  /***
   * @description: 全景-最新活动列表
   * @param {*} options
   * @return {*}
   ***/
  getRecentTracking(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/trace',
      ...options
    });
  },

  /***
   * @description: 全景-研究复盘-个股K线
   * @param {*} options
   * @return {*}
   ***/
  getKlineTimeline(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/indicator/candlestick/line',
      ...options
    });
  },

  /***
   * @description: 全景-研究复盘-叠加指标
   * @param {*} options
   * @return {*}
   ***/
  getIndicatorTimeline(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/research/heat/line',
      ...options
    });
  },

  /***
   * @description: 全景-成分股
   * @param {*} options
   * @return {*}
   ***/
  getConstituentStock(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/ingredient/stock/list',
      ...options
    });
  },

  /***
   * @description: 全景 - 成分股 - 添加成份股
   * @param {*} options
   * @return {*}
   ***/
  addConstituentStock(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/add/ingredient',
      ...options
    });
  },

  /***
   * @description: 全景 - 成分股 - 单个 -- 反馈
   * @param {*} options
   * @return {*}
   ***/
  addSingleConstituentStockCallback(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/add/ingredient/note',
      ...options
    });
  },

  /***
   * @description: 全景-成分股纳入理由
   * @param {*} options
   * @return {*}
   ***/
  getConstituentStockReason(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/stock/into/reason/detail',
      ...options
    });
  },

  /***
   * @description: 板块总览-景气对比图
   * @param {*} options
   * @return {*}
   ***/
  getProsperityComparison(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/prosperity/comparison',
      ...options
    });
  },

  /***
   * @description: 板块总览-景气对比图-弹框
   * @param {*} options
   * @return {*}
   ***/
  getSectorProsperityInfo(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/latest-quotes',
      ...options
    });
  },

  /***
   * @description: 全景-研究复盘-图可点击时间段
   * @param {*} options
   * @return {*}
   ***/
  getAvailableRangeOption(options) {
    return http.fetch({
      method: 'GET',
      url:
        '/external/alpha/api/reading/statics/query/sector/biz/timeline/period',
      ...options
    });
  },

  /***
   * @description: 全景-研究复盘-文件个数序列图
   * @param {*} options
   * @return {*}
   ***/
  getFileNumTimeline(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/statics/query/sector/biz/timeline',
      ...options
    });
  },

  /***
   * @description: 纪要tab日期列表
   * @param {*} options
   * @return {*}
   ***/
  getSummaryDateList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/summary/date',
      ...options
    });
  },

  /***
   * @description: 纪要tab列表
   * @param {*} options
   * @return {*}
   ***/
  getSummaryList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/summary/list',
      ...options
    });
  },

  /***
   * @description: 资讯tab日期列表
   * @param {*} options
   * @return {*}
   ***/
  getInformationDateList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/article/date',
      ...options
    });
  },

  /***
   * @description: 资讯tab列表
   * @param {*} options
   * @return {*}
   ***/
  getInformationList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/article/list',
      ...options
    });
  },

  /***
   * @description: 点评tab日期列表
   * @param {*} options
   * @return {*}
   ***/
  getCommentDateList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/comment/date',
      ...options
    });
  },

  /***
   * @description: 点评tab列表
   * @param {*} options
   * @return {*}
   ***/
  getCommentList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/comment/list',
      ...options
    });
  },

  /***
   * @description: 研报tab日期列表
   * @param {*} options
   * @return {*}
   ***/
  getResearchDateList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/report/date',
      ...options
    });
  },

  /***
   * @description: 研报tab列表
   * @param {*} options
   * @return {*}
   ***/
  getResearchList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/report/list',
      ...options
    });
  },

  /***
   * @description: 活动tab列表
   * @param {*} options
   * @return {*}
   ***/
  getActivityList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/activity',
      ...options
    });
  },

  /***
   * @description: 活动tab计数
   * @param {*} options
   * @return {*}
   ***/
  getActivityCount(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/activity/count',
      ...options
    });
  },

  /***
   * @description: 基金产品列表
   * @param {*} options
   * @return {*}
   ***/
  getFundList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/fund/products/list',
      ...options
    });
  },

  /***
   * @description: 基金公司列表
   * @param {*} options
   * @return {*}
   ***/
  getFundCompanyList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/fund/companies/list',
      ...options
    });
  },

  /***
   * @description: 基金经理列表
   * @param {*} options
   * @return {*}
   ***/
  getFundManagerList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/sector/fund/manager/list',
      ...options
    });
  },

  /***
   * @description: 搜索基金经理
   * @param {*} options
   * @return {*}
   ***/
  searchFundManager(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/fund/manager/searchManager',
      ...options
    });
  },

  /***
   * @description: 搜索基金公司
   * @param {*} options
   * @return {*}
   ***/
  searchFundCompany(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/fund/search',
      ...options
    });
  },

  /***
   * @description: 获取交易日列表
   * @param {*} options
   * @return {*}
   ***/
  getTradingDayList(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/trading-day/list',
      ...options
    });
  },

  /***
   * @description: 板块分时线图
   * @param {*} options
   * @return {*}
   ***/
  getTradingDataLine(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/sector/ticker-data/line',
      ...options
    });
  },

  /***
   * @description: 获取板块的当前交易状态
   * @param {*} options
   * @return {*}
   ***/
  getTradingStatus(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/realtime/sector/trading-status',
      ...options
    });
  },

  /***
   * @description: 板块的实时行情
   * @param {*} options
   * @return {*}
   ***/
  getSectorRealtime(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/realtime/sector',
      ...options
    });
  }
};
export default service;
