<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:55
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-07-24 13:54:53
-->
<template>
  <div class="part-container">
    <div class="title">搜索发现</div>
    <div class="result-container">
      <div
        class="item"
        v-for="(item, index) in data"
        :key="`${item.word}-${index}`"
        @click="onClick(item, index)"
      >
        <div class="name-wrap">
          <img
            v-if="index < 3"
            class="hot-icon"
            :src="require('@/static/assets/images/hot-word.png')"
            alt=""
          />
          <div class="name">{{ item.word }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetTracker from '@/common/utils/setTracker';
export default {
  props: {
    data: {
      type: Array
    }
  },
  methods: {
    onClick(item, index) {
      SetTracker('Web_Search_Click', {
        search_words: item.word,
        search_types: '点击搜索发现',
        order_index: index + 1
      });
      this.$emit('search', item.word);
    }
  }
};
</script>

<style scoped lang="scss">
.part-container {
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.06em;
    color: $font-b-1;
    padding: 0 16px 0 16px;
  }
  .result-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    padding: 0 16px 4px 16px;
    .item {
      margin-bottom: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      background: #f4f5f6;
      padding: 3px 8px;
      margin-right: 8px;
      &:hover {
        background: $theme-b-5-1;
        .delete-icon {
          display: inline-block;
        }
      }
      .name-wrap {
        display: flex;
        align-items: center;
        max-width: 100%;
        &:hover {
          .name {
            color: $theme-b-2;
          }
        }
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
          font-size: 14px;
          line-height: 22px;
          color: $font-b-1;
        }
        .hot-icon {
          width: 16px;
        }
      }
    }
  }
}
</style>
