<template>
  <div class="part-container">
    <div class="title-wrap">
      <span class="title">搜索历史</span>
      <!-- 注：试用iconfont导出的图标 笔画不全-->
      <!-- <i class="clear-icon iconfont icon-dustbin" @click="$emit('clear')"></i>-->
      <span class="clear-wrap">
        <img
          class="clear-icon"
          :src="require('@/static/images/reading/icon-dustbin.png')"
          @click="$emit('clear')"
          alt=""
        />
        <img
          class="clear-icon-active"
          :src="require('@/static/images/reading/icon-dustbin-active.png')"
          @click="$emit('clear')"
          alt=""
        />
      </span>
    </div>
    <div class="result-container">
      <div
        class="item"
        v-for="(item, index) in data"
        :key="item"
        @click="onClick(item, index)"
      >
        <div class="name-wrap">
          <span class="name">{{ item }}</span>
        </div>
        <i
          class="delete-icon iconfont icon-close"
          @click.stop="$emit('delete', item)"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import SetTracker from '@/common/utils/setTracker';

export default {
  props: {
    data: {
      type: Array
    }
  },
  methods: {
    onClick(item, index) {
      SetTracker('Web_Search_Click', {
        search_words: item,
        search_types: '点击搜索历史',
        order_index: index + 1
      });
      this.$emit('search', item);
    }
  }
};
</script>

<style scoped lang="scss">
.part-container {
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 12px 16px;
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.06em;
      color: $font-b-1;
    }
    .clear-wrap {
      width: 16px;
      position: relative;
      .clear-icon {
        width: 16px;
        cursor: pointer;
      }
      .clear-icon-active {
        width: 16px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        display: none;
      }
      &:hover {
        .clear-icon-active {
          display: block;
        }
      }
    }
    //注：试用iconfont导出的图标 笔画不全
    //.clear-icon {
    //  cursor: pointer;
    //  font-size: 14px;
    //  color: $font-b-2;
    //  &:hover {
    //    color: $theme-b-2;
    //  }
    //}
  }

  .result-container {
    .item {
      padding: 5px 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background: $color-bg-1;
        .delete-icon {
          display: inline-block;
        }
      }
      .name-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .name {
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: $font-b-1;
        }
      }
      .delete-icon {
        font-size: 13px;
        color: $font-b-2;
        cursor: pointer;
        display: none;
        &:hover {
          color: $theme-b-2;
        }
      }
    }
  }
}
</style>
