/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : 345281398@qq.com
 * @Date          : 2023-07-31 10:27:20
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-06-25 15:41:09
 */
import * as dao from './dao';
import * as services from './services';
import * as formatters from './formatters';
import * as stores from './store';
import routes from './router';

import dayjs from 'dayjs';
const weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

const dataflow = {
  formatters,
  dao,
  services
};

export default { routes, stores, dataflow };
