import {
  SET_DIALOG_CONTEXT,
  DELETE_DIALOG_CONTEXT,
  SET_TASK_ID,
  DELETE_TASK_ID,
  CLEAR_QUESTION_QUEUE,
  SET_QUESTION_QUEUE
} from './mutationTypes';

export default {
  [SET_DIALOG_CONTEXT](state, dialog) {
    state.dialogContext.push(dialog);
  },
  [DELETE_DIALOG_CONTEXT](state) {
    state.dialogContext = [];
  },
  [SET_TASK_ID](state, taskId) {
    state.taskId = taskId;
  },
  [DELETE_TASK_ID](state) {
    state.taskId = '';
  },
  [CLEAR_QUESTION_QUEUE](state) {
    state.questionQueue = [];
  },
  [SET_QUESTION_QUEUE](state, question) {
    state.questionQueue.push(question);
  }
};
