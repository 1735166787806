import { ProductTypeEnums } from '@/common/config/enum';
import { MOBILE_SHOW } from '@/common/config/constants';
import confirmDialog from '@/common/components/confirm-dialog';
import $router from '@/router';

export default {
  namespaced: true,

  state: {
    // 是否是刚登录过得，进入reading模块后清除
    onceLogin: false,
    // 是否是新用户登录
    onceRegister: false,
    // 客户经理信息
    customerManager: null,
    isIntroModalVisible: '' // 会员介绍弹层是否展示&enterBy
  },

  getters: {},

  mutations: {
    setCustomerManager(state, data) {
      state.customerManager = data;
    },
    setIsIntroModalVisible(state, data) {
      state.isIntroModalVisible = data;
    },
    setOnceLogin(state, data) {
      state.onceLogin = data;
    },
    setOnceRegister(state, data) {
      state.onceRegister = data;
    }
  },

  actions: {
    jumpToPointDetail(state, query) {
      const { version } = query;
      delete query.version;
      if (
        version === 30 &&
        this.state.global.user?.productType === ProductTypeEnums.free
      ) {
        confirmDialog({
          title: '您的账号版本暂无权限',
          showCancelButton: false,
          dangerMode: true,
          text:
            '您的账号版本暂无外资报告查看权限，请联系客户经理升级 <span style="text-decoration: underline;color: #2a66f6;">' +
            MOBILE_SHOW +
            '</span>'
        });
        return Promise.reject(new Error());
      }

      const route = $router.resolve({
        name: 'pointDetail',
        query
      });
      const { href } = route;
      window.open(href, '_blank');
      return Promise.resolve();
    }
  }
};
