<template>
  <div
    class="cp-alpha-sass-admin_btn-item"
    :class="getClassnames"
    @click="onClick"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'defalut' // defalut, primary
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium' // large, medium, small, mini 目前只实现默认的medium
    },
    content: {
      type: String,
      default: ''
    },
    plain: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClassnames() {
      const res = [`btn_type_${this.type}`, `btn_size_${this.size}`];
      if (this.plain) {
        res.push('btn_plain');
      }
      if (this.disabled) {
        res.push('btn_disabled');
      }
      return res;
    }
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit('click');
    }
  }
};
</script>
<style lang="scss" scoped>
.cp-alpha-sass-admin_btn-item {
  & + & {
    margin-left: 12px;
  }
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.btn_type_defalut {
    border: none;
    color: $font-b-2;
    background-color: #f4f5f6;
    &:hover {
      background-color: rgba(221, 223, 225, 0.6);
    }
    &:active {
      background-color: $font-b-5;
    }
  }
  &.btn_type_primary {
    border: none;
    color: #fff;
    background-color: $theme-b-1;
    border-color: $theme-b-1;
    &:hover {
      background-color: $theme-b-2;
      border-color: $theme-b-2;
    }
    &:active {
      background-color: $theme-b--1;
      border-color: $theme-b--1;
    }
  }
  &.btn_size_medium {
    height: 34px;
    font-size: 14px;
    width: 92px;
  }
  &.btn_size_small {
    height: 32px;
    font-size: 14px;
    width: 92px;
  }
  &.btn_plain {
    border: 1px;
    border-style: solid;
    background-color: #fff;
    &.btn_type_primary {
      color: $theme-b-1;
      border-color: $theme-b-1;
      &:hover {
        background-color: #fff;
        color: $theme-b-2;
        border-color: $theme-b-2;
      }
      &:active {
        background-color: #fff;
        color: $theme-b-1;
        border-color: $theme-b-1;
      }
    }
  }
  &.btn_disabled {
    &.btn_type_primary {
      color: #fff;
      background-color: $theme-b-5;
      border-color: $theme-b-5;
    }
  }
}
</style>
