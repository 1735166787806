import { http } from '@/adapters/dao';

const service = {
  /***
   * @description: 日历-左侧、顶部统计数
   * @param {*} options
   * @return {*}
   ***/
  getSideWeeklyCount(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/weekly/count/form/side',
      ...options
    });
  },

  /***
   * @description: 日历-周视图-统计数
   * @param {*} options
   * @return {*}
   ***/
  getWeeklyCount(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/weekly/count',
      ...options
    });
  },

  /***
   * @description: 日历-日视图-表格列表、表格列表
   * @param {*} options
   * @return {*}
   ***/
  getDailyCalendar(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/daily/list',
      ...options
    });
  },

  /***
   * @description: 日历- 月视图-表格列表
   * @param {*} options
   * @return {*}
   ***/
  getMonthlyCalendar(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/monthly/form',
      ...options
    });
  },
  getWeeklyCalendarCount(data) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/weekly/count/form',
      data
    });
  },
  getWeeklyCalendarList(data) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/weekly/list/form',
      data
    });
  },
  /***
   * @description: 日历 - 查询关注状态
   * @param {*} options
   * @return {*}
   ***/
  getFollowList(data) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/follow/list',
      data
    });
  },
  /***
   * @description: 日历 - 关注/取消关注
   * @param {*} options
   * @return {*}
   ***/
  getFollowAdd(params) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/calendar/follow/add',
      params
    });
  },
  /***
   * @description: 日历-业绩报-日视图、周视图
   * @param {*} options
   * @return {*}
   ***/
  getAnnouncementList(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/performance/report/list',
      ...options
    });
  },
  getWeeklyAnnouncementList(data = {}) {
    return http.fetch({
      method: 'POST',
      url:
        '/external/alpha/api/reading/calendar/performance/report/weekly/list',
      data
    });
  },
  /***
   * @description: 日历-业绩报-日视图、周视图
   * @param {*} options
   * @return {*}
   ***/
  getAnnouncementMonthlyList(options = {}) {
    return http.fetch({
      method: 'POST',
      url:
        '/external/alpha/api/reading/calendar/performance/report/monthly/list',
      ...options
    });
  },

  /***
   * @description: 日历-导出excel
   * @param {*} options
   * @return {*}
   ***/
  exportCalendarExcel(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/download',
      ...options
    });
  },
  /***
   * @description: 日历-行业论坛详情
   * @param {*} options
   * @return {*}
   ***/
  getIndustryMeetingDetail(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/investigation/industry/meeting/detail',
      ...options
    });
  },

  /***
   * @description: 日历-邮件, 查询邮件是否开启
   * @param {*} options
   * @return {*}
   ***/
  getMailStatus(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/calendar/mail/switch/query',
      ...options
    });
  },

  /***
   * @description: 日历-邮件, 开启/关闭
   * @param {*} options
   * @return {*}
   ***/
  postMailStatus(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/calendar/mail/switch',
      ...options
    });
  },

  /***
   * @description: 日历-订阅自选, 查询是否开启
   * @param {*} options
   * @return {*}
   ***/
  getFollowStatus(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/calendar/follow/stock/switch/query',
      ...options
    });
  },

  /***
   * @description: 日历-订阅自选, 开启/关闭
   * @param {*} options
   * @return {*}
   ***/
  postFollowStatus(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/calendar/follow/stock/switch',
      ...options
    });
  },

  getCalendarTabs() {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/calendar/plan/list/v1'
    });
  },
  // 日历搜索相关接口-综合搜索
  getAllSearchResult(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/plan/search',
      ...options
    });
  },
  // 路演搜索
  getRoadShowSearchResult(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/plan/search/roadshow',
      ...options
    });
  },
  // 搜索调研
  getSurveySearchResult(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/plan/search/investigation',
      ...options
    });
  },
  // 搜索策略会
  getInvestigationSearchResult(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/plan/search/strategy',
      ...options
    });
  },
  getIndustryMeetingSearchResult(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/plan/search/industryMeeting',
      ...options
    });
  },
  getPerformanceReportSearchResult(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/plan/search/performance',
      ...options
    });
  },
  /***
   * @description: 综合搜索-日历搜索卡片
   * @param {*} options
   * @return {*}
   ***/
  searchCalenderCard(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/calendar/plan/window',
      ...options
    });
  }
};

export default service;
