/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-11 16:05:39
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-10-31 11:17:02
 */
//
import { http } from '@/adapters/dao';

const service = {
  /***
   * @description: 获取审核详情
   * @param {*} options
   * @return {*}
   ***/
  getAuditDetail(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/v2/authorization/user/card/auditDetail',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 获取专属客户经理详情
   * @param {*} options
   * @return {*}
   ***/
  getCustomerManagerDetail(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/customerManager/detail',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 补全信息，邮箱认证渠道
   * @param {*} options
   * @return {*}
   ***/
  setCompleteInformation(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/completeInformation',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 名片认证渠道
   * @param {*} options
   * @return {*}
   ***/
  setCardAuth(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/card/authentication',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 角色搜索
   * @param {*} options
   * @return {*}
   ***/
  getRoles(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/role/searchRoles',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 获取买方机构列表
   * @param {*} options
   * @return {*}
   ***/
  getBuyerOrgList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/institution/buyer/list',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 获取卖方机构列表
   * @param {*} options
   * @return {*}
   ***/
  getBrokerOrgList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/institution/broker/list',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 获取行业列表
   * @param {*} options
   * @return {*}
   ***/
  getIndustryList(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/v2/authorization/industry/read/list',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 发送邮箱验证码
   * @param {*} options
   * @return {*}
   ***/
  sendEmailCode(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/sendEmailCode',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 校验邮箱合法性，是否已经使用
   * @param {*} options
   * @return {*}
   ***/
  checkEmailLegal(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/checkEmailFormat',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 校验邮件验证码
   * @param {*} options
   * @return {*}
   ***/
  checkEmailCode(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/checkEmailCode',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  },

  /***
   * @description: 开启试用
   * @param {*} options
   * @return {*}
   ***/
  openTrial(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/continueTrial',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  }
};

export default service;
