<template>
  <div v-if="isVisible">
    <TemporaryModal
      v-if="isFromRegister"
      @close="handleClose"
      @showModal="handleShowValidMobileModal"
    />
    <Side v-else @showModal="handleShowValidMobileModal" />
    <ValidMobileModal
      v-if="isValidMobileModalVisible"
      :show-subtitle="false"
      enterBy="悬浮窗"
      :formValues="modalFormValues"
      @close="handleValidMobileModalClose"
      @complete="handleValidMobileModalConfirm"
    />
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  computed,
  getCurrentInstance,
  nextTick
} from '@vue/composition-api';
import { Message } from 'element-ui';
import ValidMobileModal from '@/common/components/valid-mobile-modal';
import Toast from '@/common/components/ui/toast';

import TemporaryModal from './temporary-modal';
import Side from './side.vue';

export default defineComponent({
  components: {
    ValidMobileModal,
    TemporaryModal,
    Side
  },
  setup() {
    const $vm = getCurrentInstance();
    const { $store } = $vm.proxy;
    const user = computed(() => $store.state.global.user || {});
    const isVisible = computed(
      () => !!(user.value.passwordFlag && !user.value.mobileValidateFlag)
    );
    // sessionStorage.removeItem('login_entry');
    const isFromRegister = ref(
      !!sessionStorage.getItem('account_register_once_flag')
    );
    sessionStorage.removeItem('account_register_once_flag');

    const isValidMobileModalVisible = ref(false);
    const modalFormValues = computed(() =>
      user.value
        ? {
            usermobile: user.value.mobile,
            regionCode: user.value.regionCode
          }
        : {}
    );
    const handleClose = () => {
      isFromRegister.value = false;
    };
    const handleShowValidMobileModal = () => {
      $store.dispatch('setGlobalAppData').then(() => {
        nextTick(() => {
          if (isVisible.value) {
            isValidMobileModalVisible.value = true;
          } else {
            Toast('手机号已验证');
          }
        });
      });
    };
    const handleValidMobileModalClose = () => {
      isValidMobileModalVisible.value = false;
    };
    const handleValidMobileModalConfirm = () => {
      $store.dispatch('setGlobalAppData');
      Message({
        type: 'success',
        message: '手机号验证通过'
      });
      handleValidMobileModalClose();
    };
    return {
      isVisible,
      isFromRegister,
      isValidMobileModalVisible,
      modalFormValues,
      handleShowValidMobileModal,
      handleValidMobileModalClose,
      handleValidMobileModalConfirm,
      handleClose
    };
  }
});
</script>
