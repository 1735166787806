<template>
  <div class="card-content" v-if="customerManager && customerManager.mobile">
    <div class="user-info">
      <div class="user-name">{{ customerManager.name }}</div>
      <div class="user-mobile">{{ customerManager.mobile }}</div>
      <div class="img-desc">扫码添加微信好友</div>
    </div>
    <div class="wx-code">
      <img :src="customerManager.wxCardUrl" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    customerManager() {
      return this.$store.state.readingStore.customerManager;
    }
  }
};
</script>
<style lang="scss" scoped>
.card-content {
  position: relative;
  width: 327px;
  height: 122px;
  margin: auto;
  background: url(./images/card.png) center / 100% 100%;
  border-radius: 6px;
}
.user-info {
  position: absolute;
  left: 60px;
  top: 33px;
  .user-name {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #5c1901;
  }
  .user-mobile {
    font-size: 14px;
    line-height: 22px;
    color: #822903;
  }
  .img-desc {
    font-size: 12px;
    line-height: 20px;
    color: #822903;
    opacity: 0.8;
  }
}
.wx-code {
  position: absolute;
  right: 21px;
  top: 21px;
  padding: 1px;
  border-radius: 5px;
  background: linear-gradient(#ffcca8, #bf7a48);
  img {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: inherit;
  }
}
</style>
