import { FormatEntity } from '@alpha-pai/base';

// *************1
const formatters = {
  list(source) {
    const data = source.list || [];
    return data.map(item => {
      const { institutionName } = item;
      return {
        ...item,
        instName: institutionName
      };
    });
  },
  total(source) {
    return source.total || 0;
  }
};

class PublicAccountListResponse extends FormatEntity {
  constructor(source = {}, customFormatters) {
    super();
    this.define(Object.assign({}, source));
    this.init(source, Object.assign({}, formatters, customFormatters));
  }
}

export default PublicAccountListResponse;
