<template>
  <div class="cp-free-version-modal">
    <div class="cp-free-version-modal-content">
      <img alt="" src="./assets/linear-grant.png" class="img-lineargrant" />
      <img alt="" src="./assets/bell.png" class="img-bell" />
      <div class="title">{{ title }}</div>
      <div class="content"><slot /></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '温馨提示'
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
.cp-free-version-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .cp-free-version-modal-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
    width: 384px;
    border: 1px solid #fff;
    // height: 285px;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-lineargrant {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 98px;
    }
    .img-bell {
      position: absolute;
      left: 50%;
      top: 0;
      height: 110px;
      width: 110px;
      transform: translate(-50%, -40px);
    }
    .title {
      position: relative;
      z-index: 1;
      padding: 66px 0 16px;
      // text-align: center;
      color: $font-b-1;
      font-size: 20px;
      font-weight: bold;
      line-height: 32px;
    }
    .content {
      z-index: 2;
      position: relative;
      flex: 1;
      width: 100%;
    }
  }
}
</style>
