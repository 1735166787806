/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-11 13:29:27
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-06-27 14:21:40
 */
import '@/common/entry';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import fingerprintjs, { murmurX64Hash128 } from '@fingerprintjs/fingerprintjs';

import Dao from '@/adapters/dao';
import * as Sentry from '@sentry/vue';
import App from './App';
import router from './router';
import store from './store';
import dataflow from './dataflow';
import { registerMicroRoutes, startMicroApps } from './micro-apps';

(async () => {
  /* keep vue-router and vuex store in sync */
  sync(store, router);

  const apps = await Promise.all([
    registerMicroRoutes(router),
    fingerprintjs
      .load({ debug: false })
      .then(fp => {
        return fp.get();
      })
      .then(({ components }) => {
        const values = [];
        Object.keys(components).forEach(function(key) {
          if (
            !['colorGamut', 'screenFrame', 'screenResolution'].includes(key)
          ) {
            values.push(components[key].value);
          }
        });
        // 指纹
        const token = murmurX64Hash128(values.join(''), 31);
        Dao.setHeaders({
          'X-device': token
        });
      })
  ]);
  startMicroApps(apps[0]);
  const sensors = require('sa-sdk-javascript');
  const baseUrl =
    process.env.NODE_ENV !== 'production'
      ? 'https://rabytetech.datasink.sensorsdata.cn/sa?project=default&token=bee92fa8de952781'
      : 'https://rabytetech.datasink.sensorsdata.cn/sa?project=production&token=bee92fa8de952781';
  const webUrl =
    process.env.NODE_ENV !== 'production'
      ? 'https://rabytetech.datasink.sensorsdata.cn/?project=default'
      : 'https://rabytetech.datasink.sensorsdata.cn/?project=production';

  sensors.init({
    server_url: baseUrl,
    web_url: webUrl,
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    send_type: 'beacon',
    heatmap: {
      // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'default',
      // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'not_collect'
    },
    show_log: false
  });
  sensors.quick('autoTrack'); // 用于采集 $pageview 事件。

  if (process.env.NODE_ENV !== 'local') {
    Sentry.init({
      Vue,
      dsn:
        'https://838d792258bacec73c1fdabacdd7c9c4@o4505757754851328.ingest.sentry.io/4506313648504832',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/
          ],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay(),
        new Sentry.Integrations.Breadcrumbs({
          console: process.env.NODE_ENV === 'production'
        })
      ],
      environment: process.env.NODE_ENV,
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  /* eslint-disable no-new */
  new Vue({
    router,
    store,
    dataflow,
    render: h => h(App)
  }).$mount('#app');
})();
