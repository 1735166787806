/*
 * @Copyright © 2022 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : silverbulllet@163.com
 * @Date          : 2022-07-27 15:46:13
 * @LastEditors   : silverbulllet silverbulllet@163.com
 * @LastEditTime  : 2022-07-27 16:23:19
 */
import Dao from '@/adapters/dao';
import { CommonUtil } from '@alpha-pai/core/utils';

class HomeDao extends Dao {
  async sendLoginSmsCode({ data = {} } = {}) {
    return this.fetch({
      url: '/external/alpha/api/v2/authentication/login/sms/send',
      method: 'POST',
      data
    });
  }

  // async sendSmsCode({ data = {} } = {}) {
  //   return this.fetch({
  //     url: '/external/alpha/api/v2/authentication/sms/send',
  //     method: 'POST',
  //     data
  //   });
  // }

  async getTestSmsCode({ data = {} } = {}) {
    return this.fetch({
      url: '/external/alpha/api/v2/authentication/sms/fetch',
      method: 'POST',
      data
    });
  }

  validateMobileCode(options) {
    return this.fetch({
      method: 'post',
      url: '/external/alpha/api/v2/authentication/sms/auth',
      data: options.data
    });
  }

  async login(options = {}) {
    return this.fetch({
      url: '/external/alpha/api/v2/authentication/mobileLogin',
      method: 'POST',
      data: options.data
    }).then(({ data = {} }) => {
      CommonUtil.userLogin(data.token);
      return data;
    });
  }

  async domainLogin(options = {}) {
    return this.fetch({
      url: '/external/alpha/api/v2/authentication/domainLogin',
      method: 'POST',
      data: options.data
    }).then(({ data = {} }) => {
      CommonUtil.userLogin(data.token);
      return data;
    });
  }

  async normalLogin(options = {}) {
    return this.fetch({
      url: '/external/alpha/api/v2/authentication/login',
      method: 'POST',
      data: options.data
    }).then(({ data = {} }) => {
      CommonUtil.userLogin(data.token);
      return data;
    });
  }

  async getCaptchaImg(options) {
    return this.fetch({
      url: '/external/alpha/api/v2/authentication/captcha/get',
      method: 'GET',
      params: options.params
    });
  }

  async checkCaptcha(options) {
    return this.fetch({
      url: '/external/alpha/api/v2/authentication/captcha/check',
      method: 'POST',
      data: options.data
    });
  }

  async unsubscribeMeetingEmail(options) {
    return this.fetch({
      url: '/external/alpha/api/v2/authorization/subscribe/unsubscribe/email',
      method: 'GET',
      ...options
    });
  }
}

export default HomeDao;
