// import * as dao from './dao';
// import * as services from './services';
import * as formatters from './formatters';
import * as stores from './store';
import * as dao from './dao';
import * as services from './services';
import routes from './router';

const dataflow = {
  formatters,
  dao,
  services
};

export default { routes, stores, dataflow };
