import uniq from 'lodash.uniq';
import { DateUtils } from '@/common/utils';
const { showDateText, showPvText, showDateTimeText } = DateUtils;

const handleChartData = (data, indexName) => {
  const resData = {
    maxDate: '',
    minDate: '',
    leftData: [],
    rightData: [],
    dates: [],
    isEmpty: false
  };
  if (!data) {
    return resData;
  }
  resData.rightData = data[indexName] || [];
  resData.leftData = data.heat || [];
  resData.isEmpty = !resData.leftData.length && !resData.rightData.length;
  let dates = [];
  resData.leftData.forEach(item => {
    dates.push(item.date);
  });
  let count = 0;
  resData.rightData.forEach(item => {
    if (typeof item.value !== 'number') {
      count += 1;
    }
    dates.push(item.date);
  });
  // 对比值无值时，value全部是null
  if (count === resData.rightData.length) {
    resData.rightData = [];
  }
  dates = uniq(dates);
  resData.dates = dates.sort((a, b) => a - b);
  if (resData.dates.length) {
    resData.maxDate = resData.dates[resData.dates.length - 1];
    resData.minDate = resData.dates[0];
  }
  return resData;
};

const handleViewpointData = data => {
  const resData = {
    total: 0,
    data: []
  };
  if (!data || !data.list || !data.list.length) {
    return resData;
  }

  resData.total = data.total || 0;
  resData.data = data.list.map(item => {
    const teamName = item.teamName;
    const teamNameStr = teamName || '机构点评';
    return {
      ...item,
      authorText: `来源：${teamNameStr}`,
      dateText: item.time ? showDateText(item.time) : ''
    };
  });
  return resData;
};

const handleReportData = data => {
  const resData = {
    total: 0,
    data: []
  };
  if (!data || !data.list || !data.list.length) {
    return resData;
  }
  resData.total = data.total || 0;
  resData.data = data.list.map(item => ({
    ...item,
    title: item.content,
    authorText:
      item.institution && item.author
        ? `${item.institution} | ${item.author}`
        : item.institution || item.author,
    dateText: item.time ? showDateText(item.time) : ''
  }));
  return resData;
};

const handleRoadshowData = data => {
  const resData = {
    total: 0,
    data: []
  };
  if (!data || !data.list || !data.list.length) {
    return resData;
  }
  resData.total = data.total || 0;
  resData.data = data.list.map(item => ({
    ...item,
    title: item.content,
    authorText:
      item.institution && item.author
        ? `${item.institution} | ${item.author}`
        : item.institution || item.author,
    dateText: item.time ? showDateText(item.time) : '',
    pvText: item.pv ? showPvText(item.pv) : ''
  }));
  return resData;
};

const handleReadRoadshowData = data => {
  const resData = {
    total: 0,
    data: []
  };
  if (!data || !data.list || !data.list.length) {
    return resData;
  }
  resData.total = data.total || 0;
  resData.data = data.list.map(item => ({
    ...item,
    title: item.content,
    authorText: item.author ? `嘉宾 | ${item.author}` : '',
    dateText: item.time ? showDateText(item.time) : '',
    industry: item.industry || [],
    stock: item.stock || []
  }));
  return resData;
};

const handleSquareRoadshowData = data => {
  const resData = {
    total: 0,
    data: []
  };
  if (!data || !data.list || !data.list.length) {
    return resData;
  }
  resData.total = data.total || 0;
  resData.data = data.list.map(item => ({
    ...item,
    dateText: item.startTime ? showDateTimeText(item.startTime) : '',
    industry: item.industry || [],
    stock: item.stock || [],
    institution: item.institution || [],
    pvText: item.pv ? showPvText(item.pv) : ''
  }));
  return resData;
};

const handleFundManagerData = data => {
  const resData = {
    total: 0,
    data: []
  };
  if (!data || !data.list || !data.list.length) {
    return resData;
  }

  resData.total = data.total || 0;
  resData.data = data.list.map(item => {
    const info = item.fundManagerMsg || {};
    return {
      ...info,
      stock: info.stockName,
      stockCount: info.num > 1 ? `等${info.num}支` : ''
    };
  });
  return resData;
};
const DealsurveyList = (data, preview) => {
  const resData = {
    total: 0,
    data: []
  };
  if (!data || !data.list || !data.list.length) {
    return resData;
  }
  resData.total = data.total || 0;
  resData.data = data.list.map(item => ({
    ...item,
    title: item.content,
    authorText: item.author ? `嘉宾 | ${item.author}` : '',
    dateText:
      item.startDate && item.startTime
        ? showDateTimeText(item.startDate + ' ' + item.startTime || '')
        : item.startDate
        ? item.startDate
        : '',
    // startTime: item.startTime || '00:00:00',
    // startDate: item.startDate || '2023-01-01',
    industry: item.industry || [],
    stock: item.stock || []
  }));

  return resData;
};
const DealbrokerUserList = data => {
  let resData = [];
  if (!data) {
    return resData;
  }
  resData = data.map(item => ({
    ...item,
    NumberList: dealNumberList(item.teamMemberList)
  }));

  return resData;
};
const dealNumberList = data => {
  const arr = [];
  if (data && data.length) {
    data.forEach(item => {
      if (item.phone) {
        arr.push(item);
      }
    });
  }
  return arr;
};

export default {
  handleChartData,
  handleViewpointData,
  handleReportData,
  handleRoadshowData,
  handleReadRoadshowData,
  handleSquareRoadshowData,
  handleFundManagerData,
  DealsurveyList,
  DealbrokerUserList
};
