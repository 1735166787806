<template>
  <div class="cp-verify-successed-modal">
    <div class="content">
      <dl>
        <dt>身份认证成功</dt>
        <dd>30天会员权益已解锁</dd>
      </dl>
      <img src="@/static/images/verify-success-modal-icon.png" alt="" />
      <custom-button
        type="primary"
        size="larger"
        label="立即使用"
        :loading="loading"
        @click="handleClose"
        class="btn-confirm"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { beginPersonal, cardAuthSuccess } from '@/services/user';
import { InstitutionRoleEnums } from '@/common/config/enum';
import SetTracker from '@/common/utils/setTracker';
export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters(['user']),
    isPurchasers() {
      return this.user?.type === InstitutionRoleEnums.purchasers;
    }
  },
  mounted() {
    SetTracker('Web_Verify_Success_Page_View');
  },
  methods: {
    handleClose() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const handleSuccess = () => {
        cardAuthSuccess();
        setTimeout(() => {
          location.reload();
        }, 200);
      };
      if (!this.isPurchasers) {
        handleSuccess();
        return;
      }
      beginPersonal()
        .then(handleSuccess)
        .catch(err => {
          this.$message.closeAll();
          this.$message({
            type: 'error',
            message: err?.response?.message || '请求失败，请稍后再试'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';

.cp-verify-successed-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 295px;
    height: 342px;
    border-radius: 6px;
    background: url(@/static/images/verify-success-modal-bg.png) center/100%
      100%;
    text-align: center;
    color: #822903;

    dl {
      padding-top: 60px;
      line-height: 32px;
      font-size: 24px;
      dt {
        font-weight: 600;
      }
      dd {
        font-weight: 500;
      }
    }
    img {
      display: block;
      margin: 22px auto 0;
      width: 80px;
      height: 80px;
    }
    .btn-confirm {
      position: absolute;
      bottom: 39px;
      width: 239px;
      left: 50%;
      transform: translate(-50%);
      border-radius: 6px;
      border: transparent;
      color: inherit;
      font-weight: 500;
      background: linear-gradient(90deg, #f0aa7f 0%, #ffcbab 100%),
        linear-gradient(90deg, #f5ba97 0%, #fbceb3 100%), #ad6539;
    }
  }
}
</style>
