<template>
  <el-dialog
    :visible="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    destroy-on-close
    append-to-body
    center
    top="0"
    class="cp-password-modal-wrap"
    @opened="calc"
  >
    <div class="cp-password-modal-content">
      <div class="header" ref="$header">
        <div class="title-wrap">
          <a
            v-if="showBack"
            class="btn-back"
            href="javascript:;"
            @click="handleBack"
          >
            <i class="iconfont icon-arrow-left" />
            <span>上一步</span>
          </a>
          <div v-if="title" class="title">{{ title }}</div>
          <div v-if="subTitle" class="subtitle">{{ subTitle }}</div>
        </div>
        <a
          v-if="closeable"
          href="javascript:;"
          class="iconfont icon-close btn-close"
          @click="handleClose"
        />
      </div>
      <div class="body" :style="bodyStyles"><slot name="content" /></div>
      <div class="footer" v-if="confirmText || cancelText" ref="$footer">
        <Button
          v-if="confirmText"
          type="primary"
          block
          :disabled="confirmDisabled"
          :content="confirmText"
          @click="handleConfirm"
        />
        <Button
          v-if="cancelText"
          type="link"
          block
          class="btn-cancel"
          :content="cancelText"
          @click="handleCancel"
        />
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  defineComponent,
  ref,
  getCurrentInstance,
  onMounted
} from '@vue/composition-api';
import Button from '@/common/components/ui/button';

export default defineComponent({
  emits: ['close', 'back', 'confirm', 'cancel'],
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    minHeight: {
      type: Number,
      default: 0
    },
    showBack: {
      type: Boolean,
      default: false
    },
    closeable: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Button
  },
  setup(props, context) {
    const $vm = getCurrentInstance();
    const bodyStyles = ref('');
    const handleClose = () => {
      context.emit('close');
    };
    const handleBack = () => {
      context.emit('back');
    };
    const handleCancel = () => {
      context.emit('cancel');
    };
    const handleConfirm = () => {
      context.emit('confirm');
    };
    const calc = () => {
      console.log('calc');
      const height =
        document.documentElement.getBoundingClientRect().height * 0.7;
      const { $header, $footer } = $vm.proxy.$refs;
      const headerH = $header ? $header.getBoundingClientRect().height : 0;
      const footerH = $footer ? $footer.getBoundingClientRect().height : 0;
      const res = `max-height: ${parseInt(
        // Math.max(height, 540) - headerH - footerH - 72,
        height - headerH - footerH - 72,
        10
      )}px;`;
      bodyStyles.value = res;
    };
    onMounted(() => {
      if (props.visible) {
        setTimeout(calc, 400);
      }
    });
    return {
      bodyStyles,
      handleClose,
      handleBack,
      handleConfirm,
      handleCancel,
      calc
    };
  }
});
</script>

<style lang="scss" scoped>
.cp-password-modal {
  &-wrap {
    ::v-deep {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-dialog {
        width: 392px;
        margin-bottom: 0;
        padding: 24px 36px 32px;
        border-radius: 6px;
        .el-dialog__body {
          padding: 0;
          line-height: 22px;
          font-size: 14px;
          color: $font-b-1;
        }
        .el-dialog__header,
        .el-dialog__footer {
          display: none;
        }
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    .header {
      flex-shrink: 0;
      padding: 0 0 24px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      .iconfont {
        font-size: 16px;
      }
      .title-wrap {
        flex: 1;

        .title {
          font-size: 18px;
          line-height: 26px;
          font-weight: bold;
        }
        .subtitle {
          margin-top: 2px;
          color: $font-b-2;
          font-size: 12px;
          line-height: 20px;
        }
        .btn-back {
          width: fit-content;
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          font-size: 14px;
          line-height: 22px;
          color: $font-b-2;
          &:hover {
            color: $theme-b-2;
            .iconfont {
              color: inherit;
            }
          }
        }
        .iconfont {
          margin-right: 2px;
          color: $font-b-3;
        }
      }
      .btn-close {
        color: $font-b-2;
        &:hover {
          color: $theme-b-2;
        }
      }
    }
    .body {
      margin: 0 -36px;
      padding: 0 36px;
      overflow: auto;
      word-break: break-word;
      @include scrollbar(4, 4);
    }
    .footer {
      margin-top: 24px;
      flex-shrink: 0;
      .btn-cancel {
        margin-top: 12px;
      }
    }
    // .el-dialog__footer {
    //   padding: 0;
    // }
  }
}
</style>
