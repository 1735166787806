<template>
  <div class="cp-valid-mobile-modal">
    <a href="javascript:;" @click="handleValid" />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import SetTracker from '@/common/utils/setTracker';

export default defineComponent({
  emits: ['showModal'],
  setup(props, context) {
    const handleValid = () => {
      SetTracker('Web_Login_Verify_Tip_Click');
      context.emit('showModal');
    };
    return {
      handleValid
    };
  }
});
</script>
<style lang="scss" scoped>
.cp-valid-mobile-modal {
  position: fixed;
  z-index: 1999;
  right: 0;
  top: 160px;
  width: 218px;
  height: 104px;
  background: url(@/static/images/mobile-unauthorized.png) center / 100% 100%;
  a {
    position: absolute;
    right: 20px;
    bottom: 32px;
    width: 56px;
    height: 20px;
  }
}
</style>
