import { plugins } from '@alpha-pai/core';
import Validate from './validate';

const CONFIG = {
  color: {
    fallback: 'chocolate'
  }
};

export default {
  install(Vue, config) {
    config = Object.assign({}, CONFIG, config);

    plugins.forEach(p => {
      const conf = p.name ? config[p.name] || {} : {};

      Vue.use(p, conf);
    });

    Vue.use(Validate);
  }
};
