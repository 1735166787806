import { Service } from '@alpha-pai/base';

class ConvertMeetingService extends Service {
  async searchCompany(options = {}) {
    const { convertMeetingDao } = this.dataflow.dao;
    const res = await convertMeetingDao.searchCompany(options);
    return res.data;
  }
}

export default ConvertMeetingService;
