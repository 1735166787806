<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-14 14:07:48
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-06-13 14:14:30
-->
<template>
  <div class="card-upload">
    <input
      class="input-file"
      ref="uploader"
      type="file"
      accept=".png,.jpg,.jpeg,.heic"
      @change="onSelectFile"
    />
    <div class="img-card" v-for="(card, ind) in innerCardImageUrls" :key="ind">
      <img :src="card" alt="" />
      <div class="icon" @click="delCard(ind)" v-show="!pendingStatus">
        <i class="iconfont icon-guanbi"></i>
      </div>
    </div>
    <div
      class="upload-card-box"
      v-show="innerCardImageUrls.length < 2 && !pendingStatus"
    >
      <div class="upload" @click="onUpload">
        <div class="upload-icon" v-show="innerCardImageUrls.length === 0"></div>
        <div
          class="upload-icon-second"
          v-show="innerCardImageUrls.length === 1"
        >
          <img :src="upAdd" alt="" />
          <span>继续上传</span>
        </div>
        <div class="upload-text" :class="{ error: uploadStatus === 'error' }">
          {{ currentMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { uploadImg } from '@/common/utils';
import upAdd from '../assets/images/up-add.png';
import { Message } from 'element-ui';
import SetTracker from '@/common/utils/setTracker';

export default defineComponent({
  name: 'CardUpload',

  components: {},
  props: {
    cardImageUrls: {
      type: Array,
      default: () => []
    },
    pendingStatus: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const uploader = ref(null);
    const message = ref('点击上传名片');
    const uploading = ref(false);
    const errorMessage = ref('');
    const uploadStatus = ref('normal'); // uploading, error

    const innerCardImageUrls = ref(props.cardImageUrls);

    const currentMessage = computed(() => {
      return uploadStatus.value === 'normal'
        ? message.value
        : errorMessage.value;
    });

    /**
     * @description: 处理信息变化
     * @return {*}
     */
    const handleMessage = () => {
      if (innerCardImageUrls.value.length === 1) {
        message.value = '点击继续上传';
      } else {
        message.value = '点击上传名片';
      }
    };

    /**
     * @description: 选择文件
     * @param {*} e
     * @return {*}
     */
    const onSelectFile = e => {
      const file = e.target.files[0];
      if (!/\.(jpg|png|jpeg|heic)$/gi.test(file.name)) {
        Message({
          type: 'error',
          message: '仅支持png、jpg、jpeg、heic文件'
        });
        return;
      }
      const { size } = file;
      // 注：录音最大支持文件为100MB 100 * 1024 * 1024
      if (size > 8388608) {
        Message({
          type: 'error',
          message: '图片文件需小于8mb'
        });
        return;
      }
      uploading.value = true;
      message.value = '名片上传中，请耐心等待';
      uploadStatus.value = 'normal';
      uploadImg(file)
        .then(res => {
          const newCardImageUrls = [...innerCardImageUrls.value, res];
          innerCardImageUrls.value = newCardImageUrls;
          emit('change', [...innerCardImageUrls.value]);
          handleMessage();
          uploading.value = false;
          SetTracker('Web_Verify_Upload_Result', {
            is_success: '是',
            err_log: ''
          });
        })
        .catch(err => {
          console.log(err);
          uploading.value = false;

          uploadStatus.value = 'error';
          errorMessage.value = '名片上传失败，请重新上传';
          SetTracker('Web_Verify_Upload_Result', {
            is_success: '否',
            err_log: err.message
          });
        });
    };

    /**
     * @description: 点击上传
     * @return {*}
     */
    const onUpload = () => {
      uploader.value.click();
      SetTracker('Web_Verify_Upload_Click');
    };

    /**
     * @description: 删除卡片
     * @param {*} ind
     * @return {*}
     */
    const delCard = ind => {
      const newCardImageUrls = [...innerCardImageUrls.value];
      newCardImageUrls.splice(ind, 1);
      innerCardImageUrls.value = newCardImageUrls;
      emit('change', innerCardImageUrls.value);
      handleMessage();
    };

    return {
      upAdd,
      onSelectFile,
      onUpload,
      uploader,
      uploadStatus,
      currentMessage,
      innerCardImageUrls,
      delCard
    };
  }
});
</script>

<style lang="scss" scoped>
.card-upload {
  .input-file {
    display: none;
  }
  .upload-card-box {
    width: 286px;
    .upload {
      width: 286px;
      height: 156px;
      border-radius: 4px;
      border: 1px solid #f4f5f6;
      background: #f7f8fa;

      cursor: pointer;
      .upload-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: 52px auto 40px;
        background: url('../assets/images/up-add.png') no-repeat center center;
        background-size: 32px 32px;
      }
      .upload-icon-second {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        margin: 48px auto 38px;
        > img {
          height: 16px;
          width: 16px;
        }
        > span {
          margin-top: 8px;
          color: $font-b-4;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 100% */
        }
      }
      .upload-text {
        border-radius: 0px 0px 4px 4px;
        background: $theme-b-3;
        color: #fff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 183.333% */
        &.error {
          background: #fc6d6f;
        }
      }
    }
  }
  .img-card {
    width: 286px;
    height: 156px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    border: 1px solid $font-b-5;
    margin-bottom: 8px;
    > img {
      height: 100%;
      width: 100%;
    }
    .icon {
      position: absolute;
      top: 8px;
      right: 8px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(25, 26, 29, 0.6);
      cursor: pointer;
      .iconfont {
        color: #dfccb3;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
  .bottom-tip {
    margin-top: 8px;
    color: $font-b-4;
    text-align: center;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }
}
</style>
