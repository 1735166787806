import { FormatEntity } from '@alpha-pai/base';
// import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import { nanoid } from 'nanoid';

const bodyFormatters = {
  questionId() {
    // const questionId = uuidv4().replace(/-/gi, '') + Date.now();
    const questionId = nanoid() + Date.now();
    return questionId;
  },
  taskId() {
    return store.getters['chatStore/taskId'];
  },
  question(source) {
    const { question } = source;
    return question.replace(/"/g, '"');
  },
  context(source) {
    const context = store.getters['chatStore/dialogContext'];
    return context || [];
  },
  tag(source) {
    const { tag } = source;
    return tag || '';
  },
  system() {
    return 'sass-web';
  }
};
class ChatQuestionRequest extends FormatEntity {
  constructor(source = {}, customFormatters) {
    super();
    this.define(Object.assign({}, source));
    this.init(source, Object.assign({}, bodyFormatters, customFormatters));
  }
}

export default ChatQuestionRequest;
