const AiChat = () =>
  import(/* webpackChunkName: "paipai-chat" */ '../pages/ai-chat/index').then(
    AiChat => AiChat
  );

const routes = [
  {
    path: '/ai-chat',
    component: AiChat,
    meta: {
      title: 'Alpha派',
      requiresAuth: true
      // requiresURIAuth: true
    }
  }
];

export default routes;
