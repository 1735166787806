<!--
 * Copyright © 2024 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2024-10-11 15:05:30
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-11-05 14:05:26
-->
<template>
  <div class="personal-robot-tip" v-if="show && isPurchasers">
    <div class="img-box">
      <img
        src="https://alphapai-web.rabyte.cn/external/alpha/data_storage/file/personal-robot-tip/big-tip.png"
        alt=""
      />
      <div class="btn" @click="onClick">
        <i class="iconfont icon-guanbishanchu"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { checkRobotTip, saveActionRobotTip } from '@/services/common.js';
import { mapGetters } from 'vuex';

export default {
  name: 'PersonalRobotTip',

  components: {},
  props: {},
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters(['user']),
    isPurchasers() {
      return this.user && `${this.user.type}` === '2';
    }
  },

  mounted() {
    if (this.isPurchasers) {
      this.init();
    }
  },
  methods: {
    init() {
      checkRobotTip({
        params: {
          from: 'web'
        }
      })
        .then(res => {
          this.show = res.data;
        })
        .catch(console.error);
    },
    onClick() {
      saveActionRobotTip({
        data: {
          from: 'web'
        }
      })
        .then(() => {
          this.show = false;
        })
        .catch(error => {
          console.error(error);
          this.show = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.personal-robot-tip {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  .img-box {
    width: 369px;
    height: 311px;
    flex-shrink: 0;
    position: relative;
    > img {
      height: 100%;
      width: 100%;
    }
    .btn {
      position: absolute;
      top: 32px;
      right: 24px;
      height: 16px;
      width: 16px;
      line-height: 16px;
      color: #808288;
      cursor: pointer;
      &:hover {
        color: $theme-b-2;
      }
    }
  }
}
</style>
