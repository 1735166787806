import Dao from '@/adapters/dao';
import { BASE_URL } from '@/adapters/config/default/constants';
class ExampleDao extends Dao {
  // 导出ai的表格数据
  exportTable(options = {}, customConfig = {}) {
    const link = document.createElement('a');
    const questionName = encodeURIComponent(options.params.questionName);
    link.style.display = 'none';
    link.href =
      BASE_URL +
      `/external/alpha/api/mix/chat/service/export?questionId=${options.params.questionId}&questionName=${questionName}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // 操作交互
  operate(options = {}, customConfig = {}) {
    return this.fetch(
      {
        method: 'POST',
        url: '/external/alpha/api/mix/chat/service/operate',
        ...options
      },
      { checkCode: data => data }
    );
  }
}

export default ExampleDao;
