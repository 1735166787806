/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:54
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-11-01 10:24:13
 */
import { Dao } from '@alpha-pai/base';
import { Constants } from '@/adapters/config';
import { UserUtils, CryptoUtils } from '@/common/utils';

export default class AdapterDao extends Dao {
  static headers = {};
  static setHeaders(opt) {
    AdapterDao.headers = {
      ...AdapterDao.headers,
      ...(opt || {})
    };
  }

  fetch(options = {}, customConfig = {}) {
    const key = UserUtils.getSecretKey();
    if (key) {
      const defaultHeaders = {
        'x-from': Constants.VUE_APP_X_FROM
      };
      options.header = {
        ...options.header,
        Cookie: `${Constants.COOKIE_DECRYPT_KEY}=${key}`
      };
      options.headers = {
        ...defaultHeaders,
        ...options.headers
      };
    }
    // options.withCredentials = true;
    if (options?.baseURL?.startsWith('https://yapi.rabyte.cn')) {
      options.url = options.url.replace('/external', '');
    }
    options.headers = {
      ...AdapterDao.headers,
      ...options.headers,
      'x-from': Constants.VUE_APP_X_FROM
      // 'x-device':
    };
    return super.fetch(options, customConfig).then(
      res => {
        return res;
      },
      e => {
        if (
          e?.response?.status === 401 ||
          e?.response?.data?.code === '401000'
        ) {
          const { pathname, search } = location;
          if (pathname.startsWith('/reading/')) {
            location.replace(`/login?${pathname}${search}`);
          }
        }
        throw e;
      }
    );
  }

  responseInterceptor(response) {
    const { headers = {}, data = {} } = response;
    const skStr = headers[Constants.HEADERS_SECRETE_KEY] || '';
    let key;
    const matches = skStr.match(`${Constants.COOKIE_DECRYPT_KEY}=(.*)`);
    if (matches && matches.length) {
      key = matches[1];
      UserUtils.setSecretKey(key);
    }

    if (key && data.data) {
      const decryptData = CryptoUtils.decryptByDESModeCBC(data.data, key);
      response.data.data = decryptData && JSON.parse(decryptData);
    }

    return response;
  }

  checkCode(response) {
    if (response instanceof Blob) {
      return response;
    }
    const code = response.code;
    const message = response.msg || 'error';
    if (String(code) === String(Dao.SUCCESS_CODE)) {
      return response;
    }
    const error = new Error(`Server error: ${code}, message: ${message}`);
    error.response = response;
    error.code = code;
    throw error;
  }
}
