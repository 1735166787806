<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:55
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-12-28 10:35:56
-->
<template>
  <div class="search-container">
    <el-popover
      ref="searchPopover"
      popper-class="app-search-popover"
      placement="bottom-start"
      trigger="click"
      :visible-arrow="false"
      @after-enter="refreshHistory"
    >
      <search-input
        slot="reference"
        :defaultWord="defaultWord"
        :searchCount="searchCount"
        :suggestion="suggestion"
        @input="onKeywordChange"
      />
      <search-suggestion
        v-if="keyword.length > 0"
        class="content"
        :keyword="keyword"
        ref="suggestion"
        @search="onSuggestionClick"
      />
      <search-history
        v-else
        class="content"
        ref="history"
        @search="onWordClick"
      />
    </el-popover>
    <!--注：2024-04-24下架顶部搜索引导，by xl-->
    <!--<SearchGuide v-if="showGuide" @close="closeGuide" />-->
  </div>
</template>

<script>
import SearchInput from './SearchInput';
import SearchHistory from './SearchHistory';
import SearchSuggestion from './SearchSuggestion';
// import SearchGuide from './SearchGuide.vue';

export default {
  components: {
    SearchInput,
    SearchHistory,
    SearchSuggestion
    // SearchGuide
  },
  data() {
    const showGuide = !localStorage.getItem('hasShowSearchOuterGuide');
    return {
      defaultWord: '',
      searchCount: 0,
      keyword: '',
      suggestion: {},
      showGuide
    };
  },
  methods: {
    onWordClick(word) {
      this.keyword = word;
      this.defaultWord = word;
      this.searchCount++;
      this.$refs.searchPopover.doClose();
    },
    refreshHistory() {
      this.$refs.history?.init();
      if (this.$refs.suggestion) {
        this.$refs.suggestion.getSuggestion();
      }
    },
    onKeywordChange(value) {
      this.keyword = value;
      this.closeGuide();
    },
    onSuggestionClick(suggestion) {
      this.suggestion = suggestion;
      this.$refs.searchPopover.doClose();
    },
    closeGuide() {
      this.showGuide = false;
      localStorage.setItem('hasShowSearchOuterGuide', true);
    }
  }
};
</script>
<style lang="scss" scoped>
.search-container {
  position: relative;
}
</style>
<style lang="scss">
.app-search-popover[x-placement^='bottom'] {
  margin-top: 7px;
  width: 434px;
  border-radius: 4px;
  border: 0;
  padding: 0;
  .content {
    padding: 12px 0 4px 0;
  }
}
</style>
