import Dao from '@/adapters/dao';

class PublicAccountDao extends Dao {
  // 获取我关注的市场资讯列表
  async getFollowPublicAccount(options = {}) {
    return await this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/follow/list',
      ...options
    });
  }

  // 获取市场资讯基本信息
  async getPublicAccountInfo(options = {}) {
    const params = options;
    return await this.fetch({
      url: '/external/alpha/api/reading/wechat/home/wechat/info',
      params
    });
  }

  // 获取市场资讯下所有文章
  async getAllArticle(options = {}) {
    return await this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/wechat/article/list',
      ...options
    });
  }

  // 市场资讯-关注
  async addPublicAccountFollow(options = {}) {
    const params = options;
    return await this.fetch({
      url: '/external/alpha/api/reading/follow/save?',
      params
    });
  }

  // 市场资讯-取消关注
  async removePublicAccountFollow(options = {}) {
    const params = options;
    return await this.fetch({
      url: '/external/alpha/api/reading/follow/remove',
      params
    });
  }
}

export default PublicAccountDao;
