<template>
  <transition name="fade">
    <div v-show="visible" class="model-wrapper">
      <div class="prompt-dialog" :style="style">
        <i
          v-if="showClose"
          class="el-icon-close close-btn"
          @click="onCloseBtnClick"
        ></i>
        <div class="body">
          <slot name="body" />
        </div>
        <div v-if="showFooter" class="footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    customStyle: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    style() {
      return { ...this.customStyle };
    }
  },
  methods: {
    onCloseBtnClick() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
.model-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.prompt-dialog {
  position: absolute;
  width: 432px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    color: #909399;
    &:hover {
      opacity: 0.7;
    }
  }

  .body {
    padding: 32px 24px 12px 32px;
    overflow: auto;
    max-height: 75vh;
  }

  .footer {
    padding: 12px 24px 24px 24px;
  }
}
</style>
