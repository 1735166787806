import AppLayout from '@alpha-pai/ui/src/layout';
import { InternalServerError } from '@alpha-pai/ui/src/error';
import { Constants } from '@/adapters/config';

const LoginPage = () =>
  import(/* webpackChunkName: "home-login" */ '../pages/login/index').then(
    LoginPage => LoginPage
  );
const RefreshPage = () =>
  import(/* webpackChunkName: "home" */ '../pages/refresh/Refresh').then(
    RefreshPage => RefreshPage
  );

const Forbidden = () =>
  import(/* webpackChunkName: "home" */ '../pages/error/Forbidden').then(
    Forbidden => Forbidden
  );

const NotFound = () =>
  import(/* webpackChunkName: "home" */ '../pages/error/NotFound').then(
    NotFound => NotFound
  );

const PrivacyPage = () =>
  import(/* webpackChunkName: "home-provision" */ '../pages/provision/Privacy');

const UserAgreementPage = () =>
  import(
    /* webpackChunkName: "home-provision" */ '../pages/provision/UserAgreement'
  );

const SsoPage = () =>
  import(/* webpackChunkName: "home-sso" */ '../pages/sso').then(
    SsoPage => SsoPage
  );
const SaasSsoPage = () =>
  import(/* webpackChunkName: "home-sso" */ '../pages/sso/CarryingToken').then(
    SsoPage => SsoPage
  );
const AlphaSsoPage = () =>
  import(/* webpackChunkName: "home-sso" */ '../pages/sso/ConvertToken').then(
    SsoPage => SsoPage
  );
const PerformanceMeetingEmail = () =>
  import(
    /* webpackChunkName: "auxiliary-email" */ '../pages/auxiliary/meeting-email/PerformanceMeetingEmail'
  ).then(PerformanceMeetingEmail => PerformanceMeetingEmail);
const MeetingEmailUnsubscribe = () =>
  import(
    /* webpackChunkName: "auxiliary-email-unsubscribe" */ '../pages/auxiliary/unsubscribe/MeetingEmailUnsubscribe'
  ).then(MeetingEmailUnsubscribe => MeetingEmailUnsubscribe);
const CompanyMeetingEmail = () =>
  import(
    /* webpackChunkName: "auxiliary-email" */ '../pages/auxiliary/meeting-email/CompanyMeetingEmail'
  ).then(CompanyMeetingEmail => CompanyMeetingEmail);
const routes = [
  {
    path: '/',
    component: AppLayout,
    redirect: Constants.VUE_APP_DEFAULT_PATH,
    // redirect: '/saas-admin',
    children: [
      {
        path: '/refresh/:path',
        component: RefreshPage,
        meta: {
          tab: false,
          title: '刷新页面',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/login',
    component: LoginPage,
    name: 'login-page',
    meta: {
      title: '讯兔科技_Alpha派'
    }
  },
  {
    path: '/sso',
    component: SsoPage,
    name: 'login-sso'
  },
  {
    path: '/saas-sso',
    component: SaasSsoPage,
    name: 'saas-sso'
  },
  {
    path: '/alpha-sso',
    component: AlphaSsoPage,
    name: 'alpha-sso'
  },
  {
    path: '*',
    redirect: '/error/404'
  },
  {
    path: '/error/404',
    alias: '/notfound',
    component: NotFound,
    meta: {
      name: 'not-found',
      title: '404',
      requiresAuth: false
    }
  },
  {
    path: '/error/403',
    alias: '/forbidden',
    component: Forbidden,
    meta: {
      name: 'forbidden',
      title: '403',
      requiresAuth: false
    }
  },
  {
    path: '/error/500',
    alias: '/error',
    component: InternalServerError,
    meta: {
      name: 'internal-server-error',
      title: '500',
      requiresAuth: false
    }
  },
  {
    path: '/provision/privacy',
    component: PrivacyPage,
    name: 'privacy-page'
  },
  {
    path: '/provision/user-agreement',
    component: UserAgreementPage,
    name: 'privacy-page-user-agreement'
  },
  {
    path: '/auxiliary/performance-meeting-email',
    component: PerformanceMeetingEmail,
    name: 'performance-meeting-email'
  },
  {
    path: '/auxiliary/company-meeting-email',
    component: CompanyMeetingEmail,
    name: 'company-meeting-email'
  },
  {
    path: '/auxiliary/meeting-email-unsubscribe',
    component: MeetingEmailUnsubscribe,
    name: 'meeting-email-unsubscribe',
    meta: {
      title: '邮件服务退订',
      requiresAuth: true
    }
  }
];

export default routes;
