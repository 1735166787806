<template>
  <div class="cp-certified-user-content">
    <div class="title">
      <img src="@/static/images/verify-successed-modal-symbol1.png" alt="" />
      <p>你的会员体验还剩30天</p>
      <img src="@/static/images/verify-successed-modal-symbol2.png" alt="" />
    </div>
    <div class="desc">
      <p>联系客户经理续期或了解更多</p>
      <p>
        <a href="javascript:;" @click="handleLinkClick">
          了解更多<i class="el-icon-arrow-right" />
        </a>
      </p>
    </div>
    <custom-button
      type="primary"
      size="medium"
      label="立即使用"
      :loading="loading"
      @click="handleClose"
      class="btn-confirm"
    />
  </div>
</template>
<script>
import SetTracker from '@/common/utils/web-setTracker';
import { beginPersonal } from '@/services/user';

export default {
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    SetTracker('Web_Current_verified_7Days_Pop_Button_Click');
  },
  methods: {
    handleClose() {
      this.loading = true;
      beginPersonal()
        .then(() => {
          setTimeout(() => {
            location.reload();
          }, 200);
        })
        .catch(err => {
          this.$message.closeAll();
          this.$message({
            type: 'error',
            message: err?.response?.message || '请求失败，请稍后再试'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleLinkClick() {
      this.$emit('intro', '存量已认证弹窗');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';

.cp-certified-user-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 486px;
  height: 246px;
  border-radius: 6px;
  background-image: linear-gradient(180deg, #dfeaff 0%, #fff 45.05%);
  background-color: #fff;
  text-align: center;
  .title {
    padding-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $theme-b-1;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;

    img {
      top: 50%;
      left: 100%;
      width: 60px;
      height: 60px;
    }
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $font-b-2;
  }
  .btn-confirm {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translate(-50%);
    width: 200px;
  }
}
</style>
