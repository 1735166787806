<template>
  <div>
    <history-words
      v-if="list.length"
      :data="list"
      @clear="onClear"
      @delete="onDelete"
      @search="$emit('search', $event)"
    />
    <div
      style="height: 24px; width: 100%"
      v-if="list.length && hotList.length"
    ></div>
    <hot-words
      v-if="hotList.length"
      :data="hotList"
      @search="$emit('search', $event)"
    />
  </div>
</template>

<script>
import HistoryWords from './HistoryWords';
import HotWords from './HotWords';
export default {
  components: {
    HistoryWords,
    HotWords
  },
  data() {
    return {
      list: [],
      hotList: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getHistory();
      this.getHotWords();
    },
    getHistory() {
      this.$dao.readDao
        .getHistoryWord({
          params: { type: 90 }
        })
        .then(({ data }) => {
          this.list = data || [];
        })
        .catch(() => {
          this.list = [];
        });
    },
    getHotWords() {
      this.$dao.readDao
        .getHotWord({
          params: { type: 90 }
        })
        .then(({ data }) => {
          this.hotList = data || [];
        })
        .catch(() => {
          this.hotList = [];
        });
    },
    onClear() {
      this.$dao.readDao
        .clearSearchHistory({
          params: { type: 90 }
        })
        .then(() => {
          this.list = [];
        })
        .catch(err => {
          console.error(err);
          this.$message({ type: 'error', message: '搜索历史删除失败' });
        });
    },
    onDelete(word) {
      this.$dao.readDao
        .clearSingleSearchHistory({
          params: { type: 90, word }
        })
        .then(() => {
          const index = this.list.findIndex(item => item === word);
          this.list.splice(index, 1);
        })
        .catch(() => {
          this.$message({ type: 'error', message: '搜索历史删除失败' });
        });
    }
  }
};
</script>

<style scoped></style>
