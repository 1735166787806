<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-06-19 10:25:02
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-06-14 15:28:55
-->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible="true"
    :append-to-body="false"
    custom-class="dedicated-account-manager-dialog"
    width="486px"
    title="专属客户经理"
    @close="onCancel"
  >
    <div class="content">
      <div class="top">
        <div class="left">
          <div class="name">{{ innerInfo.name }}</div>
          <div class="tel">{{ innerInfo.mobile }}</div>
        </div>
        <div class="right">
          <div class="qr-code">
            <img :src="innerInfo.wxCardUrl" alt="" />
          </div>
          <div class="tip">扫码添加好友</div>
        </div>
      </div>
      <div class="bottom-tip">
        <i class="iconfont icon-phone"></i>
        <span class="t">客服热线</span>
        <span>400-082-1099</span>
      </div>
    </div>
    <template v-slot:footer>
      <div class="dialog-footer"></div>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  onUnmounted,
  getCurrentInstance,
  ref,
  onMounted
} from '@vue/composition-api';
import SetTracker from '@/common/utils/setTracker';

export default defineComponent({
  name: 'DedicatedAccountManager',
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    enterBy: {
      type: String,
      default: ''
    },
    resolve: {
      type: Function,
      default: () => {
        // do nothing
      }
    },
    callback: {
      type: Function,
      default: () => {
        // do nothing
      }
    }
  },
  setup(props) {
    const vm = getCurrentInstance();
    const onCancel = () => {
      props.resolve(false);
      props.callback();
    };
    const onSave = () => {
      props.resolve(true);
      props.callback();
    };

    const innerInfo = ref({});

    innerInfo.value = { ...props.options };
    onMounted(() => {
      SetTracker('Web_CM_Page_View', {
        cm_name: innerInfo.value.name,
        enter_by: props.enterBy
      });
    });

    onUnmounted(() => {
      document.body.removeChild(vm.proxy.$el);
    });
    return { onCancel, onSave, innerInfo };
  }
});
</script>
<style lang="scss">
.dedicated-account-manager-dialog {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<style lang="scss" scoped>
.dedicated-account-manager-dialog {
  .content {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 324px;
      height: 124px;
      padding: 0 16px;
      border-radius: 5px;
      border: 1px solid #fff;
      margin: 0 auto;
      background: url('@/static/images/card-background.png') no-repeat center
        center;
      background-size: 100% 100%;
      .left {
        .name {
          color: $theme-b--4;
          font-family: PingFang SC;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 140% */
        }
        .tel {
          margin-top: 2px;
          color: $theme-b--4;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
      .right {
        .qr-code {
          width: 82px;
          height: 82px;
          border-radius: 5px;
          border: 1px solid $theme-b-2;
          overflow: hidden;
          > img {
            height: 100%;
            width: 100%;
          }
        }
        .tip {
          margin-top: 8px;
          color: $theme-b--4;
          text-align: center;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px; /* 100% */
        }
      }
    }
    .bottom-tip {
      color: $font-b-2;
      text-align: center;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      display: flex;
      justify-content: center;
      margin: 24px 0 32px;
      .t {
        margin-left: 4px;
        margin-right: 8px;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .btn {
      width: 200px;
      height: 34px;
      text-align: center;
      margin-top: 32px;
      margin-bottom: 32px;
      line-height: 8px;
      background: $theme-b-1;
      border-radius: 6px;
    }
  }
}
</style>
<style lang="scss">
.dedicated-account-manager-dialog {
  background: linear-gradient(181deg, #dfeaff 0.87%, #fff 51.26%), #fff;
  border-radius: 10px;
  .el-dialog__header {
    padding-top: 48px;
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $font-b-1;
    text-align: center;
    .el-dialog__headerbtn {
      margin-right: 4px;
      .el-icon-close {
        color: $font-b-2;
      }
    }
  }
  .el-dialog__body {
    padding: 0px 32px;
  }
  .el-dialog__footer {
    padding: 0px;
  }
}
</style>
