<template>
  <div>
    <ModalWrap
      :visible="isVisible"
      title="手机验证码校验"
      :sub-title="subtitle"
      @close="handleClose"
    >
      <el-form slot="content">
        <FormItem label="手机号">
          <div>{{ mobileShow }}</div>
        </FormItem>
        <MobileVerifyCode
          :mobile="mobile"
          v-model="verifyCode"
          :error-msg="mobileErrorMsg"
          size="medium"
        />
        <Button
          block
          type="primary"
          content="确定"
          @click="validMobileCode"
          :disabled="isSubmitDisabled"
        />
      </el-form>
    </ModalWrap>
  </div>
</template>
<script>
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  watch
} from '@vue/composition-api';
import SetTracker from '@/common/utils/setTracker';
import ModalWrap from '@/common/components/ui/password-modal-wrap';
import MobileVerifyCode from '@/common/components/mobile-verify-code';
import FormItem from '@/common/components/ui/form-item';
import Toast from '@/common/components/ui/toast';
import Button from '@/common/components/ui/button';

import { validMobileAuthorizationCode } from '@/services/user';

export default defineComponent({
  emits: ['close', 'complete'],
  props: {
    formValues: {
      type: Object,
      default: () => ({})
    },
    showSubtitle: {
      type: Boolean,
      default: true
    },
    enterBy: {
      type: String,
      default: ''
    }
  },
  components: {
    ModalWrap,
    FormItem,
    MobileVerifyCode,
    Button
  },
  setup(props, context) {
    const mobileErrorMsg = ref('');
    const verifyCode = ref('');
    const isVisible = ref(false);
    const validating = ref(false);
    const handleClose = () => {
      context.emit('close');
    };
    const subtitle = computed(() =>
      props.showSubtitle
        ? '超出48小时未完成手机验证码校验，为保障您的账号安全，请填写以下信息'
        : ''
    );
    const mobileShow = computed(() => {
      const { formValues } = props;
      return `${formValues.regionCode} ${formValues.usermobile}`;
    });
    const mobile = computed(() => {
      const { formValues } = props;
      return `${formValues.regionCode}${formValues.usermobile}`;
    });
    const isSubmitDisabled = computed(
      () => !verifyCode.value || validating.value
    );
    watch(verifyCode, () => {
      mobileErrorMsg.value = '';
    });
    const validMobileCode = () => {
      validating.value = true;
      const query = {
        code: verifyCode.value,
        mobile: mobile.value
      };
      return validMobileAuthorizationCode(query)
        .then(res => {
          if (res?.data) {
            context.emit('complete');
            SetTracker('Web_Login_Tel_Verify_Pop_Click', {
              enterBy: props.enterBy,
              is_enter: true,
              tel_nub: query.mobile
            });
            return;
          }
          return Promise.reject(res);
        })
        .catch(err => {
          SetTracker('Web_Login_Tel_Verify_Pop_Click', {
            enterBy: props.enterBy,
            is_enter: false,
            tel_nub: query.mobile
          });
          Toast(err.message || '系统错误，请稍后再试');
        })
        .then(() => {
          validating.value = false;
        });
    };
    onMounted(() => {
      isVisible.value = true;
    });
    return {
      isVisible,
      verifyCode,
      mobile,
      mobileShow,
      subtitle,
      mobileErrorMsg,
      isSubmitDisabled,
      validMobileCode,
      handleClose
    };
  }
});
</script>
