/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-07-26 13:52:38
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-11-01 13:38:05
 */
import HomeModule from './home';
import MicroAppModule from './microapp';
import Reading from './reading';
import AiChat from './ai-chat';

const AppModules = [HomeModule, MicroAppModule, Reading, AiChat];

export { AppModules };
