<template>
  <div class="app-menu">
    <MeetingRobot></MeetingRobot>
    <AppDownload />
    <CustomerService />
    <!--    <VipGuide />-->
    <app-user-info @about="openDialog" @logout="onLogoutClick" />
    <confirm-prompt ref="confirmPrompt" @confirm="onLogoutConfirm" />
    <about-us
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      @confirm="closeDialog"
    />
    <!--注：2024-04-24下架账号密码登录引导，by xl-->
    <!--<PasswordGuide />-->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AppDownload from './app-download';
import AppUserInfo from './AppUserInfo';
import MeetingRobot from './MeetingRobot.vue';
// import VipGuide from './vip-guide';
import CustomerService from './customer-service';
import ConfirmPrompt from '@/modules/reading/components/prompt/ConfirmPrompt.vue';
import { Constants } from '@/adapters/config';
import AboutUs from '../../dialog/AboutUs';
// import PasswordGuide from './password-guide';

export default {
  components: {
    AppDownload,
    AppUserInfo,
    ConfirmPrompt,
    AboutUs,
    CustomerService,
    MeetingRobot
    // PasswordGuide
    // VipGuide
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    ...mapActions('homeStore', ['removeGlobalAppData', 'setGlobalAppData']),
    onAboutClick() {},
    async onLogoutClick() {
      await this.setGlobalAppData()
        .then(res => {
          if (!res) {
            this.$router.replace({ path: Constants.VUE_APP_LOGIN_PATH });
            return false;
          }
        })
        .catch(res => {
          // console.log('非登录态');
        });
      const message = {
        type: 'warning',
        title: '确定要退出登录吗？',
        info: ''
      };
      this.$refs.confirmPrompt.open({ message });
    },
    onLogoutConfirm() {
      this.removeGlobalAppData()
        .then(() => {
          this.$message({
            type: 'success',
            message: '退出登录成功！'
          });
          localStorage.removeItem('USER_AUTH_TOKEN');
          this.$router.replace({ path: Constants.VUE_APP_LOGIN_PATH });
        })
        .catch(e => {
          this.$message({
            type: 'error',
            message: e.response.message || '退出登录失败！'
          });
        });
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.app-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding-right: 16px;
}
</style>
