import { dataflow as CoreModuleDataflow } from '@alpha-pai/core';
import { DataFlow } from '@alpha-pai/core/plugins';
// import DataFlow from './dataflowx';
import { AppModules } from './modules';

import { Constants } from '@/adapters/config';
import Nav from '@/adapters/nav';
import AuthStrategy from '@/adapters/auth';
import Layout from '@/adapters/layout';

const config = { Constants, Nav, AuthStrategy, Layout };
const source = [...AppModules].map(m => m.dataflow);
source.unshift(CoreModuleDataflow);
const dataflow = DataFlow.create(source, { config });
const dataflowVuexPlugin = DataFlow.store(dataflow);

export { dataflowVuexPlugin };
export default dataflow;
