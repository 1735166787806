<template>
  <el-dropdown
    class="app-layout-header-menu-item-avatar"
    @command="handleUserCommand"
    trigger="click"
  >
    <span class="el-dropdown-link" style="display: flex;align-items: center;">
      <el-avatar size="small" :src="avatar"></el-avatar>
    </span>
    <el-dropdown-menu slot="dropdown" class="app-layout-header-menu-items">
      <el-dropdown-item icon="el-icon-medal">
        {{ institution.name }}
      </el-dropdown-item>
      <el-dropdown-item icon="el-icon-user">
        {{ user.position }}｜{{ user.name }}
      </el-dropdown-item>
      <el-dropdown-item command="openDrawer" divided icon="el-icon-setting">
        应用设置
      </el-dropdown-item>
      <el-dropdown-item divided command="logout" icon="el-icon-switch-button">
        退出登录
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { CommonUtil } from '@alpha-pai/core/utils';
import defaultAvatarImg from '@/static/images/default-avatar.png';

export default {
  name: 'app-header-menu',

  data() {
    return {
      defaultAvatarImg
    };
  },

  computed: {
    ...mapGetters(['isLoginedUser']),

    ...mapState({
      ui(state) {
        return state.ui || {};
      },
      user(state) {
        return state.global.user || {};
      },
      institution(state) {
        return state.global.institution || {};
      },
      env(state) {
        return state.global.env || 'test';
      }
    }),

    avatar() {
      const { icon, headImgUrl } = this.user;

      if (!icon && !headImgUrl) {
        return this.defaultAvatarImg;
      }

      if (!icon) {
        return headImgUrl;
      }

      if (/^http(s)?/.test(icon)) {
        return icon;
      }

      const { Constants } = this.$services.commonService.getConfig() || {};
      return Constants.BASE_URL + icon;
    }
  },

  methods: {
    ...mapActions(['removeGlobalAppData', 'updateUIStatus']),

    openDrawer() {
      this.updateUIStatus({
        isDrawerVisible: true
      });
    },

    handleUserCommand(command) {
      switch (command) {
        case 'logout':
          this.logout();
          break;
        case 'openDrawer':
          this.openDrawer();
          break;
        default:
          break;
      }
    },

    logout() {
      this.removeGlobalAppData().then(() => {
        CommonUtil.userLogout();

        // CommonUtil.gotoLoginUrl(this.$router.currentRoute.fullPath);
        const { Constants } = this.$services.commonService.getConfig();
        const loginPath =
          Constants.LOGIN_PATH || this.$router.currentRoute.fullPath;
        CommonUtil.gotoUrl(loginPath);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/themes/default/index';
.app-layout-header-menu-item-avatar {
  .el-avatar {
    vertical-align: top;
    margin: 6px 6px 8px 0;
  }
}
</style>
<style lang="scss">
.app-layout-header-menu-items {
  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #f5f7fa;
  }
}
</style>
