import {
  SET_DIALOG_CONTEXT,
  DELETE_DIALOG_CONTEXT,
  SET_TASK_ID,
  DELETE_TASK_ID,
  CLEAR_QUESTION_QUEUE,
  SET_QUESTION_QUEUE
} from './mutationTypes';

export default {
  // 设置Question Answer对话context。
  async updateDialogContext({ commit }, payload) {
    commit(SET_DIALOG_CONTEXT, payload);
  },

  async updateQuestionQueue({ commit }, payload) {
    commit(SET_QUESTION_QUEUE, payload);
  },

  async deleteDialogContext({ commit }, payload) {
    commit(DELETE_DIALOG_CONTEXT);
  },

  // 设置taskId
  async setTaskId({ commit, rootGetters }) {
    commit(SET_TASK_ID, 'task-' + rootGetters.user.id + '-' + Date.now());
  },
  // 关闭对话
  async closeTask({ commit }, payload) {
    commit(DELETE_TASK_ID, payload);
    commit(CLEAR_QUESTION_QUEUE);
    commit(DELETE_DIALOG_CONTEXT);
  }
};
