<!--
 * Copyright © 2022 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2022-07-27 18:22:40
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-11-14 14:53:59
-->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible="true"
    :append-to-body="false"
    :showClose="false"
    custom-class="alpha-saas-admin-reading-confirm-dialog"
    width="432px"
    @close="onCancel"
  >
    <div class="title">
      <img v-if="showIcon" :src="iconUrl" alt="" />
      <span>{{ title }}</span>
    </div>
    <template v-if="text">
      <div class="content" :style="contentStyles" v-if="!dangerMode">
        {{ text }}
      </div>
      <div class="content" :style="contentStyles" v-else v-html="text"></div>
    </template>
    <template v-slot:footer>
      <div class="dialog-footer">
        <Button v-if="showCancelButton" @click="onCancel">{{
          cancelButtonText
        }}</Button>
        <Button
          v-if="showConfirmButton"
          type="primary"
          :disabled="confirmDisable"
          @click="onSave"
          >{{ confirmButtonText }}</Button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  onUnmounted,
  getCurrentInstance
} from '@vue/composition-api';
import Button from '@/common/components/button';
import warning from '@/static/assets/icon/warning.png';

export default defineComponent({
  name: 'DeleteConfirm',

  components: {
    Button
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    dangerMode: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'warn'
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showConfirmButton: {
      type: Boolean,
      default: true
    },
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    confirmButtonText: {
      type: String,
      default: '确定'
    },
    resolve: {
      type: Function,
      default: () => {
        // do nothing
      }
    },
    callback: {
      type: Function,
      default: () => {
        // do nothing
      }
    },
    confirmDisable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconUrl() {
      return this.icon || warning;
    },
    contentStyles() {
      if (this.showIcon) {
        return 'padding-left: 50px;';
      }
      return '';
    }
  },
  setup(props) {
    const vm = getCurrentInstance();
    const onCancel = () => {
      props.resolve(false);
      props.callback();
    };
    const onSave = () => {
      props.resolve(true);
      props.callback();
    };
    onUnmounted(() => {
      document.body.removeChild(vm.proxy.$el);
    });
    return { onCancel, onSave };
  }
});
</script>

<style lang="scss" scoped>
.alpha-saas-admin-reading-confirm-dialog {
  .title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    color: $font-b-1;
    > img {
      height: 34px;
      width: 34px;
      margin-right: 16px;
    }
  }

  .content {
    font-size: 14px;
    line-height: 22px;
    color: $font-b-1;
    margin-top: 12px;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
<style lang="scss">
.alpha-saas-admin-reading-confirm-dialog {
  border-radius: 6px;

  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .el-dialog__header {
    height: 0px;
    padding: 0px;
  }
  .el-dialog__body {
    padding: 32px 24px 32px 32px;
  }
  .el-dialog__footer {
    padding: 0 24px 24px 0;
  }
}
</style>
