/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-12 13:35:45
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-20 15:10:47
 */

import { USER_TYPE_MAP } from './config';
import service from '@/modules/home/http/login';

/**
 * @description: 校验姓名
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 */
export const validateName = (rule, value, callback) => {
  if (!value || !value.trim()) {
    callback(new Error('请输入真实姓名'));
    return;
  }
  if (value.length > 20) {
    callback(new Error('姓名长度不能超过20个字符'));
    return;
  }
  if (value.length < 2) {
    callback(new Error('姓名长度不能少于2个字符'));
    return;
  }

  const nameReg = /^[a-z·.\u4e00-\u9fa5\u3000\u3400-\u4dbf\s]+$/iu;

  if (!nameReg.test(value)) {
    callback(new Error('你输入的名字有误，请输入汉字、字母或·'));
    return;
  }
  callback();
};

/***
 * @description: 校验团队名称  必须是卖方首席分析师或分析师
 * @param {*} formData 表单数据
 * @return {*}
 ***/
export const validateOrgName = fn => {
  const formData = fn();
  return (rule, value, callback) => {
    if (formData.value.roleId === 3 || formData.value.roleId === 4) {
      if (!value) {
        callback(new Error('请输入团队名称'));
      } else {
        callback();
      }
    } else {
      callback();
    }
    callback();
  };
};

/***
 * @description: 校验行业,. 必须是卖方首席分析师或分析师
 * @param {*} formData
 * @return {*}
 ***/
export const validateIndustryIdList = fn => {
  const formData = fn();
  return (rule, value, callback) => {
    if (formData.value.roleId === 3 || formData.value.roleId === 4) {
      if (value.length === 0) {
        callback(new Error('请选择行业'));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
};

/***
 * @description:  校验机构
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 ***/
export const validateInstitutionCode = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请选择所在机构'));
    return;
  }
  callback();
};

/***
 * @description: 校验验证码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 ***/
export const validateCode = fn => {
  const formData = fn();
  return (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入验证码'));
      return;
    }
    service
      .checkEmailCode({
        data: {
          email: formData.value.email,
          verificationCode: value
        }
      })
      .then(res => {
        if (res.data) {
          callback();
        } else {
          callback(new Error('你输入的验证码有误，请重新输入'));
        }
      })
      .catch(err => {
        callback(err);
      });
  };
};

/***
 * @description: 校验名片
 * @param {*} formData
 * @return {*}
 ***/
export const validateCardImageUrls = fn => {
  const formData = fn();
  return (rule, value, callback) => {
    if (formData.value.institutionCode === 'OTHER') {
      if (value.length === 0) {
        callback(new Error('请上传名片'));
        return;
      } else {
        callback();
        return;
      }
    }
    callback();
  };
};

/***
 * @description: 校验邮箱
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 ***/
export const validateEmail = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入邮箱'));
    return;
  }
  // eslint-disable-next-line
  const regex = /^([\.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
  if (!regex.test(value)) {
    callback(new Error('请输入正确的邮箱'));
    return;
  }

  service
    .checkEmailLegal({
      data: {
        email: value
      }
    })
    .then(res => {
      if (res.code === 200000) {
        callback();
      } else {
        callback(new Error('该邮箱已被占用'));
      }
    })
    .catch(err => {
      callback(err);
    });
};

/***
 * @description: 校验角色
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 ***/
export const validateRole = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请选择角色'));
    return;
  }
  callback();
};

/***
 * @description: 获取角色列表
 * @param {*} type 角色类型 USER_TYPE_MAP 中的值
 * @return {*}
 ***/
export const getRoleList = type => {
  const map = {
    [USER_TYPE_MAP.VENDOR]: [
      {
        label: '首席分析师',
        value: 3
      },
      {
        label: '分析师',
        value: 4
      },
      {
        label: '销售',
        value: 2
      }
    ],
    [USER_TYPE_MAP.PURCHASER]: [
      {
        label: '基金经理(含基助)',
        value: 5
      },
      {
        label: '研究员',
        value: 6
      }
    ]
  };
  return map[type];
};

/***
 * @description: 获取角色名称
 * @return {*}
 ***/
export const roleIdMap = {
  3: '首席分析师',
  4: '分析师',
  5: '基金经理(含基助)',
  6: '研究员',
  2: '销售'
};

/***
 * @description: 高亮搜索关键字
 * @param {*} str
 * @param {*} keyword
 * @return {*}
 ***/
export const highlightStr = (str, keyword) => {
  const index = str.indexOf(keyword);
  if (index > -1) {
    const preStr = str.slice(0, index);
    const postStr = str.slice(index + keyword.length);
    const highligtStr = `<span style="color: #dc3f4b;">${keyword}</span>`;
    return `${preStr}${highligtStr}${postStr}`;
  } else {
    return str;
  }
};
