/*
 * @Copyright © 2024 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-08-15 17:21:47
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-05-28 13:42:39
 */
const APP_CONTEXT = {
  ALPHA: 'alpha',
  RABYTE: 'rabyte',
  ADMIN: 'rabyte_admin'
};

// 机构角色枚举
export const InstitutionRoleEnums = {
  vendors: '1',
  purchasers: '2'
};
// 产品版本
export const ProductTypeEnums = {
  trial: 0, // 试用版
  personal: 1, // 个人版
  professional: 2, // 专业版
  institutional: 3, // 机构版
  free: 4 // 免费版
};
const APP_MOBILE_CODE_TEMPLATE_ID = {
  LOGIN: 1,
  DEALER_USER_UPDATE_MOBILE: 11,
  DEALER_USER_OTHER_BROKER_DUP_MOBILE: 8,
  DEALER_USER_OTHER_BUYER_DUP_MOBILE: 9,
  DEALER_USER_OTHER_BROKER_DUP_NAME_MODIFY_MOBILE: 10,
  DEALER_USER_OTHER_BROKER_DUP_NAME_ORIGIN_MOBILE: 8,
  WHITELIST_OTHER_BROKER_DUP_MOBILE: 12
};

export default {
  APP_CONTEXT,
  APP_MOBILE_CODE_TEMPLATE_ID
};
