/*
 * @Copyright © 2022 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2022-07-27 18:48:11
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2022-11-08 19:08:47
 */
import { createApp } from '@vue/composition-api';
import Confirm from './Confirm.vue';

export default function confirm({
  title = '',
  text = '',
  icon,
  showIcon = true,
  dangerMode = false,
  cancelButtonText,
  confirmButtonText,
  showCancelButton,
  showConfirmButton,
  confirmDisable
}) {
  return new Promise((resolve, reject) => {
    const el = document.createElement('div');
    document.body.appendChild(el);
    const instance = createApp(Confirm, {
      text,
      title,
      icon,
      showIcon,
      dangerMode,
      cancelButtonText,
      confirmButtonText,
      showCancelButton,
      showConfirmButton,
      confirmDisable,
      resolve,
      reject,
      callback: () => {
        instance.unmount();
        // debugger; 这里需要注意，vue3 写法需要验证下。
        // document.body.removeChild(el);
      }
    });
    instance.mount(el);
  });
}
