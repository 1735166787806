<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-07-24 13:50:00
 * @LastEditors   :luyue@rabyte.cn
 * @LastEditTime  : 2023-11-10 15:34:02
-->
<template>
  <div class="app-logo">
    <img
      class="logo-img"
      src="@/modules/reading/components/layout/assets/images/logo.png"
      @click="goHome"
    />
    <template v-if="user">
      <img
        v-if="user.trialFlag"
        class="trial-img"
        src="@/modules/reading/components/layout/assets/images/shiyongban.png"
      />
      <img
        v-else-if="user.productType === 1 || user.productType === 2"
        class="professional-img"
        src="@/modules/reading/components/layout/assets/images/zhuanyeban.png"
      />
      <img
        v-else-if="user.productType === 3"
        class="institution-img"
        src="@/modules/reading/components/layout/assets/images/jigouban.png"
      />
      <img
        v-else-if="user.productType === 4"
        class="free-img"
        src="@/modules/reading/components/layout/assets/images/mianfeiban.png"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/' });
    }
  }
};
</script>

<style scoped lang="scss">
.app-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .logo-img {
    cursor: pointer;
    width: 75px;
    margin-right: 8px;
    margin-left: 16px;
  }
  .trial-img,
  .free-img,
  .professional-img {
    width: 40px;
    height: 16px;
  }
  .institution-img {
    width: 53px;
    height: 18px;
  }
}
</style>
