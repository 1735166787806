/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-14 10:30:28
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-19 14:32:59
 */
import { http } from '@/adapters/dao';
import dayjs from 'dayjs';
import { Constants } from '@/adapters/config';

const uploadImg = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async e => {
      const CryptoJS = (await import('crypto-js')).default;
      const wordArray = CryptoJS.lib.WordArray.create(e.target.result);
      const hash = CryptoJS.MD5(wordArray).toString();
      const datePath = dayjs().format('YYYY-MM-DD');
      const ext = file.name.split('.').pop();
      const url = `/external/alpha/data_storage/file/calling-card/${datePath}/${hash}.${ext}`;
      http
        .fetch(
          {
            method: 'POST',
            url,
            data: file
          },
          {
            checkCode: data => data
          }
        )
        .then(() => {
          // 这里没再加 response 相关处理，默认为只要返回就是成功
          const imgUrl = (Constants.BASE_URL || window.location.origin) + url;
          resolve(imgUrl);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    };

    reader.readAsArrayBuffer(file);
  });
};
export default uploadImg;
