export default {
  dialogContext: state => {
    const context = state.dialogContext;
    // context是一个有序的数组，每一项有字段questionId,answer。请按照数组顺序，合并有相同questionId的对象，将answer追加到对象中。
    if (context && context.length > 0) {
      const newContext = [];
      let lastQuestionId = null;
      let lastAnswer = null;
      for (let i = 0; i < context.length; i++) {
        const item = context[i];
        if (item.questionId === lastQuestionId) {
          lastAnswer = lastAnswer.concat(item.answer);
        } else {
          if (lastQuestionId) {
            newContext.push({ questionId: lastQuestionId, answer: lastAnswer });
          }
          lastQuestionId = item.questionId;
          lastAnswer = item.answer;
        }
      }
      if (lastQuestionId) {
        newContext.push({ questionId: lastQuestionId, answer: lastAnswer });
      }
      return newContext.map(dialog => {
        const question = state.questionQueue.find(
          question => question.questionId === dialog.questionId
        );
        return {
          question: question.question,
          answer: dialog.answer
        };
      });
    }
    return [];
  },
  taskId: state => {
    const taskId = state.taskId;
    return taskId;
  }
};
