import { extend } from 'vee-validate/dist/vee-validate.full';
import { required } from 'vee-validate/dist/rules';

extend('mobile', {
  validate(value) {
    // 大陆 || 香港
    return /^1[23456789]\d{9}$/.test(value) || /^00852\d{8}$/.test(value);
  },

  message(value) {
    return `请输入正确的手机号`;
  }
});

extend('input', {
  ...required,
  params: ['fieldDescr'],

  message(value, { fieldDescr }) {
    return `请输入${fieldDescr}`;
  }
});

extend('select', {
  ...required,
  params: ['fieldDescr'],

  message(value, { fieldDescr }) {
    return `请选择${fieldDescr}`;
  }
});

extend('code', {
  validate(value) {
    const len = 6; // 短信验证码
    return new RegExp(`^\\d{${len}}$`).test(value);
  },

  message() {
    return `验证码有误，请重新输入`;
  }
});

extend('mobile', {
  validate(value) {
    return /(?:0|86|\+86)?1[3-9]\d{9}/.test(value);
  },

  message() {
    return `请输入正确的手机号`;
  }
});

extend('email', {
  validate(value) {
    if (!value) {
      return true;
    }

    return /^[A-Za-z0-9.\-\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
      value
    );
  },

  message() {
    return `请输入正确的邮箱`;
  }
});

extend('selectInOptions', {
  params: ['fieldDescr', 'options'],

  validate(value, { options }) {
    if (!value) {
      return true;
    }

    // 注：value 可能是数组，目前行业支持多选
    if (typeof value === 'object') {
      let flag = true;
      value.forEach(item => {
        if (
          options.indexOf(item) === -1 &&
          options.indexOf(value + '') === -1
        ) {
          flag = false;
        }
      });
      return flag;
    } else {
      return options.indexOf(value) > -1 || options.indexOf(value + '') > -1;
    }
  },

  message(value, { fieldDescr, _value_, options }) {
    // 注：value 可能是数组，目前行业支持多选
    if (typeof value === 'object') {
      const list = [];
      value.forEach(item => {
        if (
          options.indexOf(item) === -1 &&
          options.indexOf(value + '') === -1
        ) {
          list.push(item);
        }
      });
      return `未找到与"${list.join(',')}"匹配的${fieldDescr}`;
    } else {
      return `未找到与"${_value_}"匹配的${fieldDescr}`;
    }
  }
});

// 修改券商用户信息时，手机号是否被占用
extend('mobileExisted', {
  params: ['existed'],

  validate(value, { existed }) {
    return !existed || existed === 'false';
  },

  message() {
    return `该手机号已被占用`;
  }
});

// 登录时验证码无效
extend('validCode', {
  params: ['valid'],

  validate(value, { valid }) {
    return !valid || valid === 'true';
  },

  message() {
    return `验证码有误，请重新输入`;
  }
});

// 登录时手机号无权限
extend('authorizedMobile', {
  params: ['authorized'],

  validate(value, { authorized }) {
    return !authorized || authorized === 'true';
  },

  message() {
    return `抱歉，该手机号暂无权限`;
  }
});

// 图形验证码
extend('captcha', {
  validate(value) {
    const len = 4; // 短信验证码
    return new RegExp(`^[A-Za-z0-9]{${len}}$`).test(value);
  },

  message() {
    return `验证码有误，请重新输入`;
  }
});

// 登录时图形验证码无效
extend('validCaptcha', {
  params: ['valid', 'message'],

  validate(value, { valid }) {
    return !valid || valid === 'true';
  },

  message(value, { message }) {
    return message || `验证码有误，请重新输入`;
  }
});
