<template>
  <div v-if="visible" class="cp-verify-failed-modal">
    <div class="content">
      <h1>身份认证失败</h1>
      <div class="failed-reason">
        {{ reason }}
      </div>
      <!-- <custom-button
        type="primary"
        size="medium"
        label="我知道了"
        @click="handleClose"
        class="btn-confirm"
      /> -->
      <p>
        <span>有任何建议或问题，欢迎随时</span>
        <a href="javascript:;" @click="handleConnectUs">
          联系我们<i class="el-icon-arrow-right" />
        </a>
      </p>
      <div class="btn-box">
        <custom-button
          type="default"
          size="medium"
          label="继续试用"
          @click="handleContinue"
          v-show="continueUseShow"
          class="btn-confirm left"
        />
        <custom-button
          type="primary"
          size="medium"
          label="重新认证"
          @click="handleConfirm"
          class="btn-confirm right"
        />
      </div>
      <i
        class="iconfont icon-guanbi close-icon"
        v-show="!continueUseShow"
        @click="onClose"
      ></i>
    </div>

    <AuthenticationModal
      ref="authenticationModal"
      @close="handleClose"
      enter-by="认证失败弹窗"
    />
  </div>
</template>
<script>
import service from '@/modules/home/http/login.js';
import { mapGetters } from 'vuex';
import { BUSINESS_CARD_VERIFICATION_FAILED_REASON_ENUMS } from '@/modules/home/config/enum.js';
import dam from '@/common/components/dedicated-account-manager';

import SetTracker from '@/common/utils/setTracker';

export default {
  name: 'VerifyFailedModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AuthenticationModal: () => import('../authentication-modal')
  },
  data() {
    return {
      reason: ''
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAuditDetail();
        }
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    // 是否还可以继续试用，也就是试用天数问题
    continueUseShow() {
      return this.user.remainAmount !== 0;
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    /**
     * @description: 继续试用
     * @return {*}
     */
    handleContinue() {
      this.$emit('continue-use');
      SetTracker('Web_Verify_Failed_Button_Click', {
        button_name: '继续试用'
      });
    },
    /**
     * @description:  未过期情况下，点击我知道了，进入系统
     * @return {*}
     */
    handleClose() {
      if (this.continueUseShow) {
        this.$emit('continue-use');
      } else {
        this.$emit('close');
      }
    },
    async handleConfirm() {
      SetTracker('Web_Verify_Failed_Button_Click', {
        button_name: '重新认证'
      });
      this.$refs.authenticationModal.handleOpen(result => {
        if (result.type === 'email' && this.continueUseShow) {
          this.handleContinue();
        }
      });
    },
    getAuditDetail() {
      service.getAuditDetail().then(res => {
        console.log(res, BUSINESS_CARD_VERIFICATION_FAILED_REASON_ENUMS);
        let reason = '';
        if (res.data.auditType === '5') {
          reason = res.data.auditText || '';
        } else {
          reason =
            BUSINESS_CARD_VERIFICATION_FAILED_REASON_ENUMS[res.data.auditType];
        }
        this.reason = reason;
        SetTracker('Web_Verify_Failed_Page_View', {
          err_log: reason
        });
      });
    },
    /**
     * @description: 联系我们
     * @return {*}
     */
    handleConnectUs() {
      dam(this.$store, {
        enterBy: '认证失败弹窗'
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';

.cp-verify-failed-modal {
  // z-index: 10001;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 486px;
    min-height: 232px;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #dfeaff 0%, #fff 45.05%);
    background-color: #fff;
    text-align: center;
    padding-bottom: 24px;
    .btn-box {
      display: flex;
      align-content: center;
      justify-content: center;
      margin-top: 24px;
      .btn-confirm {
        &.left {
          margin-right: 24px;
          &:hover {
            background: #ffffff;
            border-color: $theme-b-2;
            color: $theme-b-2;
          }
        }
        &.right {
          margin-left: 0px;
        }
      }
    }
    h1 {
      margin: 48px 0 0px;
      line-height: 26px;
      font-size: 18px;
      font-weight: 600;
      color: $font-b-1;
    }
    img {
      display: block;
      margin: auto;
      width: 120px;
      height: 120px;
      border-radius: 6px;
      border: 1px solid $theme-b-4;
    }
    p {
      margin-top: 24px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $font-b-2;
    }
    .failed-reason {
      color: $font-b-2;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      max-width: 284px;
      margin: 8px auto 0;
    }
    .btn-confirm {
      // position: absolute;
      // bottom: 32px;
      // left: 50%;
      // transform: translate(-50%);
      width: 200px;
      &.left {
        width: 132px;
      }
    }
  }
  .close-icon {
    position: absolute;
    top: 20px;
    right: 24px;
    cursor: pointer;
    font-size: 16px;
    color: $font-b-2;
  }
}
</style>
