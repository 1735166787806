/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-07-11 10:41:26
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-21 15:15:09
 */
import Dao from '@/adapters/dao';
import { CommonUtil } from '@alpha-pai/core/utils';
import { Constants } from '@/modules/home/config';

class CommonDao extends Dao {
  async logout() {
    return this.fetch({
      method: 'post',
      url: '/external/alpha/api/v2/authentication/logout'
    });
  }

  async getAppUserInfo(options = {}) {
    if (!CommonUtil.getAuthToken()) {
      return {};
    }
    // TODO  暂不需要权限控制
    // return Promise.all([this.getUserInfo(), this.getUserAuth()]).then(
    //   ([user = {}, auth = []]) => {
    //     return {
    //       global: {
    //         user: { ...user, id: user.userId },
    //         auth
    //       }
    //     };
    //   }
    // );

    const user = await this.getUserInfo();
    const { managerFlag, institutionId } = user;
    const auth =
      institutionId > -1 && managerFlag === '1'
        ? Constants.SAAS_ADMIN_RESOURCE_AUTH_MAP
        : [];
    return {
      global: {
        user: { ...user, id: user.id || user.uid },
        auth
      }
    };
  }

  async getUserInfo(options = {}) {
    return this.fetch({
      url: '/external/alpha/api/v2/authorization/user/info'
    }).then(json => {
      return json.data;
    });
  }

  async getUserToken(options = {}) {
    return this.fetch({
      url:
        '/external/alpha/api/v2/authorization/authority/getToken?subSystemCode=alpha-web',
      params: options.params
    }).then(json => {
      return json.data;
    });
  }

  // 外部系统跳转登录 后端-世亨
  async ssoLogin(options = {}) {
    return await this.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authentication/token/sso',
      ...options
    });
  }

  // 外部系统跳转登录 后端-靖靖
  async covertToken(options = {}) {
    return await this.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authentication/token/convert',
      ...options
    });
  }
}

export default CommonDao;
