<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-11 15:14:37
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-19 16:46:40
-->
<template>
  <div v-if="type">
    <div v-show="!isIntroModalVisible">
      <EndOfVipModal
        :is-purchasers="isPurchasers"
        :visible="type === TYPE_MAP.END_OF_VIP"
        @intro="handleIntroModalShow"
        @close="handleClose"
      />
      <EndOfProbationModal
        :is-purchasers="isPurchasers"
        :visible="type === TYPE_MAP.END_OF_PROBATION"
        @intro="handleIntroModalShow"
        @close="handleClose"
      />
      <VerifyVerifyingModal
        :is-purchasers="isPurchasers"
        :visible="type === TYPE_MAP.VERIFY_VERIFYING"
        @intro="handleIntroModalShow"
        @close="handleClose"
      />
      <VerifyFailedModal
        :is-purchasers="isPurchasers"
        :visible="type === TYPE_MAP.VERIFY_FAILED"
        @intro="handleIntroModalShow"
        @close="handleClose"
        @continue-use="$emit('continue-use')"
      />
    </div>
    <VipIntroductionModal
      :visible="isIntroModalVisible"
      @close="handleIntroModalClose"
    />
  </div>
</template>
<script>
import VipIntroductionModal from '../vip-introduction-modal';

import EndOfVipModal from './end-of-vip-modal';
import EndOfProbationModal from './end-of-probation-modal';
import VerifyFailedModal from './verify-failed-modal';
import VerifyVerifyingModal from './verify-verifying-modal';

const TYPE_MAP = {
  END_OF_VIP: 'endOfVip', // 会员到期 只有买方才有
  END_OF_PROBATION: 'endOfProbation', // 试用结束
  VERIFY_VERIFYING: 'verifyVerifying', // 认证审核中
  VERIFY_FAILED: 'verifyFailed' // 认证失败
};

// 18345669875

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    isPurchasers: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EndOfVipModal,
    EndOfProbationModal,
    VipIntroductionModal,
    VerifyFailedModal,
    VerifyVerifyingModal
  },
  data() {
    return {
      isIntroModalVisible: '',
      TYPE_MAP: Object.freeze(TYPE_MAP)
    };
  },
  methods: {
    handleIntroModalShow(v) {
      this.isIntroModalVisible = v;
    },
    handleIntroModalClose() {
      this.isIntroModalVisible = '';
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>
