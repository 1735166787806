<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-08-15 17:21:47
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-20 16:56:55
-->
<template>
  <div class="cp-verify-verifying-modal" v-if="visible">
    <div class="content">
      <div class="title">
        <img src="@/static/images/verify-successed-modal-symbol1.png" alt="" />
        <p>身份认证申请已提交</p>
        <img src="@/static/images/verify-successed-modal-symbol2.png" alt="" />
      </div>
      <div class="desc">
        <p>小派将在1-3个工作日内完成审核</p>
        <p>审核结果将以短信方式通知你，敬请关注</p>
      </div>
      <custom-button
        type="primary"
        size="medium"
        label="我知道了"
        @click="handleClose"
        class="btn-confirm"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';

.cp-verify-verifying-modal {
  z-index: 1999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 486px;
    height: 246px;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #dfeaff 0%, #fff 45.05%);
    background-color: #fff;
    text-align: center;
    .title {
      padding-top: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $theme-b-1;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;

      img {
        top: 50%;
        left: 100%;
        width: 60px;
        height: 60px;
      }
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $font-b-2;
      p:first-child {
        margin-top: 4px;
      }
    }
    .btn-confirm {
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translate(-50%);
      width: 200px;
    }
  }
}
</style>
