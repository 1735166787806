<!--
 * Copyright © 2024 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2024-10-12 14:29:40
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-11-05 14:04:03
-->
<template>
  <el-popover
    popper-class="meeting-robot-tip-popover"
    placement="bottom-start"
    trigger="hover"
    :visible-arrow="false"
    v-if="isPurchasers"
  >
    <div slot="reference" class="app-download-text">
      <img src="@/static/assets/images/robot-tip-icon.png" alt="" />会议助理
    </div>
    <div class="app-download-container">
      <img
        class="download-img"
        src="https://alphapai-web.rabyte.cn/external/alpha/data_storage/file/personal-robot-tip/header-robot-tip.png"
        alt=""
      />
    </div>
  </el-popover>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'MeetingRobot',
  computed: {
    ...mapGetters(['user']),
    isPurchasers() {
      return this.user && `${this.user.type}` === '2';
    }
  }
};
</script>

<style scoped lang="scss">
.app-download-text {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.06em;
  color: $white;
  cursor: pointer;
  padding: 0 12px;
  &:hover {
    background: $theme-bg-2;
  }
  > img {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }
}
.app-download-container {
  img {
    width: 142px;
    height: 182px;
  }
  width: 142px;
  height: 182px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss">
.meeting-robot-tip-popover {
  border: 0;
  padding: 0 !important;
  width: 142px;
  height: 182px;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  &.el-popper[x-placement^='bottom'] {
    margin-top: 0;
  }
}
</style>
