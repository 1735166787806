<template>
  <MeiShi />
</template>
<script>
import MeiShi from './mei-shi.vue';
export default {
  components: {
    MeiShi
  }
};
</script>
