<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : luyue01@rabyte.cn
 * @Date          : 2023-11-13 09:49:29
 * @LastEditors   : luyue luyue01@rabyte.cn
 * @LastEditTime  : 2023-11-13 09:49:29
-->
<template>
  <el-popover
    ref="appMenuPopover"
    popper-class="app-userinfo-popover"
    placement="bottom-end"
    trigger="hover"
    :visible-arrow="false"
  >
    <div slot="reference" class="app-userinfo-container">
      <img
        class="avatar-img"
        :src="require('../../../assets/images/avatar.png')"
        alt=""
      />
      <span class="username">{{ userName }}</span>
    </div>
    <div class="app-menu-container">
      <div
        class="menu"
        v-for="menu in finalMenus"
        :key="menu.key"
        @click="onClick(menu)"
      >
        <div class="menu-name">
          <span class="name">{{ menu.name }}</span>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Constants } from '@/adapters/config';

export default {
  data() {
    return {
      menus: [
        {
          type: 'router',
          name: '个人主页',
          value: 'home',
          address: '/reading/profile/collect'
        },
        {
          type: 'router',
          name: '收藏',
          value: 'collect',
          address: '/reading/profile/collect'
        },
        {
          type: 'router',
          name: '历史记录',
          value: 'history',
          address: '/reading/profile/history'
        },
        {
          type: 'dialog',
          name: '关于我们',
          value: 'aboutUs'
        },
        {
          type: 'router',
          name: '密码管理',
          value: 'password-manage',
          address: '/reading/profile/password-manage'
        },
        {
          type: 'dialog',
          name: '退出',
          value: 'logout'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['user']),
    userName() {
      return this.user?.name || '未认证';
    },
    isVendors() {
      return this.user?.type === '1';
    },
    // 注：卖方用户只有关于我们、退出
    finalMenus() {
      if (this.isVendors) {
        const arr = ['aboutUs', 'logout', 'password-manage'];
        return this.menus.filter(item => arr.includes(item.value));
      } else {
        return this.menus;
      }
    },
    isCooperateUser() {
      return this.user?.cooperateFlag === 1;
    }
  },
  methods: {
    ...mapActions('homeStore', ['removeGlobalAppData', 'setGlobalAppData']),
    onClick(menu) {
      if (menu.type === 'router') {
        this.$refs.appMenuPopover.doClose();
        this.toHome();
        if (this.$route.path !== menu.address) {
          this.$router.push({ path: menu.address });
        }
        return;
      }
      if (menu.value === 'aboutUs') {
        // 关于我们弹框
        this.$emit('about');
      } else if (menu.value === 'logout') {
        // 退出登录
        this.$emit('logout');
      }
    },
    async toHome() {
      await this.setGlobalAppData()
        .then(res => {
          // console.log('登录态', res);
          if (!res) {
            this.$router.replace({ path: Constants.VUE_APP_LOGIN_PATH });
            return false;
          }
        })
        .catch(res => {
          // console.log('非登录态');
        });
    }
  }
};
</script>

<style scoped lang="scss">
.app-userinfo-container {
  position: relative;
  padding: 0 12px !important;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $theme-bg-2;
  }
  .avatar-img {
    width: 24px;
    height: 24px;
  }
  .username {
    margin: 0 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: $white;
    cursor: pointer;
    max-width: 56px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.app-menu-container {
  width: 124px;
  .menu {
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $font-b-1;
    height: 32px;
    cursor: pointer;
    .default-icon {
      visibility: visible;
    }
    .selected-icon {
      visibility: hidden;
    }
    &:hover {
      background: $color-bg-1;
      .default-icon {
        visibility: hidden;
      }
      .selected-icon {
        visibility: visible;
      }
    }
    .menu-name {
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
    }
  }
}
</style>

<style lang="scss">
.app-userinfo-popover {
  top: 38px !important;
  margin-top: 14px;
  padding: 12px 0;
  border-radius: 4px;
  border: 0;
}
</style>
