import { Enum } from '@/common/config';
import DefaultNav from './default/nav';

let Nav;

switch (process.env.VUE_APP_CURRENT_CONTEXT) {
  case Enum.APP_CONTEXT.RABYTE:
  default:
    Nav = DefaultNav;
    break;
}

export default Nav;
