/*
 * @Copyright © 2022 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : silverbulllet@163.com
 * @Date          : 2022-06-22 17:24:51
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-06-27 15:27:50
 */
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend
} from 'vee-validate/dist/vee-validate.full';

import './rules';
import VeeLocale from './locale';

// console.log('TODO：需要优化', VeeLocale);

const install = function(Vue, { locale = 'zh' } = {}) {
  localize(VeeLocale);

  localize(locale);

  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);
};

export default {
  name: 'validate',

  localize,
  extend,
  install
};
