import { render, staticRenderFns } from "./SearchSuggestion.vue?vue&type=template&id=3f0e4368&scoped=true"
import script from "./SearchSuggestion.vue?vue&type=script&lang=js"
export * from "./SearchSuggestion.vue?vue&type=script&lang=js"
import style0 from "./SearchSuggestion.vue?vue&type=style&index=0&id=3f0e4368&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0e4368",
  null
  
)

export default component.exports