import { FormatEntity } from '@alpha-pai/base';

import {
  showDateText,
  showPvText,
  showSimpleDateText,
  showPv
} from '@/modules/reading/utils/index.js';

// *************1
const formatters = {
  list(source) {
    const data = source.list || [];
    const type = source.type;
    return data.map(
      ({
        id,
        title,
        content,
        sourceId,
        sourceName,
        url,
        date,
        industryName,
        pv,
        institution,
        feature,
        read = false
      }) => {
        return {
          id,
          title,
          content,
          type,
          typeText: 'public',
          author: {
            id: sourceId,
            name: sourceName,
            url,
            industryName,
            institution
          },
          date: showSimpleDateText(date),
          fineDate: showDateText(date),
          pv: showPvText(pv),
          showPv: showPv(date) && pv,
          hasRead: read,
          featureHas: feature,
          feature: feature
            ? feature
                .filter(x => x !== '机构观点')
                .filter(x => x !== '产业资讯')
            : []
        };
      }
    );
  },
  total(source) {
    return source.total || 0;
  }
};

class AccountArticleListResponse extends FormatEntity {
  constructor(source = {}, customFormatters) {
    super();
    this.define(Object.assign({}, source));
    this.init(source, Object.assign({}, formatters, customFormatters));
  }
}

export default AccountArticleListResponse;
