import { PositionRoleEnums } from './enums';

export const SUCCESS_MSG = 'Success';

// 路演纪要
const READING_SUMMARY_TYPE = 31;

// 市场资讯文章
const READING_WECHAT_ARTICLE_TYPE = 32;

// 机构观点
const READING_INSTITUTION_VIEWPOINT_TYPE = 321;

// 产业资讯
const READING_MARKET_CONSULTATION_TYPE = 322;

// 某个市场资讯文章
const READING_EXACT_WECHAT_ARTICLE_TYPE = 33;

// 默认排序
const LIST_SORT_DEFAULT = 0;

// 最新排序
const LIST_SORT_NEW_TOP = 10;

// 最热排序
const LIST_SORT_HOT_TOP = 20;

// 市场资讯文章排序类型
const PUBLIC_ARTICLE_LIST_SORT_TYPES = [
  { label: '最新', value: LIST_SORT_NEW_TOP },
  { label: '最热', value: LIST_SORT_HOT_TOP }
];

// 市场资讯文章、概要搜索排序类型
const SEARCH_ARTICLE_LIST_SORT_TYPES = [
  { label: '默认', value: LIST_SORT_DEFAULT },
  { label: '最新', value: LIST_SORT_NEW_TOP }
];

// 路演纪要特色列表
const READING_SUMMARY_FEATURES = [
  {
    label: '专家路演',
    value: '专家路演'
  },
  {
    label: '高管参会',
    value: '高管参会'
  },
  {
    label: '首次覆盖',
    value: '首次覆盖'
  },
  {
    label: '发布会',
    value: '发布会'
  },
  {
    label: '基金经理观点',
    value: '基金经理观点'
  }
];

// 买方端职位角色列表
const PURCHASERS_POSITION_ROLE_LIST = [
  {
    name: '基金经理(含基助)',
    id: PositionRoleEnums.fundManager
  },
  {
    name: '研究员',
    id: PositionRoleEnums.researcher
  },
  {
    name: '市场团队',
    id: PositionRoleEnums.marketingTeam
  },
  {
    name: '其他',
    id: PositionRoleEnums.purchasersOtherRole
  }
];
const VENDORS_POSITION_ROLE_LIST = [
  {
    name: '首席分析师',
    id: PositionRoleEnums.chiefAnalyst
  },
  {
    name: '分析师',
    id: PositionRoleEnums.analyst
  },
  {
    name: '销售',
    id: PositionRoleEnums.sales
  }
];

// 搜索栏默认轮播问题
const DEFAULT_QUESTION_LIST = [
  '主机厂对V2X模组的需求量有多大？',
  '欧洲电网建设的投资预算有多少？',
  'Temu和Shein的商业逻辑有何区别？',
  '用于治疗支原体感染的药物种类有哪些，敏感率、耐药性如何？',
  '钙钛矿企业的量产效率情况如何？',
  '为什么美光比海力士在HBM的市占率低？',
  '奈雪的茶在下沉市场的战略规划？',
  '华为剥离车BU利好哪些公司？',
  '保险公司为何2023Q3利润普遍下滑？',
  '华为液冷超充的进展？',
  '人形机器人国内厂商进度？',
  '特斯拉Cybertruck国内供应商有哪些公司？',
  '复合集流体/复合铜箔的上车进展如何？',
  '目前无人驾驶方案中激光雷达的量有多少？',
  '液冷超充产业链有哪些公司？',
  '拼多多新的业绩增长点主要源于哪部分业务？',
  '短剧行业的市场空间有多大？',
  '人形机器人各个环节的价值量是多少？',
  '欧盟电网升级利好国内哪些公司？',
  '中国新能源汽车过去三年出海的增长情况？',
  '金元顺安缪玮彬最新增持了哪些公司？'
];

// 获取pdf文件路径的超时时间，单位：毫秒
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const PDF_TIMEOUT = MINUTE;

export default {
  SUCCESS_MSG,
  READING_SUMMARY_TYPE,
  READING_WECHAT_ARTICLE_TYPE,
  READING_INSTITUTION_VIEWPOINT_TYPE,
  READING_MARKET_CONSULTATION_TYPE,
  READING_EXACT_WECHAT_ARTICLE_TYPE,
  LIST_SORT_DEFAULT,
  LIST_SORT_NEW_TOP,
  LIST_SORT_HOT_TOP,
  PUBLIC_ARTICLE_LIST_SORT_TYPES,
  SEARCH_ARTICLE_LIST_SORT_TYPES,
  READING_SUMMARY_FEATURES,
  PURCHASERS_POSITION_ROLE_LIST,
  VENDORS_POSITION_ROLE_LIST,
  DEFAULT_QUESTION_LIST,
  PDF_TIMEOUT
};
