<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : luyue01@rabyte.cn
 * @Date          : 2023-11-10 15:32:31
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-09-29 14:03:17
-->
<template>
  <div class="app-nav">
    <!-- <PlateGuide
      v-if="!isVendors"
      @visible-change="disableScrollMenu = $event"
    /> -->
    <el-menu
      :default-active="activeIndex"
      :router="true"
      :class="{ 'mouse-in': mouseInMenu, 'disable-scroll': disableScrollMenu }"
      @mouseenter.native="mouseInMenu = true"
      @mouseleave.native="mouseInMenu = false"
      @select="onSelected"
    >
      <template v-for="item in finalMenus">
        <el-menu-item
          v-if="!item.children"
          :index="item.address"
          :key="item.value"
          :id="`aside-menu-${item.value}`"
          :class="item.specClass || ''"
        >
          <template v-if="item.iconImg">
            <img :src="item.iconImg" class="icon-img" alt="" />
            <img :src="item.iconImgActive" class="icon-img active" alt="" />
          </template>
          <i v-else class="iconfont" :class="item.icon" />

          <span class="menu-name"> {{ item.name }}</span>
          <img
            v-if="item.isNew"
            class="menu-flag"
            src="~@/static/images/new.png"
            alt=""
          />
          <img v-if="item.flag" class="menu-flag" :src="item.flag" alt="" />
          <img v-if="item.for" class="menu-flag" :src="item.for" alt="" />
        </el-menu-item>

        <el-popover
          placement="right"
          title=""
          width="106"
          trigger="hover"
          v-if="item.children"
          :visible-arrow="false"
          popper-class="app-nav-second-menu-popover"
          :key="item.value"
        >
          <li
            class="custom-group el-menu-item"
            :class="[
              item.specClass || '',
              { 'is-active': handleCustomActive(item) }
            ]"
            slot="reference"
          >
            <template v-if="item.iconImg">
              <img :src="item.iconImg" class="icon-img" alt="" />
              <img :src="item.iconImgActive" class="icon-img active" alt="" />
            </template>
            <i v-else class="iconfont" :class="item.icon" />

            <span class="menu-name"> {{ item.name }}</span>
            <img
              v-if="item.isNew"
              class="menu-flag"
              src="~@/static/images/new.png"
              alt=""
            />
            <img v-if="item.flag" class="menu-flag" :src="item.flag" alt="" />
          </li>

          <template>
            <el-menu-item
              v-for="child in item.children"
              :index="child.address"
              :key="child.value"
              :id="`aside-menu-${child.value}`"
            >
              <template v-if="child.iconImg">
                <img :src="child.iconImg" class="icon-img" alt="" />
                <img
                  :src="child.iconImgActive"
                  class="icon-img active"
                  alt=""
                />
              </template>
              <i v-else class="iconfont" :class="child.icon" />

              <span class="menu-name"> {{ child.name }}</span>
              <img
                v-if="child.isNew"
                class="menu-flag is-new"
                src="~@/static/images/new.png"
                alt=""
              />
              <img
                v-if="child.flag"
                class="menu-flag"
                :src="child.flag"
                alt=""
              />
            </el-menu-item>
          </template>
        </el-popover>
      </template>
    </el-menu>
    <img
      class="triangle-radius"
      src="@/modules/reading/components/layout/assets/images/triangle.png"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import PlateGuide from './plate-guide.vue';
// import announcementIcon from '@/static/images/nav-announcement.png';
import foreignMarket from '../../assets/images/foreign-market.png';
import foreignMarketActive from '../../assets/images/foreign-market-active.png';
import aiCopilot from '../../assets/images/ai-copilot.png';
import aiCopilotActive from '../../assets/images/ai-copilot-active.png';
import { ProductTypeEnums } from '@/common/config/enum';
import { isBasicTrailVersion } from '@/common/utils/user';
import fl from '@/modules/reading/components/layout/assets/images/foreign-tip.png';
export default {
  /* components: {
    PlateGuide
  }, */
  data() {
    return {
      activeIndex: '/reading/home/my-focus',
      purchasersMenus: [
        {
          name: '首页',
          value: 'myFocus',
          address: '/reading/home/my-focus',
          icon: 'icon-shouye'
        },
        {
          name: '日历',
          value: 'calendar',
          address: '/reading/home/calendar',
          icon: 'icon-rili'
        },
        {
          name: '海外',
          value: 'foreignMarket',
          address: '/reading/foreign-market',
          iconImg: foreignMarket,
          iconImgActive: foreignMarketActive
        },
        {
          name: '个股',
          value: 'stock',
          address: '/reading/home/stock',
          icon: 'icon-gegu'
        },

        {
          name: '板块',
          value: 'plate',
          address: '/reading/home/segment',
          icon: 'icon-bankuai'
          // flag: require('../../assets/images/nav-icon-chain.png')
        },
        {
          name: 'ETF',
          value: 'etf-index',
          address: '/reading/home/etf',
          icon: 'icon-ETF'
        },
        {
          name: '纪要',
          value: 'meeting',
          address: '/reading/home/meeting',
          icon: 'icon-jiyao'
        },
        // 注：目前仅有万家用户有会议
        {
          name: '会议',
          value: 'public-roadshow',
          address: '/reading/home/public-roadshow',
          icon: 'icon-luyan'
        },
        {
          name: '点评',
          value: 'comment',
          address: '/reading/home/comment',
          icon: 'icon-dianping1'
        },
        {
          name: '研报',
          value: 'report',
          address: '/reading/home/report',
          icon: 'icon-yanbao1',
          for: fl
        },

        // {
        //   name: '转记',
        //   value: 'convert-meeting',
        //   address: '/reading/home/convert-meeting',
        //   icon: 'icon-zhuanji'
        // },
        // {
        //   name: '翻译',
        //   value: 'translate-tool',
        //   address: '/reading/translate-tool',
        //   icon: 'icon-fanyi',
        //   isNew: false
        // },
        {
          name: 'AI助理',
          iconImg: aiCopilot,
          iconImgActive: aiCopilotActive,
          value: 'ai-copilot',
          isNew: true,
          children: [
            {
              name: '转记',
              value: 'convert-meeting',
              address: '/reading/home/convert-meeting',
              icon: 'icon-zhuanji',
              isNew: true
            },
            {
              name: '翻译',
              value: 'translate-tool',
              address: '/reading/translate-tool',
              icon: 'icon-fanyi',
              isNew: false
            }
          ]
        },
        {
          name: '更多',
          icon: 'icon-gengduo',
          value: 'more',
          specClass: 'more',
          children: [
            {
              name: '调研',
              value: 'survey',
              address: '/reading/home/research',
              icon: 'icon-tiaoyan'
            },
            {
              name: '资讯',
              value: 'news',
              address: '/reading/home/news',
              icon: 'icon-zixun'
            }
          ]
        }
      ],
      vendorMenu: [
        {
          name: '公告',
          value: 'announcement',
          address: '/reading/home/announcement',
          icon: 'icon-gonggao1'
        },
        {
          name: '翻译',
          value: 'translate-tool',
          address: '/reading/translate-tool',
          icon: 'icon-fanyi'
        },
        {
          name: '转记',
          value: 'convert-meeting',
          address: '/reading/home/convert-meeting',
          icon: 'icon-zhuanji',
          isNew: true
        }
      ],
      mouseInMenu: false,
      disableScrollMenu: false,
      activeMenu: null
    };
  },
  computed: {
    ...mapGetters(['user']),
    isVendors() {
      return this.user?.type === '1';
    },
    // 是否是试用用户
    isTestUser() {
      return isBasicTrailVersion(this.user);
    },
    isInvestmentUser() {
      const roleId = this.user?.roleId;
      return roleId === 5 || roleId === 6;
    },
    hasAuth() {
      return this.user?.summaryHiddenFlag === 0;
    },
    isLimitedToFreeUser() {
      return this.user?.productType === ProductTypeEnums.free;
    },
    // 注：卖方只有录音转纪要，公告
    // 注：买方基金经理、研究员+非黑名单用户有会议速记，买方所有用户均有机构观点、产业资讯
    // 注：临时需求万家基金有会议菜单
    // 注：万家基金测试环境生产环境institutionCode相同
    finalMenus() {
      if (this.isVendors) {
        return this.vendorMenu;
      } else {
        let menus =
          this.user?.institutionCode !== 'HINST0000000000000219'
            ? this.purchasersMenus.filter(
                item => item.value !== 'public-roadshow'
              )
            : this.purchasersMenus;
        if (!this.hasAuth) {
          menus = menus.filter(item => item.value !== 'meeting');
        }
        if (this.isTestUser || this.isLimitedToFreeUser) {
          menus = menus.filter(item => item.value !== 'foreignMarket');
        }
        return menus;
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(p) {
        this.activeIndex = p.path;
      }
    }
  },
  methods: {
    onSelected(key, keyPath) {
      this.activeMenu = key;
    },
    handleCustomActive(item) {
      return item.children.map(i => i.address).includes(this.activeIndex);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-height: 580px) {
  .app-nav {
    transform: scale(0.9);
    transform-origin: top;
    height: 110% !important;
    > .triangle-radius {
      right: -13px !important;
    }
  }
}
.app-nav {
  z-index: 1999;
  width: 58px;
  // height: 100%;
  min-height: 1px;
  background: $theme-bg-1;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  // padding-top: 4px;
  position: relative;
  height: 100%;
  ::v-deep.el-menu {
    width: 58px;
    background: transparent;
    border: transparent;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    &.mouse-in {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
    &.disable-scroll {
      overflow-y: hidden !important;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: $theme-bg-1;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-bg-6;
      border-radius: 2px;
      visibility: hidden;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $color-bg-6;
    }
  }
  ::v-deep.el-menu-item {
    height: 50px;
    line-height: 20px;
    color: $white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4px;
    position: relative;
    .iconfont {
      color: $white;
      opacity: 0.7;
      font-size: 16px;
    }
    .icon-img {
      height: 16px;
      width: 16px;
      &.active {
        display: none;
      }
    }
    .menu-name {
      opacity: 0.7;
    }
    // .menu-new {
    //   position: absolute;
    //   width: 23px;
    //   height: 11px;
    //   right: 6px;
    //   top: -6px;
    // }
    .menu-flag {
      position: absolute;
      width: auto;
      height: 11px;
      left: 50%;
      margin-left: -7px;
      top: -6px;
    }
    &.more {
      position: sticky;
      bottom: 0;
      background: #011035;
      border-top: 1px solid #3a4160;
    }
  }
  ::v-deep.el-menu-item:hover {
    opacity: 1;
    color: $white;
    background: transparent;
    .iconfont,
    .menu-name {
      opacity: 1;
    }
    .icon-img {
      &.active {
        display: inline-block;
      }
      &:not(.active) {
        display: none;
      }
    }
  }
  ::v-deep.el-menu-item:focus {
    opacity: 1;
    color: $white;
    background: transparent;
    .iconfont,
    .menu-name {
      opacity: 1;
    }
    .icon-img {
      &.active {
        display: inline-block;
      }
      &:not(.active) {
        display: none;
      }
    }
  }
  ::v-deep.is-active {
    opacity: 1;
    background: transparent;
    .iconfont,
    .menu-name {
      opacity: 1;
    }
    .icon-img {
      &.active {
        display: inline-block;
      }
      &:not(.active) {
        display: none;
      }
    }
  }
  ::v-deep.el-submenu__title {
    height: 50px;
    padding: 0;
    color: $font-b-2;
    font-size: 16px;
    margin-bottom: -3px;
  }
  .triangle-radius {
    position: absolute;
    right: -10px;
    top: 0;
    width: 10px;
    height: 10px;
    z-index: 1;
  }
}
</style>
<style lang="scss">
.app-nav-menu-popper-class {
  margin-top: 0;
  padding: 16px;
  width: 144px;
  .el-menu {
    padding: 8px 12px;
    width: 144px;
    min-width: 144px;
  }
  .el-menu-item {
    height: 34px;
    width: 120px;
    line-height: 14px;
    color: $font-b-1;
    font-size: 14px;
    border-radius: 2px;
  }
  .el-menu-item:hover {
    color: $theme-b-2 !important;
  }
  .is-active {
    color: $theme-b-1 !important;
    background-color: $theme-b-6 !important;
  }
}
.app-nav-second-menu-popover {
  border-radius: 6px;
  border: 1px solid #011035;

  background: #2e344f;
  padding: 8px;
  &.el-popper[x-placement^='right'] {
    margin-left: 0px;
  }
  .el-menu-item {
    height: 28px;
    line-height: 20px;
    color: $white;
    font-size: 12px;
    width: 88px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 12px !important;
    border-radius: 4px;
    &:first-of-type {
      margin-bottom: 4px;
    }
    .iconfont {
      color: $white;
      font-size: 16px;
      margin-right: 4px;
    }
    .icon-img {
      height: 16px;
      width: 16px;
      &.active {
        display: none;
      }
    }
    .menu-name {
      opacity: 0.7;
    }
    // .menu-new {
    //   position: absolute;
    //   width: 23px;
    //   height: 11px;
    //   right: 6px;
    //   top: -6px;
    // }
    .menu-flag {
      position: absolute;
      width: auto;
      height: 11px;
      left: 50%;
      margin-left: -7px;
      top: -6px;
      &.is-new {
        right: 4px;
        top: 50%;
        left: auto;
        transform: translateY(-50%);
      }
    }
  }
  .el-menu-item:hover {
    color: $white;
    border-radius: 4px;
    background: #011035;

    .icon-img {
      &.active {
        display: inline-block;
      }
      &:not(.active) {
        display: none;
      }
    }
  }
  .is-active {
    background: #011035;

    .icon-img {
      &.active {
        display: inline-block;
      }
      &:not(.active) {
        display: none;
      }
    }
  }
}
</style>
