import Dao from '@/adapters/dao';

class ReadDao extends Dao {
  // 获取行业列表
  async getIndustryList(options = {}) {
    // const params = options;
    console.log(options);
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/v2/authorization/industry/read/list',
      params: options
    });
  }

  // 获取会议纪要列表
  async getSummaryList(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/roadshow/summary/list',
      ...options
    });
  }

  // 获取会议纪要详情
  async getSummaryDetail(options = {}) {
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/roadshow/summary/detail',
      ...options
    });
  }

  // 获取路演详情(会议详情)
  async getRoadshowDetail(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/service/square/roadshow',
      method: 'GET',
      ...options
    });
  }

  // 获取路演纪要数量，给日期组件使用
  async getRoadshowCount(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/roadshow/summary/count',
      method: 'POST',
      ...options
    });
  }

  // 获取预约数量，给日期组件使用
  async getOrderCount(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/roadshow/summary/reservation/count',
      method: 'POST',
      ...options
    });
  }

  // 获取市场资讯列表
  async getWeChatList(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/wechat/article/list',
      ...options
    });
  }

  // 大家都在看
  async peopleWatch(options = {}) {
    return this.fetch({
      url: '/external/alpha/api/reading/wechat/article/all/home',
      ...options
    });
  }

  // 市场资讯详情
  async getWeChatDetail(options = {}) {
    return this.fetch({
      url: '/external/alpha/api/reading/wechat/article/detail',
      ...options
    });
  }

  // 获取预约列表
  async getAppointmentList(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/roadshow/summary/reservation/list',
      ...options
    });
  }

  // 获取感兴趣列表
  async getFav(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/favorIndustry',
      ...options
    });
  }

  // 保存兴趣
  async saveFav(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/user/save/favorIndustry',
      ...options
    });
  }

  // 感兴趣列表
  async getFavList(options) {
    const params = options;
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/wechat/article/all/industry',
      params
    });
  }

  // 获取会议速记页面右侧列表 /alpha/api/reading/roadshow/summary/all/industry
  async getMeetingRightList(options) {
    const params = options;
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/roadshow/summary/all/industry',
      params
    });
  }

  // 取消/收藏
  async follow(options = {}) {
    const params = options;
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/favorite/doFavorite',
      params
    });
  }

  async checkReadInfo(options = {}) {
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/restrict',
      ...options
    });
  }

  async getInstitutionEmail(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/institution/buyer/list',
      ...options
    });
  }

  async searchInstitutionEmail(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/v2/authorization/institution/buyer/search',
      ...options
    });
  }

  async getTag(options = {}) {
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/holding/status',
      ...options
    });
  }

  // 获取全量感兴趣行业列表
  async getInterestList(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/v2/authorization/industry/read/list',
      ...options
    });
  }

  // 获取已选感兴趣行业列表
  async getInterestValues(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/v2/authorization/user/favorIndustry',
      method: 'POST',
      ...options
    });
  }

  // 保存感兴趣行业
  async saveFavor(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/v2/authorization/user/save/favorIndustry',
      method: 'POST',
      ...options
    });
  }

  // 获取纪要、市场资讯搜索关键词
  async getHistoryWord(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/search/history',
      ...options
    });
  }

  // 删除纪要、市场资讯搜索关键词
  async clearSearchHistory(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/search/history/clear',
      ...options
    });
  }

  // 删除单一纪要、市场资讯搜索关键词
  async clearSingleSearchHistory(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/search/history/clear/single',
      ...options
    });
  }

  // 获取纪要、市场资讯搜索热门关键词
  async getHotWord(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/hot/word',
      ...options
    });
  }

  /* async smartSearch(options = {}) {
    // 将导致接口报错的特殊字符去除
    options.params.word = options.params.word.replace(/[[\]{}"]/g, '');
    return await this.fetch({
      url: '/external/alpha/api/reading/smart/search',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  } */

  async smartSearch(options = {}) {
    // 将导致接口报错的特殊字符去除
    console.log('optionsoptionsoptions', options);
    options.params.word = options.params.word.replace(/[[\]{}"]/g, '');
    const data = {
      ...options.params,
      word: options.params.word.replace(/[[\]{}"]/g, '')
    };
    return await this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/smartSearch',
      data,
      headers: {
        'x-from': 'web'
      }
    });
  }

  // 搜索会议速记
  async searchSummary(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/roadshow/summary/search',
      ...options
    });
  }

  // 买方-市场资讯文章搜索
  async wechatArticleSearch(options = {}) {
    return await this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/wechat/article/search/article',
      ...options
    });
  }

  // 公众号搜索
  async wechatSearch(options = {}) {
    return await this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/wechat/article/search/account',
      ...options
    });
  }

  // 获取纪要详情分享的小程序码
  async getSummaryShareCode(options = {}, customConfig = {}) {
    return await this.fetch(
      {
        method: 'POST',
        url: '/mid-proxy/api/v1/share/getwxacodeunlimit',
        ...options
      },
      customConfig
    );
  }

  // 获取研究热榜
  async getResearchHotList(options = {}, customConfig = {}) {
    return await this.fetch(
      {
        method: 'GET',
        url: '/external/alpha/api/reading/discovery/list',
        ...options
      },
      customConfig
    );
  }

  // 获取综合搜索分词
  async chatIntent(data, customConfig = {}) {
    // Object.keys(options.data).forEach(key => {
    //   const value = options.data[key];
    //   options.data[key] = encodeURIComponent(value);
    // });
    data.query = data.query.replace(/"/g, '"');
    return await this.fetch(
      {
        method: 'POST',
        url: '/external/alpha/api/mix/chat/service/intent',
        data
      },
      customConfig
    );
  }

  // ai回答时，保存原始问题
  async saveWord(data, customConfig = {}) {
    data.word = data.word.replace(/"/g, '"');
    return await this.fetch(
      {
        method: 'POST',
        url: '/external/alpha/api/reading/smart/saveWord',
        data
      },
      customConfig
    );
  }

  // sug搜索建议词
  async searchSuggest(options = {}, customConfig = {}) {
    // 将导致接口报错的特殊字符去除
    options.params.word = options.params.word.replace(/[[\]{}"]/g, '');
    return await this.fetch(
      {
        method: 'GET',
        url: '/external/alpha/api/reading/stock/searchSuggest',
        ...options
      },
      customConfig
    );
  }

  // get paipai search input placeholder
  async getPaipaiPlaceholder(data = {}, customConfig = {}) {
    return await this.fetch(
      {
        method: 'POST',
        url: '/external/alpha/api/mix/question/list',
        data
      },
      customConfig
    );
  }

  // 获取观点详情
  getViewpointDetail(options = {}) {
    const params = options;
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/viewpoint/detail',
      params
    });
  }
}

export default ReadDao;
