import sensors from 'sa-sdk-javascript';

function SetTracker(trackerName, data = {}) {
  try {
    console.log('埋点>>>>', trackerName, data);
    if (data) {
      sensors.track(trackerName, {
        ...data
      });
    } else {
      sensors.track(trackerName);
    }
  } catch {}
}

function wrapperTracker(trackerName, data) {
  try {
    SetTracker(trackerName, data);
  } catch (error) {}
}
export default wrapperTracker;
