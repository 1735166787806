import { FormatEntity } from '@alpha-pai/base';

const formatters = {
  data(source) {
    const data = source.data || [];
    return data.map(({ accountName, rwxaccCode, url, ...rest }) => {
      return {
        id: rwxaccCode,
        name: accountName,
        url,
        rwxaccCode,
        ...rest
      };
    });
  }
};

class FollowAccountListResponse extends FormatEntity {
  constructor(source = {}, customFormatters) {
    super();
    this.define(Object.assign({}, source));
    this.init(source, Object.assign({}, formatters, customFormatters));
  }
}

export default FollowAccountListResponse;
