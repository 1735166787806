/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-07-26 13:52:38
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-09-24 19:06:18
 */
import { Enum } from '@/common/config';
import { CommonUtil } from '@alpha-pai/core/utils';
import { Constants } from '@/adapters/config';
import loginService from '@/modules/home/http/login.js';

export function AuthInterceptor(store) {
  return function(to, from, next) {
    const requiresAuthNext = function() {
      if (store.getters.isLoginedUser) {
        next();
      } else {
        CommonUtil.userLogout(); // 清空本地登陆信息
        const { Constants } = store.dataflow.services.commonService.getConfig();
        if (Constants.LOGIN_PATH) {
          // eslint-disable-next-line no-return-assign
          return (window.location.href = Constants.LOGIN_PATH);
        }
        // this.$router.currentRoute.fullPath
        const { pathname, search } = window.location;
        if (pathname === '/' && !search) {
          next('/login');
        } else {
          // next(`/login?${pathname}${search}`);
          const path = `${pathname}${search}`;
          next(`/login?url=${encodeURIComponent(path)}`);
        }
      }
    };

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.user || !store.getters.user.id) {
        store.dispatch('setGlobalAppData').then(() => {
          requiresAuthNext();
        });
      } else {
        requiresAuthNext();
      }

      // requiresAuthNext();
    } else {
      next();
    }
  };
}

const redirect = store => {
  return function(to, from, next) {
    if (store?.getters?.user?.type === '1') {
      if (/^\/reading/.test(to.path)) {
        if (
          ![
            '/reading/profile/password-manage',
            '/reading/home/convert-meeting',
            '/reading/home/announcement',
            '/reading/home/announcement-detail',
            '/reading/translate-tool',
            '/reading/translate-preview',
            '/reading/foreign-market',
            '/reading/self-summary-detail'
          ].includes(to.path)
        ) {
          next('/reading/home/announcement');
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  };
};

const logout = (store, next) => {
  store.dispatch('removeGlobalAppData');
  CommonUtil.userLogout(); // 清空本地登陆信息

  if (Constants.VUE_APP_LOGIN_PATH) {
    // eslint-disable-next-line no-return-assign
    return (window.location.href = Constants.VUE_APP_LOGIN_PATH);
  }
  const { pathname, search } = window.location;
  next(`/login?${pathname}${search}`);
};

/***
 * @description: 处理试用到期后，用户卡bug 刷新的问题
 * @param {*} store
 * @return {*}
 ***/
export const handleExpiredUser = store => {
  return function(to, from, next) {
    const user = store?.getters?.user;
    // app中间态用户，只注册，未开始试用，这种用户要开启试用
    const appIntermediateStateUser =
      user?.informationFlag === 0 &&
      user?.remainAmount === 0 &&
      user?.totalAmount === 0;
    // 未认证 并且试用已结束或未开启使用 并且身份认证成功或失败展示弹窗
    const illegalUserTag =
      user?.informationFlag !== 1 && user?.remainAmount === 0;
    if (to.name === 'login-page') {
      next();
    } else {
      if (appIntermediateStateUser) {
        loginService
          .openTrial({
            data: {
              mobile: user?.mobile
            }
          })
          .then(res => {
            store.dispatch('setGlobalAppData').then(() => {
              next();
            });
          })
          .catch(err => {
            console.error(err);
            logout(store, next);
          });
      } else if (illegalUserTag) {
        logout(store, next);
      } else {
        next();
      }
    }
  };
};
const updateUserInfo = store => {
  return function(to, from, next) {
    // 手机未认证账户，每次刷新路由器后都更新数据以确保认证侧边栏的状态
    if (to.name !== 'login-page') {
      try {
        const user = store?.getters?.user;
        if (user.passwordFlag && !user.mobileValidateFlag) {
          store.dispatch('setGlobalAppData');
        }
      } catch (e) {}
    }
    next();
  };
};
const hooks = [redirect, handleExpiredUser, updateUserInfo];

switch (process.env.VUE_APP_CURRENT_CONTEXT) {
  case Enum.APP_CONTEXT.RABYTE:
  default:
    break;
}

export default hooks;
