/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-25 09:58:30
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-11-22 10:32:43
 */
// 定义一个函数，用正则表达式匹配中文标点符号
export function splitByPunctuation(text, regex) {
  // text = text.replaceAll('\n', '');
  const sentences = [];
  // 定义一个正则表达式，匹配中文的可以代表句子结束的标点：句号、问号、感叹号、分号、冒号、换行符
  if (!regex) {
    regex = /[\u3002\uFF1F/?\uFF01\uFF1B\n/.]/g;
  }
  const parts = text.split(regex);
  // 定义一个变量，用来记录当前的位置
  let index = 0;
  // 遍历数组，把每个元素去掉首尾的空白字符，并添加到句子数组中
  for (let part of parts) {
    part = part.trim();
    if (part) {
      // 在原文本中找到当前元素的位置
      index = text.indexOf(part, index);
      // 在原文本中找到当前元素后面的标点符号
      const punctuation = text.charAt(index + part.length);
      // 如果有标点符号，就把它和当前元素拼接起来，并添加到句子数组中
      if (punctuation) {
        sentences.push(part + punctuation);
      } else {
        // 如果没有标点符号，就只添加当前元素到句子数组中
        sentences.push(part);
      }
      // 更新位置
      index += part.length + punctuation.length;
    }
  }
  return sentences;
}

export function formatQuestion(question) {
  return question.replace('Q:', 'Q：');
}

export function replaceHighSentences(
  inputString,
  highlightSentences,
  type = 'sentence'
) {
  highlightSentences.forEach(sentence => {
    const replacedString = inputString.replace(sentence, function(match) {
      return `<span class="highlight-${type}">${match}</span>`;
    });
    inputString = replacedString;
  });
  return inputString;
}

export function highlightenStr(str, keyword) {
  const index = str.indexOf(keyword);
  if (index > -1) {
    const preStr = str.slice(0, index);
    const postStr = str.slice(index + keyword.length);
    const highligtStr = `<span style="color: #ef404a;">${keyword}</span>`;
    return `${preStr}${highligtStr}${postStr}`;
  } else {
    return str;
  }
}

/*
 * 数字千分分割，保留小数位数根据参数定
 * 参数说明：
 * number：要格式化的数字
 * decimals：保留几位小数
 * */
export function handleFormatAmount(number, decimals = 2) {
  if (number || number === 0) {
    if (String(number).indexOf('%') > -1) {
      return number;
    }
    if (number === 0) {
      return number.toFixed(decimals);
    }
    try {
      number = Number(number).toFixed(decimals) + '';
      return number.replace(/(-?\d{1,3})(?=(\d{3})+(\.\d+)?$)/g, '$1,');
      // return number.replace(/(-?\d)(?=(\d{3})+\.)/g, '$1,');
    } catch (e) {
      return '';
    }
  } else {
    return '';
  }
}

/**
 * @description 在一个字符串中插入换行符，使得之前单独出现的换行符成对出现\
 *              例如：将这个'ab\n\n\ncd\nefg'字符串变成'ab\n\n\ncd\n\nefg'
 * @param {String} str
 * @return {String}
 * */
export function insertNewLineCharacter(str) {
  if (!str) {
    return '';
  }
  const charArr = [];
  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);
    if (char === '\n') {
      if (i === 0 || i === str.length - 1) {
        charArr.push(char);
      } else if (str.charAt(i - 1) !== '\n' && str.charAt(i + 1) !== '\n') {
        charArr.push(char);
        charArr.push('\n');
      } else {
        charArr.push(char);
      }
    } else {
      charArr.push(char);
    }
  }
  return charArr.join('');
}
