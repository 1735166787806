<template>
  <div v-if="isVisible">
    <div class="cp-user-modal-wrap" v-show="!isIntroVisible">
      <!-- 试用倒计时 -->
      <TrialCountdownModal
        v-if="isTrialCountdownVisible"
        @close="handleClose"
        @intro="handleShowIntro"
      />
      <!-- 存量已认证个人用户：赠30天 -->
      <CertifiedUserModal
        v-if="isCertifiedUserVisible"
        @close="handleClose"
        @intro="handleShowIntro"
      />
      <!-- 买方认证成功 -->
      <VerifySuccessedModal
        v-if="isVerifySuccesseVisible"
        @close="handleClose"
        @intro="handleShowIntro"
      />
      <FreeVersionTipsModal
        v-if="isFressVisible"
        @close="handleClose"
        @intro="handleShowIntro"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { InstitutionRoleEnums } from '@/common/config/enum';
import enums from '@/modules/reading/config/enums';
// import { getRouteName } from '@/modules/reading/utils';

import TrialCountdownModal from './trial-countdown-modal';
import CertifiedUserModal from './certified-user-modal';
import VerifySuccessedModal from './verify-successed-modal';
import FreeVersionTipsModal from './free-version-tips-modal';
const { BusinessCardVerificationEnums } = enums;

export default {
  components: {
    TrialCountdownModal,
    CertifiedUserModal,
    VerifySuccessedModal,
    FreeVersionTipsModal
  },
  data() {
    return {
      authenticationModalEnterBy: ''
    };
  },
  computed: {
    ...mapGetters(['user']),
    isFressVisible() {
      return this.user?.freeProductFlag === 1;
    },
    isIntroVisible() {
      return this.$store.state.readingStore.isIntroModalVisible;
    },
    isVisible() {
      return (
        this.isTrialCountdownVisible ||
        this.isCertifiedUserVisible ||
        this.isVerifySuccesseVisible ||
        this.isFressVisible
      );
    },
    // 试用倒计时
    isTrialCountdownVisible() {
      const { user, $store } = this;
      return (
        $store.state.readingStore.onceLogin &&
        user &&
        user.trialFlag &&
        user.cardAuditStatus !== BusinessCardVerificationEnums.verifying &&
        !$store.state.readingStore.onceRegister
      );
    },
    // 买方存量已认证
    isCertifiedUserVisible() {
      const { user } = this;
      return (
        user &&
        user.type === InstitutionRoleEnums.purchasers &&
        user.cooperateFlag !== 1 &&
        user.upgradeFlag === 1
      );
    },
    // 买方认证成功
    isVerifySuccesseVisible() {
      const { user } = this;
      return (
        user &&
        user.type === InstitutionRoleEnums.purchasers &&
        user.cardAuthFlag
      );
    }
  },
  beforeDestroy() {
    this.handleClose();
  },
  methods: {
    handleClose() {
      this.$store.commit('readingStore/setOnceLogin', false);
    },
    handleIntroClose() {
      this.$store.commit('readingStore/setIsIntroModalVisible', '');
    },
    handleShowIntro(v) {
      this.$store.commit('readingStore/setIsIntroModalVisible', v);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';

.cp-user-modal-wrap {
  z-index: 1998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
