import sensors from 'sa-sdk-javascript';

// 如果需要给web端埋点，sourceFrom参数需要传'web'
function SetTracker(trackerName = '', { data = {}, sourceFrom = '' } = {}) {
  if (!sourceFrom) {
    if (window?.ReactNativeWebView) {
      sourceFrom = 'app';
    } else {
      sourceFrom = 'miniProgram';
    }
  }

  try {
    if (data) {
      sensors.track(trackerName, {
        ...data,
        sourceFrom
      });
    } else {
      sensors.track(trackerName, {
        sourceFrom
      });
    }
  } catch {}
}

export default SetTracker;
