<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-08-07 10:26:08
 * @LastEditors   : luyue01@rabyte.cn
 * @LastEditTime  : 2023-1-13 9:48:29
-->
<template>
  <el-popover
    popper-class="app-download-popover"
    placement="bottom-start"
    trigger="hover"
    :visible-arrow="false"
  >
    <div slot="reference" class="app-download-text">
      <i class="iconfont icon-xiazaiAPP1"></i>下载APP
    </div>
    <div class="app-download-container">
      <img
        class="download-img"
        src="@/static/assets/images/qr-test.png"
        alt=""
      />
      <p>
        <b>扫描二维码</b>
        <br />
        下载Alpha派
      </p>
    </div>
  </el-popover>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.app-download-text {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.06em;
  color: $white;
  cursor: pointer;
  padding: 0 12px;
  &:hover {
    background: $theme-bg-2;
  }
  .iconfont {
    font-size: 16px;
    margin-right: 4px;
  }
}
.app-download-container {
  img {
    margin-top: 12px;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    margin-top: 8px;
    font-size: inherit;
    color: $theme-b-1;
  }
}
</style>

<style lang="scss">
.app-download-popover {
  border: 0;
  top: 38px !important;
  padding: 0 !important;
  width: 124px;
  height: 168px;
  border-radius: 4px;
  background: linear-gradient(180deg, $theme-b-5 0%, #fff 46.05%),
    linear-gradient(180deg, $theme-b-5 0%, #fff 19.79%), #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
</style>
