/*
 * @Copyright © 2024 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2024-10-17 11:17:53
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-10-24 14:11:28
 */
/**
 * @author [author]
 * @email [example@mail.com]
 * @create date 2023-11-20 10:29:50
 * @modify date 2023-11-20 10:29:50
 * @desc [公共数据相关接口]
 */

import { http } from '@/adapters/dao';

export const getSWIndustryList = params =>
  http.fetch({
    method: 'GET',
    url: '/external/alpha/api/v2/authorization/industry/list/sw1',
    params
  });

/***
 * @description: 关注
 * @param {*} options
 * @return {*}
 ***/
export const follow = options => {
  return http.fetch({
    method: 'GET',
    url: '/external/alpha/api/reading/favorite/doFavorite',
    ...options
  });
};

export const saveActionRobotTip = (options = {}) => {
  return http.fetch({
    method: 'POST',
    url: '/external/alpha/api/agent/meeting/private-meeting/popup/record',
    ...options
  });
};

/***
 * @description: 私有会议弹窗是否可展示
 * @param {*} options
 * @return {*}
 ***/
export const checkRobotTip = (options = {}) => {
  return http.fetch({
    method: 'GET',
    url: '/external/alpha/api/agent/meeting/private-meeting/popup/show',
    ...options
  });
};
