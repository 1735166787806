import Dao from '@/adapters/dao';

class TranslateToolDao extends Dao {
  // 获取后端当前状态
  async getCurrentStatus(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/current/status',
      method: 'GET',
      ...options
    });
  }

  // 文件上传
  async postUploadFile(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/file/upload',
      method: 'POST',
      ...options
    });
  }

  // 添加翻译任务
  async postTaskAdd(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/task/add',
      method: 'POST',
      ...options
    });
  }

  // 取消翻译任务
  async getTaskCancel(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/task/cancel',
      method: 'GET',
      ...options
    });
  }

  // 翻译记录列表
  async postTaskList(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/task/list',
      method: 'POST',
      ...options
    });
  }

  // 查询翻译进度
  async getTaskProgress(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/task/progress',
      method: 'GET',
      ...options
    });
  }

  // 文件翻译成功或失败, 点击新建翻译时调用
  async getTaskResultConfirm(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/task/result/confirm',
      method: 'GET',
      ...options
    });
  }

  // 发送邮件
  async getTaskSendMail(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/task/sendMail',
      method: 'GET',
      ...options
    });
  }

  // 查询额度
  async getTodayPage(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/today/page',
      method: 'GET',
      ...options
    });
  }

  // 查询语言列表
  async getLanguageList(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/languages',
      method: 'GET',
      ...options
    });
  }

  // 获取任务
  async getTaskGet(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/task/get',
      method: 'GET',
      ...options
    });
  }

  // 删除任务
  async getTaskDelete(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/mix/translate/task/delete',
      method: 'GET',
      ...options
    });
  }

  // 新增外资研报翻译任务
  async addForeignReportTranslateTask(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/stock/us/report/translate/add',
      method: 'POST',
      ...options
    });
  }
}
export default TranslateToolDao;
