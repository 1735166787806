<template>
  <div
    class="part-container"
    v-show="stockList.length > 0 || managerList.length > 0"
  >
    <div class="result-container">
      <div
        class="item"
        v-for="(item, index) in stockList"
        :key="item.securityTickerSymbol"
        @click="onClickStock(item, index)"
      >
        <div class="name-wrap">
          <span
            class="name"
            v-html="
              highlightenStr(item.securityName, keyword.trim().toUpperCase())
            "
          ></span>
          <span
            class="name right"
            v-html="
              highlightenStr(
                item.securityTickerSymbol,
                keyword.trim().toUpperCase()
              )
            "
          ></span>
        </div>
      </div>
      <div
        class="item"
        v-for="(item, index) in managerList"
        :key="item.psnCode"
        @click="onClickManager(item, index)"
      >
        <div class="name-wrap">
          <span
            class="name"
            v-html="highlightenStr(item.psnName, keyword.trim().toUpperCase())"
          ></span>
          <span
            class="name right"
            v-html="highlightenStr(item.instName, keyword.trim().toUpperCase())"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { highlightenStr } from '@/modules/reading/utils';
import { debounce } from 'lodash';
import SetTracker from '@/common/utils/setTracker';

export default {
  props: {
    keyword: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      stockList: [],
      managerList: []
    };
  },
  methods: {
    highlightenStr,
    getSuggestion: debounce(function() {
      this.$dao.readDao
        .searchSuggest({
          params: { word: this.keyword, pageNum: 1, pageSize: 8 }
        })
        .then(({ data }) => {
          this.stockList = data.stock || [];
          this.managerList = data.fundManager || [];
        })
        .catch(() => {
          this.stockList = [];
          this.managerList = [];
        });
    }, 200),
    onClickStock(item, index) {
      SetTracker('Web_Search_Click', {
        search_words: item.securityName,
        search_types: '点击搜索sug',
        order_index: index + 1
      });
      this.$emit('search', {
        type: 'stock',
        code: item.securityTickerSymbol,
        keyword: item.securityName
      });
    },
    onClickManager(item, index) {
      SetTracker('Web_Search_Click', {
        search_words: item.psnName,
        search_types: '点击搜索sug',
        order_index: index + 1
      });
      this.$emit('search', {
        type: 'person',
        code: item.psnCode,
        keyword: item.psnName
      });
    }
  },
  watch: {
    keyword: {
      handler(val) {
        if (val) {
          this.getSuggestion();
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.part-container {
  .result-container {
    .item {
      padding: 5px 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background: $color-bg-1;
        .delete-icon {
          display: inline-block;
        }
      }
      .name-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .name {
          font-size: 14px;
          line-height: 22px;
          color: $font-b-1;
          min-width: 56px;
          display: inline-block;
          text-align: left;
        }
        .right {
          margin-left: 16px;
        }
      }
      .delete-icon {
        font-size: 13px;
        color: $font-b-2;
        cursor: pointer;
        display: none;
        &:hover {
          color: $theme-b-2;
        }
      }
    }
  }
}
</style>
