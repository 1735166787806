/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:55
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-12-05 15:45:15
 */
import Vuex from 'vuex';
import { store as GlobalStore } from '@alpha-pai/core';
import { AppModules } from './modules';
import { dataflowVuexPlugin } from './dataflow';
import sensors from 'sa-sdk-javascript';
import profileFormat from '@/common/utils/profileFormat';
import * as Sentry from '@sentry/vue';
const { mutationTypes: types } = GlobalStore;

const stores = [...AppModules].reduce((pre, cur) => {
  return {
    ...pre,
    ...cur.stores
  };
}, {});

/***
 * @description: 处理全局数据，主要是埋点问题处理，原有的无埋点的登录，导致丢点
 * @param {*} commit
 * @param {*} data
 * @return {*}
 ***/
async function setGlobalAppData({ commit }, data) {
  const { dataflow } = this;
  const { commonDao } = this.dataflow.dao;
  try {
    // 获取用户数据
    const appUserInfo = data || (await commonDao.getAppUserInfo());
    const { global = {} } = appUserInfo || {};

    dataflow.services.userService.setUser(global.user);
    dataflow.services.authService.setAuthList(global.auth);

    commit(types.SET_GLOBAL_APP_DATA, global);
    sensors.login(global?.user?.uid);
    if (global?.user?.uid) {
      sensors.setProfile(profileFormat(global.user));
      if (process.env.NODE_ENV !== 'local') {
        Sentry.setUser({
          id: global?.user?.uid,
          username: global?.user?.name,
          email: global?.user?.email,
          phone: global?.user?.mobile
        });
      }
    }

    return true;
  } catch (e) {
    commit(types.SET_GLOBAL_APP_DATA, {});
  }
}

GlobalStore.actions.setGlobalAppData = setGlobalAppData;

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  ...GlobalStore,

  modules: {
    ...stores
  },

  plugins: [dataflowVuexPlugin]
});
