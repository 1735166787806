/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-06-28 14:46:53
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-04-12 16:39:28
 */
import { http } from '@/adapters/dao';

const service = {
  getCommentSearch(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/comment/search',
      ...options
    });
  },

  getCommentDetail(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/comment/detail',
      ...options
    });
  },

  // 批量关注股票
  concernStock(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/follow/multi',
      ...options
    });
  },

  // 取消关注股票
  cancelConcernStock(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/unfollow',
      ...options
    });
  },

  // 获取关注状态
  getFocusStatus(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/follow/query',
      ...options
    });
  },

  // 获取关注股票数量
  getFocusCount(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/information/flow/stock/follow/num',
      ...options
    });
  },

  // 一键关注持仓
  concernPosition(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/information/flow/stock/follow/holding',
      ...options
    });
  },

  // 股票搜索
  searchStock(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/information/flow/stock/search',
      ...options
    });
  },

  // 获取关注股票列表
  getFocusList(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/follow/list',
      ...options
    });
  },

  // 获取关注推荐
  getFocusRecommend(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/information/flow/stock/recommend',
      ...options
    });
  },

  // 获取隐蔽代码汉字
  getChideCode(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/encrypt/hz_html',
      ...options
    });
  },

  // 获取关注个股信息流
  getFocusStockInfoList(options) {
    return http.fetch({
      method: 'POST',
      url:
        '/external/alpha/api/reading/information/flow/stock/information/list',
      ...options
    });
  },

  // 获取申万一级行业，共31个
  getSWIndustryList(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/v2/authorization/industry/list/sw1',
      ...options
    });
  },

  // 点评详情页，获取右侧列表及种类
  getCommentDetailAside(options) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/comment/list/recommend',
      ...options
    });
  },
  // 点评推荐数量
  getCommentCount(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/comment/recommend/list/count',
      ...options
    });
  },
  // 文件上传/导入
  uploadFile(options) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/follow/file',
      ...options
    });
  },

  getInviteRecord(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/v2/authorization/user/invite/record',
      ...options
    });
  },

  // 获取今日新增数据
  getAddReadData(options = {}) {
    return http.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/count/today',
      ...options
    });
  },

  /***
   * @description: 删除关注
   * @param {*} options
   * @return {*}
   ***/
  deleteFollow(options = {}) {
    return http.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/deleteFollow',
      ...options
    });
  }
};

export default service;
