/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:55
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-04-01 15:07:34
 */
import { FormatEntity } from '@alpha-pai/base';
import {
  showSimpleDateText,
  showPvText
} from '@/modules/reading/utils/index.js';

const formatters = {
  list(source) {
    return (source.list || []).map(item => {
      let ele = {
        ...item,
        name: item.guest || item.analyst || '', // 主讲人名：有嘉宾用嘉宾，没有嘉宾用分析师
        icon: item.url || '', // 市场资讯头像
        time: item.date, // 发布时间
        institution: item.publishInstitution || item.institution || '', // 发布机构
        industrys: (item.industry || []).map(item => item.name), // 行业
        stocks: (item.stock || []).map(item => item.name), // 股票
        updateTime: showSimpleDateText(item.updateTime), // 阅读时间
        originUpdateTime: item.updateTime,
        pv: showPvText(item.pv),
        rank: item.accountRank,
        featureHas: item.feature,
        type: item.type,
        feature: item.feature
          ? item.feature
              .filter(x => x !== '机构观点')
              .filter(x => x !== '产业资讯')
          : []
      };
      if (ele.type === 60) {
        ele.type = item.subType;
      }
      if (item.fullJson) {
        if (ele.type === 321) {
          ele = {
            ...ele,
            ...JSON.parse(ele.fullJson)
          };
        } else if (ele.type === 35) {
          const d = JSON.parse(ele.fullJson);
          ele = {
            ...ele,
            ...JSON.parse(ele.fullJson),
            commentType:
              JSON.parse(ele.fullJson).type === 'txt' ? 'text' : 'img',
            teamName: JSON.parse(ele.fullJson)?.teamName?.[0]?.name || '',
            analyst: d?.analyst?.[0]
              ? {
                  icon: d?.analyst?.[0]?.logo,
                  name: d?.analyst?.[0]?.name
                }
              : {
                  icon: '',
                  name: ''
                },
            type: 35
          };
        } else {
          const {
            aiFlag,
            pptFlag,
            radio,
            recorder,
            hasCoreData,
            roadshowDate,
            institutionalReservation,
            marketTypeV2,
            containsPpt
          } = JSON.parse(item.fullJson);
          ele = {
            ...ele,
            aiFlag,
            pptFlag,
            radio,
            recorder,
            hasCoreData,
            roadshowDate,
            institutionalReservation,
            marketTypeV2,
            containsPpt
          };
        }
      }
      return ele;
    });
  },
  total(source) {
    return source.total;
  }
};

class HistoryListResponse extends FormatEntity {
  constructor(source = {}, customFormatters) {
    super();
    this.define(Object.assign({}, source));
    this.init(source, Object.assign({}, formatters, customFormatters));
  }
}

export default HistoryListResponse;
