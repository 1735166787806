const RELOAD_KEY = 'RABYTE_SAAS_LOAD_CHUNK_FAIL_RELOADED';

export const loadErrorHandler = function(router) {
  sessionStorage.removeItem(RELOAD_KEY);

  router.onError(function() {
    const { currentRoute } = router;
    // 只有在登录页和web端才会触发这个逻辑
    if (
      currentRoute.path.startsWith('/reading/') ||
      currentRoute.name === 'login-page'
    ) {
      if (sessionStorage.getItem(RELOAD_KEY)) {
        return;
      }
      sessionStorage.setItem(RELOAD_KEY, '1');
      location.reload(true);
    }
  });
};
