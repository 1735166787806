/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:52:17
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-11 16:55:45
 */
export default {};

// 审核失败原因枚举
export const BUSINESS_CARD_VERIFICATION_FAILED_REASON_ENUMS = {
  0: '',
  1: '名片不清晰，请重新上传',
  2: '非投研用户，暂未开放使用',
  3: '非目标用户，暂未开放使用',
  4: '名片未包含关键信息，如姓名、公司、部门、职位、邮箱、手机号码'
};
