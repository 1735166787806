import { http } from '@/adapters/dao';
import { CommonUtil } from '@alpha-pai/core/utils';

// 账密登录
export const accountLogin = data =>
  http
    .fetch({
      url: '/external/alpha/api/v2/authentication/accountLogin',
      method: 'POST',
      data
    })
    .then(res => {
      const token = res?.data?.token;
      if (token) {
        CommonUtil.userLogin(token);
      }
      return res;
    });
// 账密注册
export const accountRegister = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/accountRegister',
    method: 'POST',
    data
  });

// 手机核验-发送手机验证码
export const sendMobileAuthorizationCode = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/sendMobileCode',
    method: 'POST',
    data
  });
// 手机验证码核验
export const checkMobileAuthorizationCode = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/checkSmsCode',
    method: 'POST',
    data
  });
// 手机核验-校验手机验证码
export const validMobileAuthorizationCode = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/mobileValidate',
    method: 'POST',
    data
  });
// 发送邮箱验证码
export const sendEmailAuthorizationCode = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/sendEmailCode',
    method: 'POST',
    data
  });
// 邮箱验证码核验
export const checkEmailAuthorizationCode = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/checkEmailCode',
    method: 'POST',
    data
  });
// 重置用户密码
export const resetUserPassword = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/resetPassword',
    method: 'POST',
    data
  });
// 设置用户密码
export const setUserPassword = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/setPassword',
    method: 'POST',
    data
  });
// 修改用户密码
export const modifyUserPassword = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/modifyPassword',
    method: 'POST',
    data
  });
// 通过手机号码获取账号状态
export const checkAccount = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/checkAccount',
    method: 'POST',
    data
  });

// 当前登录人基本信息
export const getUserInfo = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/info',
    method: 'GET',
    data
  });

// 手机号注册
// export const mobileRegister = data =>
//   http.fetch({
//     url: '/external/alpha/api/v2/authorization/user/mobileRegister',
//     method: 'POST',
//     data
//   });
// 自动登录无需验证码
export const autoLogin = data =>
  http
    .fetch({
      url: '/external/alpha/api/v2/authentication/auto/login',
      method: 'POST',
      data
    })
    .then(({ data = {} }) => {
      CommonUtil.userLogin(data.token);
      return data;
    });

// 开始试用
export const beginTrial = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/beginTrial',
    method: 'POST',
    data
  });

// 继续试用
export const continueTrial = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/continueTrial',
    method: 'POST',
    data
  });
// 获取专属客服经理
export const getCustomerManager = (data = {}) =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/customerManager/detail',
    method: 'POST',
    ...data
  });
// 名片认证成功删除标记
export const cardAuthSuccess = () =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/card/authSuccess',
    method: 'GET',
    data: {}
  });

// 开启个人版
export const beginPersonal = () =>
  http.fetch({
    method: 'POST',
    url: '/external/alpha/api/v2/authorization/user/beginPersonal',
    data: {}
  });

// 清除免费版标记
export const removeFreeProductFlag = () =>
  http.fetch({
    method: '',
    url: '/external/alpha/api/v2/authorization/user/freeProductFlag/remove',
    params: {}
  });

// 免费版访问详情次数查询
export const getReadingDetailCount = (params = {}) =>
  http.fetch({
    method: 'GET',
    url: '/external/alpha/api/reading/detail/count/query',
    params
  });
// 免费版 阅读详情解锁
export const confirmReadingDetail = (params = {}) =>
  http.fetch({
    method: 'GET',
    url: '/external/alpha/api/reading/detail/count/confirm',
    params
  });
// 获取邀请记录
export const getInviteRecord = () =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/invite/record',
    method: 'GET',
    params: {}
  });

// 移除用户flag字段
export const removeUserFlag = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/removeUserFlag',
    method: 'POST',
    data
  });

// 完成引导
export const finishoviceGuide = data =>
  http.fetch({
    url: '/external/alpha/api/v2/authorization/user/noviceGuide/done',
    method: 'POST',
    data
  });
