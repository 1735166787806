<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-08-07 10:26:08
 * @LastEditors   : luyue@rabyte.cn
 * @LastEditTime  : 2023-11-10 14:58:31
-->
<template>
  <div class="header">
    <div class="header-inner">
      <app-logo />
      <app-search v-if="finalSearchInpVisible" />
      <app-menu />
    </div>
  </div>
</template>

<script>
import AppLogo from './AppLogo';
import AppMenu from './app-menu/index';
import AppSearch from './app-menu/search';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppLogo,
    AppMenu,
    AppSearch
  },
  data() {
    return {
      searchInpVisible: true
    };
  },
  computed: {
    ...mapGetters(['user']),
    isVendors() {
      return this.user?.type === '1';
    },
    // 注：卖方用户无机构观点、产业资讯、会议速记
    finalSearchInpVisible() {
      return !this.isVendors && this.searchInpVisible;
    }
  },
  watch: {
    '$route.name': {
      handler(val) {
        // 注：搜索主页不需要显示顶部搜索框
        this.searchInpVisible = val !== 'search';
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 50px;
  background: $theme-bg-1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  .header-inner {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
