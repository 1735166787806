<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-06-19 10:25:02
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-06-05 16:38:33
-->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible="true"
    :append-to-body="false"
    custom-class="authenticate-identity-dialog"
    width="388px"
    title="认证身份"
    @close="onCancel"
  >
    <div class="content">
      <div class="error-box" v-if="errorMessage">
        认证失败: {{ errorMessage }}
      </div>
      <div class="error-box pending" v-if="pendingStatus">
        身份认证审核中，请耐心等待
      </div>
      <div class="scroll-box">
        <el-form
          :model="formData"
          :rules="rules"
          ref="form"
          class="form-wrap"
          :disabled="pendingStatus"
          label-width="50px"
          label-position="left"
        >
          <el-form-item prop="name" label="姓名">
            <el-input
              v-model="formData.name"
              placeholder="请输入真实姓名"
              class="common-input-item"
            ></el-input>
          </el-form-item>
          <el-form-item prop="roleId" label="角色">
            <el-select
              v-model="formData.roleId"
              placeholder="请选择角色"
              class="common-input-item"
              @change="onRoleChange"
            >
              <el-option
                :label="role.label"
                :value="role.value"
                v-for="(role, ind) in roleList"
                :key="ind"
              ></el-option>
            </el-select>
          </el-form-item>
          <template v-if="showOrgNameItem">
            <el-form-item prop="industryIdList" label="行业">
              <el-select
                v-model="formData.industryIdList"
                multiple
                :collapse-tags="true"
                placeholder="请选择行业"
                class="common-input-item"
              >
                <el-option
                  :label="industry.label"
                  :value="industry.value"
                  v-for="(industry, ind) in industryList"
                  :key="ind"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="orgName" label="团队">
              <el-input
                v-model="formData.orgName"
                :maxlength="20"
                placeholder="请输入团队，例如海通金属"
                class="common-input-item"
              ></el-input>
            </el-form-item>
          </template>

          <el-form-item prop="institutionCode" label="机构">
            <el-select
              v-model="formData.institutionCode"
              filterable
              :filter-method="filterOrg"
              @change="onInstitutionChange"
              popper-class="authenticate-identity-institution-select-popper"
              placeholder="请选择所在机构"
              :disabled="!!errorMessage"
              class="common-input-item institution-select-item"
            >
              <el-option
                :label="org.institutionChineseName"
                :value="org.institutionCode"
                v-for="(org, ind) in currentOrgList"
                :key="ind"
                v-show="org.show"
                ><span v-html="org.label || org.institutionChineseName"></span
              ></el-option>
              <el-option :value="'OTHER'" label="--" class="spec-option">
                <div class="select-institution-box">
                  <span class="tip-text">未找到所在机构？</span>
                  <span class="btn-text">立即上传名片，认证身份</span>
                </div>
              </el-option>
              <i class="iconfont icon-search" slot="prefix"></i>
            </el-select>
          </el-form-item>

          <template
            v-if="
              formData.institutionCode && formData.institutionCode !== 'OTHER'
            "
          >
            <el-form-item prop="email" label="邮箱" class="email-form-item">
              <el-input
                v-model="emailPrefix"
                :maxlength="80"
                placeholder="请输入邮箱前缀"
                class="email-input-item"
                @change="onEmailChange"
              ></el-input>
              <el-select
                v-model="emailSuffix"
                class="email-input-item-suffix"
                @change="onEmailChange"
              >
                <el-option
                  :label="suffix.label"
                  :value="suffix.value"
                  v-for="(suffix, ind) in emailSuffixList"
                  :key="ind"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="verificationCode"
              label="验证码"
              class="email-form-item"
            >
              <el-input
                v-model="formData.verificationCode"
                placeholder="请输入验证码"
                class="code-input-item"
              ></el-input>
              <div class="code-btn" @click="sendCode" v-if="!codeTime">
                发送验证码
              </div>
              <div class="time-run" v-else>重发({{ codeTime }}s)</div>
            </el-form-item>
            <div class="code-tip">
              <span>若未收到验证码，请检查垃圾邮件或</span>
              <span @click="onConnectDAM">联系客户经理</span>
            </div>
          </template>

          <!-- 机构选择其他时，需要上传名片 -->
          <template v-if="formData.institutionCode === 'OTHER'">
            <el-form-item prop="cardImageUrls" label="名片">
              <CardUpload
                :cardImageUrls="formData.cardImageUrls"
                @change="onUploadChange"
                :pendingStatus="pendingStatus"
              ></CardUpload>
            </el-form-item>
            <div class="code-tip" v-if="!pendingStatus">
              <span>名片应包含：姓名、公司、部门、职位、邮箱</span>
              <span></span>
            </div>
          </template>
        </el-form>
      </div>
    </div>
    <template v-slot:footer>
      <div class="dialog-footer" v-show="!pendingStatus">
        <el-button @click="onCancel" class="cancel">取消</el-button>
        <el-button
          type="primary"
          @click="onSave"
          class="save"
          :disabled="confirmDisabled"
          >确认</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  onUnmounted,
  getCurrentInstance,
  ref,
  computed,
  onMounted
} from '@vue/composition-api';
import { USER_TYPE_MAP } from './config';
import service from '@/modules/home/http/login.js';
import dam from '@/common/components/dedicated-account-manager';
import CardUpload from './components/CardUpload.vue';
import _ from 'lodash';
import SetTracker from '@/common/utils/setTracker';

import {
  validateName,
  validateOrgName,
  validateEmail,
  validateRole,
  validateInstitutionCode,
  validateIndustryIdList,
  validateCode,
  validateCardImageUrls,
  getRoleList,
  highlightStr,
  roleIdMap
} from './utils';
import { Message } from 'element-ui';

export default defineComponent({
  name: 'AuthenticateIdentity',
  components: {
    CardUpload
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        userType: 1, // 1-卖方 2-买方
        outerStore: {}
      })
    },
    resolve: {
      type: Function,
      default: () => {
        // do nothing
      }
    },
    callback: {
      type: Function,
      default: () => {
        // do nothing
      }
    }
  },
  setup(props) {
    const vm = getCurrentInstance();

    const form = ref(null);
    const userType = ref(props.options.userType);
    const roleList = ref([]); // 角色列表
    const orgList = ref([]); // 机构列表
    const industryList = ref([]); // 行业列表

    const currentOrgList = ref([]); // 当前机构列表

    const emailPrefix = ref(''); // 邮箱前缀
    const emailSuffix = ref(''); // 邮箱后缀
    const emailSuffixList = ref([]); // 邮箱后缀列表
    const codeTime = ref(0); // 倒计时
    const sendingCode = ref(false); // 是否正在发送验证码

    const errorMessage = ref(''); // 错误信息

    const errorOriginData = ref({}); // 错误的原始信息，用来比对的。

    const pendingStatus = ref(false); // 是否是审核中状态

    // 是否是卖方
    const isVendor = computed(
      () => `${userType.value}` === `${USER_TYPE_MAP.VENDOR}`
    );

    // 是否展示团队 和 行业
    const showOrgNameItem = computed(() => {
      return (
        (formData.value.roleId === 3 || formData.value.roleId === 4) &&
        isVendor.value
      );
    });

    // 是否展示 名片相关
    const showDisplayBusinessCardRelated = computed(() => {
      return formData.value.institutionCode === 'OTHER';
    });

    const confirmDisabled = computed(() => {
      return false;
    });

    const formData = ref({
      name: '', // 名称
      roleId: '', // 角色ID
      verificationCode: '', // 验证码
      orgName: '', // 团队
      institutionCode: '', // 机构编码
      industryIdList: [], // 行业
      email: '', // 邮箱
      cardImageUrls: [] // 名片地址
    });

    const rules = ref({
      name: [{ validator: validateName, trigger: 'change' }],
      roleId: [{ validator: validateRole, trigger: 'change' }],
      orgName: [
        {
          validator: validateOrgName(() => formData),
          trigger: 'blur'
        }
      ],
      email: [{ validator: validateEmail, trigger: 'blur' }],
      institutionCode: [
        {
          validator: validateInstitutionCode,
          trigger: 'change'
        }
      ],
      industryIdList: [
        {
          validator: validateIndustryIdList(() => formData),
          trigger: 'change'
        }
      ],
      verificationCode: [
        {
          validator: validateCode(() => formData),
          trigger: 'blur'
        }
      ],
      cardImageUrls: [
        {
          validator: validateCardImageUrls(() => formData),
          trigger: 'change'
        }
      ]
    });

    /**
     * @description:
     * @param {*} type 1-卖方 2-买方
     * @return {*}
     */
    const getRolesList = type => {
      roleList.value = getRoleList(type);
    };

    /**
     * @description: 处理非分析师和首席分析师，清空机构和行业
     * @return {*}
     */
    const onRoleChange = () => {
      if (formData.value.roleId !== 3 && formData.value.roleId !== 4) {
        formData.value.orgName = '';
        formData.value.industryIdList = [];
      }
    };

    /**
     * @description: 获取机构列表
     * @param {*} type
     * @return {*}
     */
    const getOrgList = type => {
      const req = {
        [USER_TYPE_MAP.VENDOR]: service.getBrokerOrgList,
        [USER_TYPE_MAP.PURCHASER]: service.getBuyerOrgList
      };
      req[type]().then(res => {
        orgList.value = (res.data.list || res.data || []).map(item => {
          return {
            ...item,
            show: true
          };
        });
        currentOrgList.value = orgList.value.map(item => ({
          ...item,
          label: item.institutionChineseName
        }));
      });
    };

    /**
     * @description: 获取行业列表
     * @return {*}
     */
    const getIndustryList = () => {
      service.getIndustryList().then(res => {
        industryList.value = res.data.map(item => {
          return {
            label: item.name,
            value: item.id
          };
        });
      });
    };

    const filterOrg = val => {
      if (val) {
        currentOrgList.value = orgList.value
          .map(item => {
            return {
              ...item,
              show: item.institutionChineseName.indexOf(val) > -1
            };
          })
          .map(item => {
            return {
              ...item,
              label: highlightStr(item.institutionChineseName, val)
            };
          });
      } else {
        currentOrgList.value = orgList.value;
      }
    };

    /**
     * @description: 发送验证码倒计时
     * @return {*}
     */
    const handleTimeRun = () => {
      codeTime.value = 60;
      const timer = setInterval(() => {
        codeTime.value -= 1;
        if (codeTime.value === 0) {
          clearInterval(timer);
        }
      }, 1000);
    };

    /**
     * @description: 发送验证码,先校验，后发送
     * @return {*}
     */
    const sendCode = async () => {
      let result = '';
      form.value.validateField('email', str => {
        result = str;
      });
      if (!result) {
        try {
          if (sendingCode.value) {
            return;
          }
          sendingCode.value = true;
          const result = await service.sendEmailCode({
            data: {
              email: formData.value.email
            }
          });

          if (result.code === 200000) {
            handleTimeRun();
            SetTracker('Web_Verify_Page_Send_Click', {
              is_success: '是',
              err_log: ''
            });
          } else {
            SetTracker('Web_Verify_Page_Send_Click', {
              is_success: '否',
              err_log: result.message
            });
          }
          sendingCode.value = false;
        } catch (error) {
          console.error(error);
          sendingCode.value = false;
          SetTracker('Web_Verify_Page_Send_Click', {
            is_success: '否',
            err_log: error.message || ''
          });
        }
      }
    };

    /**
     * @description: 邮箱前缀变化校验邮箱合法性
     * @return {*}
     */
    const onEmailChange = () => {
      formData.value.email = `${emailPrefix.value}@${emailSuffix.value}`;
      form.value.validateField('email');
    };

    /**
     * @description: 机构变化，处理相应变化，如果是其他，清除邮箱，验证码相关，如果是正常机构，清除名片
     * @param {*} val 机构编码
     * @return {*}
     */
    const onInstitutionChange = val => {
      if (
        formData.value.institutionCode &&
        formData.value.institutionCode !== 'OTHER'
      ) {
        const ele = orgList.value.filter(
          item => item.institutionCode === formData.value.institutionCode
        )[0];
        emailSuffixList.value = ele.emailServers.map(suf => ({
          label: `@${suf}`,
          value: suf
        }));
        emailSuffix.value = ele.emailServers[0];

        // 行业切换，清除互斥数据，清除名片
        formData.value.cardImageUrls = [];
      } else if (formData.value.institutionCode === 'OTHER') {
        // 清除邮箱，验证码相关, 重置机构列表
        emailSuffixList.value = [];
        emailSuffix.value = '';
        emailPrefix.value = '';
        formData.value.email = '';
        formData.value.verificationCode = '';
        currentOrgList.value = orgList.value.map(item => ({
          ...item,
          label: item.institutionChineseName
        }));
      }
    };

    /**
     * @description: 名片上传变化
     * @param {Array[string]} cards 名片地址 数组
     * @return {*}
     */
    const onUploadChange = cards => {
      formData.value.cardImageUrls = cards;
      form.value.validateField('cardImageUrls');
    };

    /**
     * @description: 联系客户经理
     * @return {*}
     */
    const onConnectDAM = () => {
      SetTracker('Web_Verify_Page_CM_Click');
      dam(props.options.outerStore, {
        enterBy: '认证身份页'
      });
    };

    /**
     * @description: 初始化
     * @return {*}
     */
    const init = () => {
      // 处理错误信息
      errorMessage.value = props.options.errorMessage || '';

      // 如果出现错误，一定是名片重新认证
      if (errorMessage.value) {
        const userInfo = props.options.errorData;
        const tempFormData = {
          name: userInfo.name || '', // 名称
          roleId: userInfo.roleId || '', // 角色ID
          verificationCode: '', // 验证码
          orgName: userInfo.orgName || '', // 团队
          institutionCode: 'OTHER', // 机构编码
          industryIdList: userInfo.industryIdList || [], // 行业
          email: '', // 邮箱
          cardImageUrls: userInfo.cardImageUrls // 名片地址
        };
        errorOriginData.value = _.cloneDeep(tempFormData);
        formData.value = _.cloneDeep(tempFormData);
      }

      // 如果是审核中,展示数据，隐藏其他按钮
      pendingStatus.value = props.options.pendingStatus;

      if (pendingStatus.value) {
        const userInfo = props.options.pendingData;
        formData.value = {
          name: userInfo.name || '', // 名称
          roleId: userInfo.roleId || '', // 角色ID
          verificationCode: '', // 验证码
          orgName: userInfo.orgName || '', // 团队
          institutionCode: 'OTHER', // 机构编码
          industryIdList: userInfo.industryIdList || [], // 行业
          email: '', // 邮箱
          cardImageUrls: userInfo.cardImageUrls // 名片地址
        };
      }
    };
    // 卖方才有可能选择行业
    if (isVendor.value) {
      getIndustryList();
    }
    getRolesList(userType.value);
    getOrgList(userType.value);

    init();

    const onCancel = () => {
      props.resolve(false);
      props.callback();
    };
    const onSave = async () => {
      const result = await form.value.validate();

      if (!result) {
        return;
      }

      // 埋点信息
      const trackerOpts = {
        enter_by: props.options.enterBy,
        role_type:
          props.options.outerStore.state.global.user.type === 2
            ? '买方'
            : '卖方',
        phone_number: props.options.outerStore.state.global.user.mobile,
        user_name: formData.value.name,
        user_role: roleIdMap[formData.value.roleId],
        user_industry: formData.value.industryIdList
          .map(item => {
            return industryList.value.filter(ind => ind.value === item)[0]
              .label;
          })
          .join(','),
        user_team: formData.value.orgName,
        user_institution: '',
        user_email: '',
        is_upload_business_card: '是',
        is_success: '是',
        err_log: '',
        is_first_time_verify: props.options.first ? '是' : '否',
        ra_try_already: `${3 -
          props.options.outerStore.state.global.user.remainAmount}天`
      };

      if (formData.value.institutionCode === 'OTHER') {
        // 防止未改动直接提交
        if (_.isEqual(formData.value, errorOriginData.value)) {
          Message({
            type: 'error',
            message: '请修改信息'
          });
          return;
        }
        trackerOpts.is_upload_business_card = '是';
        service
          .setCardAuth({
            data: {
              cardImageUrls: formData.value.cardImageUrls,
              industryIdList: formData.value.industryIdList,
              orgName: formData.value.orgName,
              name: formData.value.name,
              roleId: formData.value.roleId
            }
          })
          .then(res => {
            SetTracker('Web_Verify_Done_Click', trackerOpts);

            props.resolve({
              type: 'card' // 代表名片认证
            });
            props.callback();
          })
          .catch(err => {
            SetTracker('Web_Verify_Done_Click', trackerOpts);
            this.$message.error(err.message);
            console.error(err);
            trackerOpts.is_success = '否';
            trackerOpts.err_log = err.message;
          });
      } else {
        trackerOpts.is_upload_business_card = '否';
        trackerOpts.user_email = formData.value.email;
        trackerOpts.user_institution = orgList.value.filter(item => {
          return item.institutionCode === formData.value.institutionCode;
        })?.[0]?.institutionChineseName;

        service
          .setCompleteInformation({
            data: {
              email: formData.value.email,
              industryIdList: formData.value.industryIdList,
              institutionCode: formData.value.institutionCode,
              orgName: formData.value.orgName,
              name: formData.value.name,
              roleId: formData.value.roleId,
              verificationCode: formData.value.verificationCode
            }
          })
          .then(res => {
            SetTracker('Web_Verify_Done_Click', trackerOpts);
            props.resolve({
              type: 'email' // 代表邮箱认证
            });
            props.callback();
          })
          .catch(err => {
            SetTracker('Web_Verify_Done_Click', trackerOpts);
            console.error(err);
            this.$message.error(err.message);
            trackerOpts.is_success = '否';
            trackerOpts.err_log = err.message;
          });
      }
    };

    onMounted(() => {
      SetTracker('Web_Verify_Page_View', {
        enter_by: props.options.enterBy,
        is_first_time_verify: props.options.first ? '是' : '否'
      });
    });

    onUnmounted(() => {
      document.body.removeChild(vm.proxy.$el);
    });

    return {
      onCancel,
      onSave,
      form,
      formData,
      rules,
      roleList,
      isVendor,
      orgList,
      showOrgNameItem,
      confirmDisabled,
      filterOrg,
      handleTimeRun,
      currentOrgList,
      emailPrefix,
      emailSuffix,
      emailSuffixList,
      onEmailChange,
      sendCode,
      onInstitutionChange,
      codeTime,
      industryList,
      showDisplayBusinessCardRelated,
      errorMessage,
      onConnectDAM,
      onUploadChange,
      onRoleChange,
      pendingStatus
    };
  }
});
</script>

<style lang="scss" scoped>
.authenticate-identity-dialog {
  .content {
    .error-box {
      border-radius: 6px;
      background: #fc6d6f;
      max-width: 336px;
      padding: 4px 12px;
      color: #fff;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0px auto 16px;
      &.pending {
        background: $theme-b-3;
      }
    }
    .scroll-box {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 388px;
      width: 348px;
      @include scrollbar(4, 4);
    }
    .form-wrap {
      margin-top: 12px;
      .common-input-item {
        width: 286px;
        &.institution-select-item {
          ::v-deep.el-input__inner {
            padding-left: 32px;
          }
          ::v-deep.el-input__prefix {
            left: 12px;
          }
          .icon-search {
            font-size: 16px;
            color: $font-b-3;
          }
        }
      }
      .email-form-item {
        .email-input-item-suffix {
          width: 128px;
          margin-left: 8px;
          &.no-select {
            ::v-deep.el-input__suffix {
              display: none;
            }
          }
        }
        .code-btn {
          color: $theme-b-1;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px; /* 157.143% */
          margin-left: 20px;
          // width: 70px;
          display: inline-block;
          cursor: pointer;
          &:hover {
            color: #5788ff;
          }
        }
        .time-run {
          color: $font-b-4;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px; /* 157.143% */
          margin-left: 24px;
          display: inline-block;
        }
      }
      .email-input-item {
        width: 150px;
      }
      .code-input-item {
        width: 184px;
      }
      .input-file {
        display: none;
      }
      .upload-card-box {
        width: 286px;
        .upload {
          width: 286px;
          height: 156px;
          border-radius: 4px;
          border: 1px solid #f4f5f6;
          background: #f7f8fa;

          cursor: pointer;
          .upload-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin: 52px auto 40px;
            background: url('./assets/images/up-add.png') no-repeat center
              center;
            background-size: 32px 32px;
          }
          .upload-text {
            border-radius: 0px 0px 4px 4px;
            background: $theme-b-3;
            color: #fff;
            text-align: center;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 183.333% */
          }
        }
      }
      .code-tip {
        color: $font-b-4;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px; /* 100% */
        margin-left: 50px;
        text-align: center;
        > span:last-of-type {
          cursor: pointer;
          color: $font-b-3;
          text-decoration-line: underline;
          &:hover {
            color: $theme-b-2;
          }
        }
      }
      &::v-deep.el-form {
        // .el-input
        .el-input__inner {
          background: #f4f5f6;
          border-radius: 6px;
        }
        .el-form-item__label {
          color: $font-b-1;
          padding-right: 0px;
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    padding-bottom: 24px;
    .el-button {
      width: 92px;
      height: 34px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 14px;
      text-align: center;
      padding: 10px 0px;
      font-weight: 400;
      border-radius: 6px;

      &.cancel {
        color: $font-b-2;
        background: #f4f5f6;
        border-color: #f4f5f6;
        &:hover {
          background: #eaeaea;
          // color: #eaeaea;
          border-color: #eaeaea;
        }
      }
      &.save {
        margin-left: 12px;
        color: rgba(255, 255, 255, 1);
        background: $theme-b-1;
        border-color: $theme-b-1;
        &:hover {
          background: $theme-b-2;
          border-color: $theme-b-2;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.authenticate-identity-dialog {
  // background: linear-gradient(181.02deg, #dfeaff 0.87%, #ffffff 51.26%), #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .el-dialog__header {
    padding: 24px 24px 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $font-b-1;
    .el-dialog__headerbtn {
      margin-right: 4px;
      .el-icon-close {
        color: $font-b-2;
      }
    }
    background: linear-gradient(181deg, #dfeaff 0.87%, #fff 99.13%), #fff;
    flex-shrink: 0;
  }
  .el-dialog__body {
    padding: 0px 24px;
    flex: 1;
    overflow: auto;
  }
  .el-dialog__footer {
    padding: 0px;
    flex-shrink: 0;
  }
}
.authenticate-identity-institution-select-popper {
  .select-institution-box {
    color: $font-b-3;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    cursor: pointer;
    .btn-text {
      color: $theme-b-1;
    }
  }
  .spec-option {
    position: sticky;
    bottom: 0px;
    background: #fff;
  }
  .el-select-dropdown__item {
    color: $font-b-1;
    &:hover {
      background: #f4f5f6;
    }
  }
}
</style>
