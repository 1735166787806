/*
 * @Copyright © 2022 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : silverbulllet@163.com
 * @Date          : 2022-12-20 15:46:48
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-12-06 10:21:28
 */
import VueRouter from 'vue-router';
import { Router } from '@alpha-pai/base';
import { URIInterceptor, Progress } from '@alpha-pai/core/router';
import store from './store';
import { AppModules } from './modules';
import { loadErrorHandler } from '@/common/utils/loadChunkError';
import hooks, { AuthInterceptor } from './adapters/router';
import afterHooks from './adapters/router/afterHooks';
import { Constants } from '@/adapters/config';

const routes = [...AppModules].reduce((pre, cur) => {
  const moduleRoutes =
    typeof cur.routes === 'function' ? cur.routes(store) : cur.routes;
  return pre.concat(moduleRoutes);
}, []);

const beforeEachHooks = [
  AuthInterceptor(store),
  URIInterceptor(store),
  ...hooks.map(fn => fn(store))
];

const afterEachHooks = [afterHooks(store)];

const router = new Router({
  BaseRouter: VueRouter,
  config: {
    mode: 'history',
    base: Constants.ROUTER_BASE || '/',
    routes
  },
  hooks: {
    beforeEach: [Progress.StartHook(), ...beforeEachHooks],
    afterEach: [...afterEachHooks, Progress.EndHook()]
  }
}).eject();

loadErrorHandler(router);
// loadErrorHandler(() => {
//   const { currentRoute } = router;
//   // 只有在登录页和web端才会触发这个逻辑
//   return (
//     currentRoute.path.startsWith('/reading/') ||
//     currentRoute.name === 'login-page'
//   );
// });

export default router;
