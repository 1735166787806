/*
 * @Copyright © 2022 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : chenll01@rabyte.cn
 * @Date          : 2022-05-10 20:26:13
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-10-08 14:14:52
 */
import SetTracker from '@/common/utils/web-setTracker';
import store from '@/store';
import { isBasicTrailVersion } from '@/common/utils/user';
import { ProductTypeEnums } from '@/common/config/enum';
import AppLayout from '@/modules/reading/components/layout/index.vue';
import { getRouteName } from '../utils';

const Summary = () =>
  import(/* webpackChunkName: "reading-summary" */ '../pages/summary').then(
    Summary => Summary
  );

const TransformMinutes = () =>
  import(
    /* webpackChunkName: "transform-minutes" */ '../pages/transform-minutes'
  ).then(TransformMinutes => TransformMinutes);

const DailyReportDetail = () =>
  import(
    /* webpackChunkName: "daily-report-detail" */ '../pages/daily-report-detail'
  ).then(DailyReportDetail => DailyReportDetail);

const HomeMyOrder = () =>
  import(
    /* webpackChunkName: "reading-my-order" */ '../pages/home/my-order'
  ).then(Page => Page);
const HomePoint = () =>
  import(
    /* webpackChunkName: "reading-home-point" */ '../pages/home/Point'
  ).then(Page => Page);
const HomeNews = () =>
  import(/* webpackChunkName: "reading-home-news" */ '../pages/home/News').then(
    Page => Page
  );
const Announcement = () =>
  import(
    /* webpackChunkName: "reading-announcement" */ '../pages/announcement'
  ).then(Page => Page);
const AnnouncementDetail = () =>
  import(
    /* webpackChunkName: "reading-announcement-detail" */ '../pages/announcement-detail'
  ).then(Page => Page);

// const HomeConvertMeeting = () =>
//   import(
//     /* webpackChunkName: "reading-home-convert-meeting" */ '../pages/home/ConvertMeeting'
//   ).then(Page => Page);
const PointDetail = () =>
  import(
    /* webpackChunkName: "reading-point-detail" */ '../pages/point-detail'
  ).then(PointDetail => PointDetail);
const UsStockPointDetail = () =>
  import(
    /* webpackChunkName: "reading-us-stock-point-detail" */ '../pages/us-stock-point-detail'
  ).then(UsStockPointDetail => UsStockPointDetail);

const PointOfficial = () =>
  import(
    /* webpackChunkName: "reading-point-list" */ '../pages/official/components/layout/index.vue'
  ).then(PointOfficial => PointOfficial);

const NewsDetail = () =>
  import(
    /* webpackChunkName: "reading-news-detail" */ '../pages/news/detail/index.vue'
  ).then(NewsDetail => NewsDetail);

const HomeMyFocus = () =>
  import(/* webpackChunkName: "reading-my-focus" */ '../pages/my-focus').then(
    homeMyFocus => homeMyFocus
  );

const Research = () =>
  import(
    /* webpackChunkName: "research-research-list" */ '../pages/research'
  ).then(Research => Research);

const CommentDetail = () =>
  import(
    /* webpackChunkName: "comment-detail" */ '../pages/comment-detail'
  ).then(CommentDetail => CommentDetail);

const ProfileLayout = () =>
  import(
    /* webpackChunkName: "reading-profile-layout" */ '../pages/profile/components/layout/index.vue'
  ).then(ProfileLayout => ProfileLayout);

const ProfileCollect = () =>
  import(
    /* webpackChunkName: "reading-profile-collect" */ '../pages/profile/collect/index.vue'
  ).then(ProfileCollect => ProfileCollect);

const ProfileHistory = () =>
  import(
    /* webpackChunkName: "reading-profile-history" */ '../pages/profile/history/index.vue'
  ).then(ProfileHistory => ProfileHistory);
const ProfilePasswordManage = () =>
  import(
    /* webpackChunkName: "reading-profile-password" */ '../pages/profile/password-manage/index.vue'
  );
const Search = () =>
  import(
    /* webpackChunkName: "reading-search" */ '../pages/search/index.vue'
  ).then(Search => Search);
const WithoutAuth = () =>
  import(
    /* webpackChunkName: "reading-without-auth" */ '../pages/without-auth/index.vue'
  );

const SurveyDetail = () =>
  import(
    /* webpackChunkName: "Survey-Detail" */ '../pages/survey-detail/index.vue'
  );
const FundManagerPointDetail = () =>
  import(
    /* webpackChunkName: "Fund-Manager-Point-Detail" */ '../pages/fund-manager-point-detail/FundManagerPointDetail.vue'
  );
const PublicRoadshow = () =>
  import(
    /* webpackChunkName: "PublicRoadshow" */ '../pages/roadshow/iframe-index.vue'
  );
const StockHomePage = () =>
  import(
    /* webpackChunkName: "reading-stock-home" */ '../pages/stock-homepage/index.vue'
  );
const Plate = () =>
  import(/* webpackChunkName: "reading-plate" */ '../pages/plate/index.vue');
const Comment = () =>
  import(/* webpackChunkName: "reading-plate" */ '../pages/comment/index.vue');

const EtfIndex = () =>
  import(
    /* webpackChunkName: "reading-etf-index" */ '../pages/etf/index/index.vue'
  );

const EtfDetail = () =>
  import(
    /* webpackChunkName: "reading-etf-detail" */ '../pages/etf/detail/index.vue'
  );

const SummaryDetail = () =>
  import(
    /* webpackChunkName: "reading-summary-detail" */ '../pages/summary-detail'
  );

const SelfSummaryDetail = () =>
  import(
    /* webpackChunkName: "reading-self-summary-detail" */ '../pages/summary-detail/SelfSummaryDetail.vue'
  );

const TranslateTool = () =>
  import(/* webpackChunkName: "translate-tool" */ '../pages/translate-tool');

const ForeignMarket = () =>
  import(
    /* webpackChunkName: "reading-foreign-market" */ '../pages/foreign-market'
  );

const TranslatePreview = () =>
  import(
    /* webpackChunkName: "translate-preview" */ '../pages/translate-tool/preview.vue'
  );
const Calendar = () =>
  import(/* webpackChunkName: "reading-calendar" */ '../pages/calendar');

const Report = () => import(/* webpackChunkName: "report" */ '../pages/report');

const routes = [
  {
    path: '/reading',
    component: AppLayout,
    redirect: '/reading/home/my-focus',
    // 权限控制
    beforeEnter(to, from, next) {
      const user = store?.state?.global?.user || {};
      const { name } = to;
      if (
        (isBasicTrailVersion(user) &&
          [
            'meeting-detail',
            'comment-detail-pc',
            'pointDetail',
            'foreignMarket'
          ].includes(name)) ||
        (user.productType === ProductTypeEnums.free && name === 'foreignMarket')
      ) {
        SetTracker('Web_No_Access_View', {
          data: { enter_name: getRouteName(to) }
        });
        next({
          name: 'without-auth',
          query: {
            origin: encodeURIComponent(to.fullPath)
          }
        });
        return false;
      }
      next();
    },
    children: [
      {
        name: 'homeMeeting',
        path: 'home/meeting',
        component: Summary,
        meta: {
          title: 'Alpha派-纪要',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'myOrder',
        path: 'home/my-order',
        component: HomeMyOrder,
        meta: {
          title: 'Alpha派-我的预约',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'homePoint',
        path: 'home/point',
        component: HomePoint,
        meta: {
          title: 'Alpha派-研报',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'homeReport',
        path: 'home/report',
        component: Report,
        meta: {
          title: 'Alpha派-研报',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'PublicRoadshow',
        path: 'home/public-roadshow',
        component: PublicRoadshow,
        meta: {
          title: 'Alpha派-会议',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'homeNews',
        path: 'home/news',
        component: HomeNews,
        meta: {
          title: 'Alpha派-产业资讯',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      // {
      //   name: 'homeConvertMeeting',
      //   path: 'home/convert-meeting',
      //   component: HomeConvertMeeting,
      //   meta: {
      //     title: 'Alpha派-AI纪要助理',
      //     name: 'home',
      //     requiresAuth: true,
      //     requiresURIAuth: true
      //   }
      // },
      {
        name: 'homeConvertMeeting',
        path: 'home/convert-meeting',
        component: TransformMinutes,
        meta: {
          title: 'Alpha派-AI纪要助理',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'dailyReportDetail',
        path: 'daily-report-detail',
        component: DailyReportDetail,
        meta: {
          title: 'Alpha派-专属日报详情',
          name: 'dailyReportDetail',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'homeMyFocus',
        path: 'home/my-focus',
        component: HomeMyFocus,
        meta: {
          title: 'Alpha派-首页',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'homeResearch',
        path: 'home/research',
        component: Research,
        meta: {
          title: 'Alpha派-调研',
          name: 'research',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'announcement',
        path: 'home/announcement',
        component: Announcement,
        meta: {
          title: 'Alpha派-公告',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'announcementDetail',
        path: 'home/announcement-detail',
        component: AnnouncementDetail,
        meta: {
          title: 'Alpha派',
          name: 'home',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },

      {
        name: 'pointDetail',
        path: 'home/point/detail',
        component: PointDetail,
        meta: {
          title: '研报详情',
          name: 'pointDetail',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },

      {
        name: 'usStockPointDetail',
        path: 'home/us-stock-point/detail',
        component: UsStockPointDetail,
        meta: {
          title: '研报详情-demo',
          name: 'usStockPointDetail',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },

      {
        name: 'point-official',
        path: 'home/point/official',
        component: PointOfficial,
        meta: {
          title: '公众号主页',
          name: 'official',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'news-official',
        path: 'home/news/official',
        component: PointOfficial,
        meta: {
          title: '公众号主页',
          name: 'official',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'news-detail',
        path: 'home/news/detail',
        component: NewsDetail,
        meta: {
          title: '产业资讯详情',
          name: 'news-detail',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'meeting-detail',
        path: 'home/meeting/detail',
        component: SummaryDetail,
        meta: {
          title: '纪要详情',
          name: 'meeting-detail',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'selfSummaryDetail',
        path: 'self-summary-detail',
        component: SelfSummaryDetail,
        meta: {
          title: '转换纪要详情',
          name: 'selfSummaryDetail',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'comment-detail-pc',
        path: 'home/comment/detail',
        component: CommentDetail,
        meta: {
          title: '点评详情',
          name: 'comment-detail-pc',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        path: 'profile',
        component: ProfileLayout,
        redirect: 'profile/collect',
        children: [
          {
            path: 'collect',
            component: ProfileCollect,
            name: 'profileCollect',
            meta: {
              title: 'Alpha派-我的-收藏',
              requiresAuth: true,
              requiresURIAuth: true
            }
          },
          {
            path: 'history',
            component: ProfileHistory,
            name: 'profileHistory',
            meta: {
              title: 'Alpha派-我的-历史记录',
              requiresAuth: true,
              requiresURIAuth: true
            }
          },
          {
            path: 'password-manage',
            component: ProfilePasswordManage,
            name: 'profilePasswordManage',
            meta: {
              title: 'Alpha派-我的-密码管理',
              requiresAuth: true,
              requiresURIAuth: true
            }
          }
        ]
      },
      {
        name: 'search',
        path: 'search',
        component: Search,
        meta: {
          title: '综合搜索-Alpha派',
          name: 'search',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'stock-home-page',
        path: 'home/stock',
        component: StockHomePage,
        meta: {
          title: '个股主页-Alpha派',
          name: 'stock',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'plate',
        path: 'home/segment',
        component: Plate,
        meta: {
          title: '板块-Alpha派',
          name: 'plate',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'comment',
        path: 'home/comment',
        component: Comment,
        meta: {
          title: '点评-Alpha派',
          name: 'comment',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'etf-index',
        path: 'home/etf',
        component: EtfIndex,
        meta: {
          title: 'ETF-Alpha派',
          name: 'etf-index',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'etf-detail',
        path: 'home/etf/detail',
        component: EtfDetail,
        meta: {
          title: 'ETF-Alpha派',
          name: 'etf-detail',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'calendar',
        path: 'home/calendar',
        component: Calendar,
        meta: {
          title: '日历-Alpha派',
          name: 'calendar',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        path: 'without-auth',
        name: 'without-auth',
        component: WithoutAuth,
        meta: {
          name: 'without-auth',
          requiresAuth: true
        }
      },
      {
        path: 'survey/detail',
        name: 'survey-detail',
        component: SurveyDetail,
        meta: {
          title: '调研详情-Alpha派',
          name: 'survey-detail',
          requiresAuth: true
        }
      },
      {
        path: 'fund-manager-point/detail',
        name: 'fund-manager-point-detail',
        component: FundManagerPointDetail,
        meta: {
          title: '基金经理观点详情-Alpha派',
          name: 'fund-manager-point-detail',
          requiresAuth: true
        }
      },
      {
        name: 'translate-tool',
        path: 'translate-tool',
        component: TranslateTool,
        meta: {
          title: '翻译工具-Alpha派',
          name: 'translate-tool',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'translate-preview',
        path: 'translate-preview',
        component: TranslatePreview,
        meta: {
          title: '翻译预览-Alpha派',
          name: 'translate-preview',
          requiresAuth: true,
          requiresURIAuth: true
        }
      },
      {
        name: 'foreignMarket',
        path: 'foreign-market',
        component: ForeignMarket,
        meta: {
          title: '海外市场-Alpha派',
          name: 'foreign-market',
          requiresAuth: true,
          requiresURIAuth: true
        }
      }
    ]
  },
  // 注：录音转纪要成功后短信出链接
  {
    path: '/c',
    component: AppLayout,
    redirect: '/reading/home/convert-meeting'
  }
];

export default routes;
