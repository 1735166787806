<template>
  <div class="cp-trial-countdown-content">
    <div class="title">
      <span>产品试用还剩</span>
      <i>{{ user.remainAmount || 1 }}</i>
      <span>天</span>
    </div>
    <div class="tip">
      <img src="@/static/images/trial-countdown-symbol.png" alt="" />
      <span>立即认证身份</span>
      <img src="@/static/images/trial-countdown-symbol.png" alt="" />
    </div>
    <template v-if="isPurchasers">
      <h2>立即认证可获得30天机构付费会员权益</h2>
      <h2>
        <span>解锁更多投研用户专属内容</span>
        <a href="javascript:;" @click="handleLinkClick">
          了解更多<i class="el-icon-arrow-right" />
        </a>
      </h2>
    </template>
    <template v-else>
      <h2>开启自由产品使用</h2>
      <h2>解锁更多投研用户专属内容</h2>
    </template>
    <h2></h2>
    <div class="btn-box">
      <custom-button
        type="default"
        size="medium"
        label="暂不认证"
        @click="handleCancel"
        class="btn-confirm left"
      />
      <custom-button
        type="primary"
        size="medium"
        label="立即认证"
        @click="handleConfirm"
        class="btn-confirm right"
      />
    </div>

    <AuthenticationModal
      ref="authenticationModal"
      @close="close"
      enter-by="倒计时弹窗"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { InstitutionRoleEnums } from '@/common/config/enum';
import SetTracker from '@/common/utils/web-setTracker';
import AuthenticationModal from '../authentication-modal';

export default {
  components: {
    AuthenticationModal
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['user']),
    isPurchasers() {
      return this.user?.type === InstitutionRoleEnums.purchasers;
    }
  },
  methods: {
    handleCancel() {
      this.setTracker('暂不认证，继续试用');
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },

    async handleConfirm() {
      this.setTracker('立即认证');
      this.$refs.authenticationModal.handleOpen(result => {
        if (result.type === 'email') {
          this.$emit('close');
        }
      });
    },
    handleLinkClick() {
      this.setTracker('了解更多');
      this.$emit('intro', '试用倒计时弹窗');
    },
    setTracker(button_name) {
      const { user } = this;
      SetTracker('Web_Count_Down_Pop_Button_Click', {
        data: {
          button_name,
          try_already: user.trialAmount - user.remainAmount,
          try_remain: user.remainAmount
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';

.cp-trial-countdown-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 486px;
  height: 298px;
  border-radius: 6px;
  background-image: linear-gradient(180deg, #dfeaff 0%, #fff 45.05%);
  background-color: #fff;
  text-align: center;
  .title {
    margin-top: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36px;
    font-size: 18px;
    font-weight: 600;
    color: $font-b-1;
    i {
      width: 34px;
      margin: 0 10px;
      border-radius: 4px;
      background: linear-gradient(180deg, $theme-b-1, #bccdf4);
      color: $white;
      font-family: DIN Alternate;
      font-size: 32px;
      font-weight: 700;
      font-style: normal;
    }
  }
  .tip {
    margin: 32px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: $theme-b-1;
    span {
      margin: 0 6px;
    }
    img {
      width: 65px;
      height: 11px;
      &:first-child {
        transform: rotate(180deg);
      }
    }
  }
  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $font-b-2;
    & + h2 {
      margin-top: 4px;
    }
  }
  .img-wrap {
    overflow: hidden;
    margin: 24px auto 0;
    width: 120px;
    height: 120px;
    border: 1px solid $theme-b-4;
    border-radius: 6px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .btn-box {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 32px;
    .btn-confirm {
      &.left {
        margin-right: 24px;
        &:hover {
          background: #ffffff;
          border-color: $theme-b-2;
          color: $theme-b-2;
        }
      }
      &.right {
        margin-left: 0px;
      }
    }
  }
  .btn-confirm {
    // position: absolute;
    // bottom: 58px;
    // left: 50%;
    // transform: translate(-50%);
    width: 200px;
    &.left {
      width: 132px;
    }
  }
  .btn-cancel {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);
    width: 200px;
  }
}
</style>
