/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:54
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-07-17 14:25:54
 */
export const formatFileSize = size => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const threshold = 1024;

  size = Number(size) * threshold;
  const index =
    size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold));

  return `${(size / Math.pow(threshold, index)).toFixed(2) * 1} ${
    units[index]
  }`;
};
