import { CustomButton } from './custom-button';

const CustomComponents = {
  CustomButton
};

function install(Vue) {
  Object.keys(CustomComponents).forEach(key => {
    const component = CustomComponents[key];
    Vue.component(component.name, component);
  });
}

export default {
  install
};
