/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-21 17:11:10
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-21 17:17:26
 */
const profileFormat = (userInfo = {}) => {
  if (!userInfo.uid) {
    return {};
  }

  const {
    mobile,
    type,
    name,
    email,
    industry,
    orgName,
    roleName,
    institution,
    trialBeginTime,
    createTime,
    trialFlag,
    informationFlag,
    remainAmount,
    trialAmount
  } = userInfo;

  // trialBeginTime（试用开始时间）、createTime（注册时间），trialFlag + informationFlag + remainAmount + trialAmount 综合判断(当前状态：试用中、试用结束、已认证)
  // 另外：试用期已过的用户，trialFlag=0  remainAmount=0 trialAmount>0  informationFlag=0；试用期未过用户trialFlag=1  remainAmount>0 trialAmount>0  informationFlag=0
  let curStatus = ''; // 试用中、试用结束、已认证
  if (informationFlag) {
    curStatus = '已认证';
  } else {
    if (trialFlag === 0 && remainAmount === 0 && trialAmount > 0) {
      curStatus = '试用结束';
    } else {
      curStatus = '试用中';
    }
  }

  return {
    User_Phone: mobile,
    User_Type: type === '1' ? '卖方' : '买方',
    User_Name: name,
    User_Email: email,
    User_Industry: industry,
    User_Orgname: orgName,
    User_Role: roleName,
    User_Institution: institution,
    Register_Date: createTime,
    Current_Status: curStatus,
    Try_Start_Date: trialBeginTime,
    Verify_Date: '', // 该字段暂不存在，后续添加
    Verify_Type: '' // 该字段暂不存在，后续添加
  };
};

export default profileFormat;
