<template>
  <div class="cp-end-of-vip-modal" v-if="visible">
    <div class="content">
      <h1>会员已到期</h1>
      <h2>
        <span>联系客户经理续期或</span>
        <a href="javascript:;" @click="handleIntro">
          了解更多<i class="el-icon-arrow-right" />
        </a>
      </h2>
      <div class="name-card-wrap" v-if="managerInfo && managerInfo.mobile">
        <dl>
          <dt>{{ managerInfo.name }}</dt>
          <dd>{{ managerInfo.mobile }}</dd>
        </dl>
        <div class="code-wrap">
          <img :src="managerInfo.wxCardUrl" alt="" />
          <p>扫码添加好友</p>
        </div>
      </div>

      <div class="contact-us">
        <i class="iconfont icon-phone" />
        <span>客服热线 {{ mobile }}</span>
      </div>
      <custom-button
        type="primary"
        size="medium"
        label="我知道了"
        @click="handleConfirm"
        class="btn-confirm"
      />
    </div>
  </div>
</template>
<script>
import { MOBILE_SHOW } from '@/common/config/constants';
import SetTracker from '@/common/utils/web-setTracker';
import { getCustomerManager } from '@/services/user';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      managerInfo: null,
      mobile: MOBILE_SHOW
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(v) {
        if (!v) {
          return;
        }
        getCustomerManager().then(res => {
          this.managerInfo = res.data;
          SetTracker('Web_member_End_View', {
            data: { cm_name: res.data?.name || '' }
          });
        });
      }
    }
  },
  methods: {
    handleIntro() {
      this.$emit('intro', '会员到期');
    },
    handleConfirm() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';

.cp-end-of-vip-modal {
  z-index: 1999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 486px;
    height: 364px;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #dfeaff 0%, #fff 45.05%);
    background-color: #fff;
    text-align: center;
    h1 {
      margin-top: 48px;
      line-height: 26px;
      font-size: 18px;
      font-weight: 600;
      color: $font-b-1;
    }
    h2 {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $font-b-2;
      & + p {
        margin-top: 4px;
      }
    }
    .name-card-wrap {
      position: relative;
      overflow: hidden;
      margin: 13px auto 0;
      width: 324px;
      height: 118px;
      border-radius: 6px;
      background: url(~@/static/images/name-card-bg.png) center / 100% 100%;
      color: $theme-b--4;
      dl {
        position: absolute;
        top: 30px;
        left: 15px;
        text-align: left;
        dt {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
        }
        dd {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .code-wrap {
        position: absolute;
        right: 15px;
        top: 16px;
        font-size: 12px;
        line-height: 20px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          margin: 0 auto 4px;
          border: 1px solid $theme-b-2;
        }
      }
    }
    .contact-us {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 22px;
      color: $font-b-2;
      i {
        margin-right: 4px;
        font-size: 16px;
      }
    }
    .btn-confirm {
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translate(-50%);
      width: 200px;
    }
  }
}
</style>
