import Dao from '@/adapters/dao';

class StockDao extends Dao {
  // 个股主页的综合资讯
  async getArticleList(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/wechat/article/list',
      data: options
    });
  }

  // 综合搜索股票列表
  async getStockList(options = {}) {
    const params = options;
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/stock/search',
      params
    });
  }

  // 股票搜索
  async searchStock(options = {}) {
    const params = options;
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/information/flow/stock/search',
      params
    });
  }

  // 获取股票关注状态
  async getFollow(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/follow/query',
      data: options
    });
  }

  // 批量关注股票
  async multiFollow(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/follow/multi',
      data: options
    });
  }

  // 取消关注股票
  async unfollowStock(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/information/flow/stock/unfollow',
      data: options
    });
  }

  // 获取点评
  async getDetailList(options = {}) {
    const params = options.data;
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/discovery/detail/list',
      params
    });
  }

  // 股票估值与分位
  async getStockValuation(options = {}) {
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/stock/value',
      params: options.params
    });
  }

  // 个股chart
  async getPriceSummary(options = {}) {
    return this.fetch({
      method: 'GET',
      url: '/external/alpha/api/reading/stock/summary',
      params: options.params
    });
  }

  // 热词二级页-个股图表数据
  getHortStockChartData(options = {}) {
    return this.fetch({
      method: 'get',
      url: '/external/alpha/api/reading/discovery/stock/heat/pic/message',
      params: options.params
    });
  }

  // 个股信息
  getBaseInfo(options = {}) {
    return this.fetch({
      method: 'get',
      url: '/external/alpha/api/reading/discovery/detail',
      params: options.params
    });
  }

  // 获取市值与持仓
  getStockOverview(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/data/fund/search/stock/overview',
      data: options
    });
  }

  // 获取会议纪要列表
  async getSummaryList(options = {}) {
    return this.fetch({
      method: 'POST',
      url: '/external/alpha/api/reading/roadshow/summary/list',
      data: options
    });
  }
}

export default StockDao;
