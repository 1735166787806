<template>
  <div :class="classname" @click="handleClick">
    <slot v-if="$slots.default" />
    <template v-else>{{ content }}</template>
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api';
const SIZE_ENUMS = ['large', 'medium', 'small', 'mini'];
const TYPE_ENUMS = ['default', 'primary', 'link'];

export default defineComponent({
  emits: ['click'],
  props: {
    block: {
      type: Boolean,
      default: false //
    },
    plain: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default' // default、primary、link
    },
    size: {
      type: String,
      default: 'medium' // large\medium\small\mini
    },
    disabled: {
      type: Boolean,
      default: false
    },
    underline: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const size = computed(() =>
      SIZE_ENUMS.includes(props.size) ? props.size : 'medium'
    );
    const type = computed(() =>
      TYPE_ENUMS.includes(props.type) ? props.type : 'default'
    );
    const plain = computed(() => props.plain && type.value !== 'link');
    const classname = computed(() => {
      const res = [
        'cp-saas-admin-button',
        `button-${size.value}`,
        `button-${type.value}${plain.value ? '-plain' : ''}`,
        plain.value ? 'plain' : ''
      ];
      if (props.block) {
        res.push('is-block');
      }
      if (props.disabled) {
        res.push('is-disabled');
      }
      if (type.value === 'link' && props.underline) {
        res.push('underline');
      }
      return res.join(' ');
    });
    const handleClick = () => {
      if (props.disabled) {
        return;
      }
      context.emit('click');
    };
    return {
      classname,
      handleClick
    };
  }
});
</script>
<style lang="scss" scoped>
.cp-saas-admin-button {
  display: inline-block;
  padding: 0 32px;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  &.button-default {
    color: $font-b-2;
    background-color: $color-bg-1;
    &:hover {
      background: rgba(221, 223, 225, 0.6);
    }
    &:active {
      background-color: $font-b-5;
    }
    &.is-disabled {
      color: $font-b-4;
    }
  }
  &.button-default-plain {
    color: $color-bg-1;
    border: 1px solid $color-bg-1;
    &:hover {
      color: rgba(221, 223, 225, 0.6);
      border-color: rgba(221, 223, 225, 0.6);
    }
    &:active {
      color: $font-b-5;
      border-color: $font-b-5;
    }
    &.is-disabled {
      color: $font-b-4;
      border-color: $font-b-4;
    }
  }
  &.button-primary {
    color: $white;
    background-color: $theme-b-1;
    &:hover {
      background-color: $theme-b-2;
    }
    &:active {
      background-color: $theme-b--1;
    }
    &.is-disabled {
      background-color: $theme-b-5;
    }
  }
  &.button-link {
    padding: 0 !important;
    color: $theme-b-1;
    &:hover {
      color: $theme-b-2;
    }
    &:active {
      color: $theme-b--1;
    }
    &.is-disabled {
      color: $theme-b-5;
    }
    &.underline {
      text-decoration: underline;
    }
  }
  &.button-primary-plain {
    color: $theme-b-1;
    border: 1px solid $theme-b-1;
    &:hover {
      color: $theme-b-2;
      border-color: $theme-b-2;
    }
    &:active {
      color: $theme-b--1;
      border-color: $theme-b--1;
    }
    &.is-disabled {
      color: $theme-b-5;
      border-color: $theme-b-5;
    }
  }
  &.button-large {
    padding-top: 9px;
    padding-bottom: 9px;
    &.plain {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  &.button-medium {
    padding-top: 6px;
    padding-bottom: 6px;
    &.plain {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  &.button-small {
    padding-top: 3px;
    padding-bottom: 3px;
    &.plain {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  &.button-mini {
    padding-top: 1px;
    padding-bottom: 1px;
    &.plain {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &.is-block {
    display: block;
    width: 100%;
  }
  &.is-disabled {
    cursor: not-allowed;
  }
}
</style>
