/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-06-13 15:04:40
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-06-27 15:11:16
 */
export function checkDocxFile(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = async function(e) {
        const content = e.target.result;
        const mammoth = await import('mammoth');
        mammoth
          .extractRawText({ arrayBuffer: content })
          .then(function(result) {
            const content = result.value;
            const wordList = content.split(/\s+/).filter(Boolean);
            let len = 0;
            wordList.forEach(word => {
              len += word.length;
            });
            resolve(len);
          })
          .catch(function(error) {
            console.error(error);
            reject(new Error('文件解析失败'));
          });
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error(error);
      reject(new Error('文件解析失败'));
    }
  });
}

export function countWordsInTextFile(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = function(e) {
        const content = e.target.result;
        const wordList = content.split(/\s+/).filter(Boolean);
        let len = 0;
        wordList.forEach(word => {
          len += word.length;
        });
        resolve(len);
      };

      reader.readAsText(file);
    } catch (error) {
      reject(error);
    }
  });
}
