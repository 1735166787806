/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-09-12 14:18:43
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-12 14:18:44
 */

export const USER_TYPE_MAP = {
  VENDOR: 1, // 卖方
  PURCHASER: 2 // 买方
};
