/*
 * @Copyright © 2022 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : silverbulllet@163.com
 * @Date          : 2022-06-13 20:15:20
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-09-05 15:45:00
 */
import DefaultAdapterDao from './default/AdapterDao';

const AdapterDao = DefaultAdapterDao;

export default AdapterDao;

const http = new AdapterDao();

export { http };
