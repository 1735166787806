<template>
  <div>
    <WebLoginModal
      :type="webLoginModalType"
      :isPurchasers="isPurchasers"
      v-show="showPending"
      @close="onWebLoginModalClose"
    ></WebLoginModal>
  </div>
</template>
<script>
import WebLoginModal from '@/common/components/web-login-modal';
import { mapGetters, mapActions } from 'vuex';
import service from '@/modules/home/http/login.js';
import { BUSINESS_CARD_VERIFICATION_FAILED_REASON_ENUMS } from '@/modules/home/config/enum.js';
import authenticateIdentity from '../authenticate-identity';

export default {
  name: 'AuthenticationModal',
  components: {
    WebLoginModal
  },
  props: {
    enterBy: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPending: false, // 是否展示审核中弹窗
      webLoginModalType: 'verifyVerifying' // 身份认证审核中 verifyVerifying
    };
  },
  computed: {
    ...mapGetters(['user']),
    isPurchasers() {
      return this.user && `${this.user.type}` === '2';
    }
  },
  watch: {},
  methods: {
    ...mapActions(['setGlobalAppData']),
    onWebLoginModalClose() {
      this.showPending = false;
      this.setGlobalAppData();
      this.$emit('close');
    },
    /**
     * @description: 获取审核详情
     * @return {*}
     */
    async getAuditDetail() {
      let result = {};
      const { data } = await service.getAuditDetail();
      result = data;
      return result;
    },
    /**
     * @description: 打开身份认证弹窗
     * @return {*}
     */
    async handleOpen(cb = () => {}) {
      const options = {
        userType: this.user.type,
        outerStore: this.$store,
        enterBy: this.enterBy || '',
        first: true
      };
      if (this.user.cardAuditStatus === 1) {
        options.pendingStatus = true;
        options.pendingData = await this.getAuditDetail();
        options.first = false;
      } else if (this.user.cardAuditStatus === 3) {
        options.errorData = await this.getAuditDetail();
        let reason = '';
        if (options.errorData.auditType === '5') {
          reason = options.errorData.auditText || '';
        } else {
          reason =
            BUSINESS_CARD_VERIFICATION_FAILED_REASON_ENUMS[
              options.errorData.auditType
            ];
        }
        options.errorMessage = reason;
        options.first = false;
      }

      const result = await authenticateIdentity(options);
      if (result.type === 'card') {
        this.webLoginModalType = 'verifyVerifying';
        this.showPending = true;
      } else {
        this.setGlobalAppData();
      }

      cb(result);
    }
  }
};
</script>
