<template>
  <div
    v-if="isVisible"
    class="cp-vip-introduction-modal"
    :class="transparent ? 'transparent' : ''"
  >
    <div class="content" :style="contentStyles">
      <a class="el-icon-close btn-close" @click="handleClose" />
      <img src="./images/header.png" alt="" />
      <div class="card">
        <NameCard />
        <div class="contact-us">客服热线 {{ mobile }}</div>
      </div>
      <img src="./images/benifits.png" alt="" />
      <div class="bottom-tips">
        *版本权限随产品演进动态调整，最终解释权归讯兔科技所有
      </div>
    </div>
  </div>
</template>
<script>
import SetTracker from '@/common/utils/web-setTracker';
import { getRouteName } from '@/modules/reading/utils/';
import { MOBILE_SHOW } from '@/common/config/constants';
import NameCard from '../name-card';

export default {
  props: {
    visible: {
      type: String,
      default: ''
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NameCard
  },
  data() {
    return {
      mobile: MOBILE_SHOW,
      isVisible: false,
      contentStyles: {}
    };
  },
  computed: {
    customerManager() {
      return this.$store.state.readingStore.customerManager;
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(v) {
        const res = Boolean(v);
        if (res) {
          this.contentStyles.maxHeight = `${document.documentElement.getBoundingClientRect()
            .height - 60}px`;
        }
        this.isVisible = v;
        if (v) {
          SetTracker('Web_Membership_Detail_Page_View', {
            data: {
              enter_by: `${getRouteName(this.$route)}-${v}`
            }
          });
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
.cp-vip-introduction-modal {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  &.transparent {
    background-color: transparent;
  }
  .content {
    overflow: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 384px;
    border-radius: 10px;
    background-color: #100e1e;
    &::-webkit-scrollbar {
      display: none;
    }
    img {
      display: block;
      width: 100%;
    }
  }

  .card {
    margin: 0 auto;
    width: 100%;
    height: 160px;
    background: url(./images/card-bg.png) center / 100% 100%;
    .contact-us {
      margin-top: 8px;
      font-size: 13px;
      line-height: 22px;
      color: #ffd2a6;
      text-align: center;
    }
  }
  .btn-close {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
  .bottom-tips {
    line-height: 20px;
    padding: 8px 0 24px;
    text-align: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
  }
}
</style>
