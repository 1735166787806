export const APP_SENT_MOBILE_CODE_PLATFORM = '0'; //  0是短信 1是微信

export const WX_SHARE_CONTENT = '更多优质投研信息，尽在Alpha派';
export const MOBILE_SHOW = '400-082-1099';
export const MOBILE = '4000821099';

/***
 * @description: 股票类型
 ***/
export const stockTypeList = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: 'A股',
    value: 10
  },
  {
    label: '港股',
    value: 20
  },
  {
    label: '美股',
    value: 30
  }
];
