import { render, staticRenderFns } from "./HistoryWords.vue?vue&type=template&id=4b94be86&scoped=true"
import script from "./HistoryWords.vue?vue&type=script&lang=js"
export * from "./HistoryWords.vue?vue&type=script&lang=js"
import style0 from "./HistoryWords.vue?vue&type=style&index=0&id=4b94be86&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b94be86",
  null
  
)

export default component.exports