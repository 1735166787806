<!--
 * Copyright © 2023 讯兔科技, All Rights Reserved.
 * 该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : luyue01@rabyte.cn
 * @Date          : 2023-11-13 10:49:19
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-06-21 14:19:34
-->
<template>
  <div class="app-search-container">
    <el-form @submit.native.prevent>
      <el-input
        class="app-search"
        :class="{ typing: !!keyword }"
        size="medium"
        :placeholder="placeholder"
        v-model="keyword"
        @input="onInput"
        :clearable="false"
        @click="onClick"
        @keyup.native.enter="onEnterPress"
        @focus="onFocus"
        @blur="onBlur"
        :maxlength="144"
        style="width: 434px;"
      >
        <div slot="suffix">
          <img
            ref="paipaiIcon"
            v-if="paipaiOffset !== -1"
            class="ai-assistant-icon"
            :style="{ right: paipaiOffset }"
            :src="require('@/static/images/ai-assistant.png')"
            v-show="!isFocus && !keyword"
          />
          <div class="delete-container" v-if="!!keyword">
            <i class="delete-icon iconfont icon-delete" @click="onClear"></i>
            <span class="line-space"></span>
          </div>
          <i
            class="search-icon iconfont icon-zhusousuo"
            :class="{ active: keyword }"
            @click="onSearch"
          ></i>
          <!--          <i class="search-icon iconfont icon-zhusousuo" @click="onSearch"></i>-->
        </div>
      </el-input>
    </el-form>
  </div>
</template>

<script>
import SetTracker from '@/common/utils/setTracker';
import Constants from '@/modules/reading/config/constants';

let questionIndex = 0;
export default {
  props: {
    defaultWord: String,
    searchCount: {
      type: Number,
      default: 0
    },
    suggestion: {
      default: () => {}
    }
  },
  data() {
    const showGuide = !localStorage.getItem('hasShowSearchOuterGuide');
    return {
      keyword: '',
      focus: false,
      placeholder: '搜索个股/标题/关键词',
      isFocus: false,
      showGuide
    };
  },
  methods: {
    onClick() {
      SetTracker('Web_Search_Input_Click');
    },
    onInput(value) {
      this.$emit('input', value);
    },
    onBlur() {
      this.isFocus = false;
    },
    onFocus() {
      this.isFocus = true;
    },
    onEnterPress() {
      const keyword = this.validate() ? this.keyword : this.placeholder;
      this.gotoSearchResult({ keyword });
    },
    onSearch() {
      const keyword = this.validate() ? this.keyword : this.placeholder;
      this.gotoSearchResult({ keyword });
    },
    onClear() {
      this.keyword = '';
      this.$emit('input', '');
    },
    gotoSearchResult({ type, code, keyword }) {
      SetTracker('Web_Search_Click', {
        search_words: keyword,
        search_types: '直接输入'
      });
      const query = {
        keyword
      };
      if (type) {
        query.type = type;
      }
      if (code) {
        query.code = code;
      }
      const { href } = this.$router.resolve({
        name: 'search',
        query
      });
      window.open(href, '_blank');
    },
    validate() {
      if (!this.keyword || !/\S/.test(this.keyword)) {
        // this.$message({
        //   type: 'info',
        //   message: '请输入搜索词'
        // });
        return false;
      }
      return true;
    },
    async initPaipaiPlaceholder() {
      let questionList;
      try {
        const { data } = await this.$dao.readDao.getPaipaiPlaceholder();
        questionList = data;
      } catch {
        questionList = Constants.DEFAULT_QUESTION_LIST;
      }
      if (questionList.length > 0) {
        this.placeholder = questionList[questionIndex];
        setInterval(() => {
          if (questionIndex + 1 >= questionList.length) {
            questionIndex = 0;
          } else {
            questionIndex++;
          }
          this.placeholder = questionList[questionIndex];
        }, 3000);
      }
    }
  },
  mounted() {
    this.initPaipaiPlaceholder();
    /* const questionList = Constants.DEFAULT_QUESTION_LIST;
    if (questionList.length > 0) {
      this.placeholder = questionList[questionIndex];
      setInterval(() => {
        if (questionIndex + 1 >= questionList.length) {
          questionIndex = 0;
        } else {
          questionIndex++;
        }
        this.placeholder = questionList[questionIndex];
      }, 3000);
    } */
  },
  watch: {
    searchCount() {
      if (this.defaultWord) {
        this.keyword = this.defaultWord;
        this.gotoSearchResult({ keyword: this.keyword });
      }
    },
    suggestion(suggestion) {
      if (suggestion.code) {
        this.gotoSearchResult(suggestion);
      }
    }
  },
  computed: {
    paipaiOffset() {
      const span = document.createElement('span');
      span.innerHTML = this.placeholder;
      span.style.fontSize = '14px';
      span.style.padding = '0px';
      span.style.margin = '0px';
      document.documentElement.appendChild(span);
      const obj = span.getBoundingClientRect();
      const width = obj.width;
      span.remove();
      return 393 - width + 'px';
    }
  }
};
</script>

<style scoped lang="scss">
.app-search-container {
  position: relative;
  z-index: 2023;
  height: 30px;
  .ai-assistant-icon {
    position: absolute;
    right: 250px;
    top: 7px;
    width: 16px;
  }
  .search-icon {
    position: absolute;
    right: 6px;
    top: 0.5px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.65);
    font-size: 20px;
    &.active {
      color: $white;
    }
    &:hover {
      color: $white;
    }
  }
  .delete-container {
    position: absolute;
    right: 37px;
    top: 5.5px;
    display: flex;
    align-items: center;
    .delete-icon {
      color: $font-b-4;
      font-size: 14px;
      cursor: pointer;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
    .line-space {
      display: inline-block;
      width: 1px;
      height: 12px;
      background: $theme-bg-4;
      margin-left: 11px;
    }
  }
  ::v-deep .el-input__inner {
    background: $theme-bg-3 !important;
    border-radius: 4px;
    padding-right: 40px;
    height: 30px;
    color: $white;
  }
  .typing ::v-deep .el-input__inner {
    padding-right: 76px;
  }
  ::v-deep .el-input__inner {
    width: 434px;
  }
  ::v-deep .el-input__inner:hover {
    border: 1px solid $theme-b-2;
  }
  ::v-deep .el-input__inner:focus {
    border: 1px solid $theme-b-1;
  }
}
</style>
