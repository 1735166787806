<template>
  <Modal @close="handleClose">
    <div class="free-version-tips-modal">
      <div class="content">
        <div class="desc">您已进入免费版</div>
        <div class="desc" @click="handleShowDesc">
          立即<span>了解免费版权益</span>
          <i class="el-icon-arrow-right" />
        </div>
      </div>
      <div
        class="btn-confirm"
        :class="isDisabled ? 'disabled' : ''"
        @click="handleClose"
      >
        我知道了
      </div>
      <div class="contact-us" @click="handleShowNameCardModal">
        如有疑问，<span>联系客户经理</span>
      </div>
    </div>
  </Modal>
</template>
<script>
import { removeFreeProductFlag } from '@/services/user';
import dam from '../dedicated-account-manager';
import Modal from '../free-version-modal';
export default {
  components: {
    Modal
  },
  data() {
    return {
      isDisabled: false
    };
  },
  methods: {
    handleClose() {
      if (this.isDisabled) {
        return;
      }
      this.isDisabled = true;
      removeFreeProductFlag()
        .then(() => {
          setTimeout(() => {
            location.reload();
          }, 200);
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: err?.msg || '系统错误，请稍后再试'
          });
        })
        .finally(() => {
          this.isDisabled = false;
        });
    },
    handleShowDesc() {
      this.$emit('intro', '免费版提示弹窗');
    },
    handleShowNameCardModal() {
      dam(this.$store, {
        enterBy: '免费版提示弹窗'
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.free-version-tips-modal {
  text-align: center;
  .content {
    color: $font-b-2;
    font-size: 14px;
    line-height: 22px;
    span,
    i {
      color: $theme-b-1;
      font-style: normal;
      cursor: pointer;
    }
    i {
      font-weight: bold;
    }
  }
  .btn-confirm {
    margin: 30px auto 16px;
    width: 240px;
    line-height: 34px;
    font-size: 14px;
    border-radius: 6px;
    color: #fff;
    background-color: $theme-b-1;
    cursor: pointer;
    &.disabled {
      opacity: 0.4;
    }
  }
  .contact-us {
    padding-bottom: 24px;
    color: $font-b-3;
    font-size: 14px;
    line-height: 22px;
    span {
      color: $theme-b-1;
      cursor: pointer;
    }
  }
}
</style>
