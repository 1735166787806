import actions from './actions';

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions
};
