const handleAllInterestIndustry = data => {
  if (!data) {
    return [];
  }
  console.log('入参', data);
  return data.map(item => ({
    id: item.industryId,
    name: item.industryName
  }));
};

export default {
  handleAllInterestIndustry
};
