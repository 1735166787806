const SAAS_ADMIN_RESOURCE_AUTH_MAP = [
  'AUTH_nav_admin',
  '/saas-admin/dealer-user-management',
  '/saas-admin/whitelist-management',
  '/saas-admin/whitelist-institution-management',
  '/saas-admin/dealer-user-management/dealer-import-failed',
  '/saas-admin/whitelist-management/whitelist-import-failed'
];

const INVALID_SMS_CODE_ERROR_CODE = 550000;

const UNAUTHORIZED_MOBILE_ERROR_CODES = [500029, 500017, 10222, 550010];

const NON_COOPERATIVE_ORGANIZATION_ERROR_CODE = 500041;

// 无账号用户
const NON_EXIST_MOBILE_ERROR_CODE = 500017;

// 试用中
const TRAILING_ERROR_CODE = 500022;

// 试用结束
const TRAIL_OVER_ERROR_CODE = 500023;

// 未认证
const NON_AUTHENTICATION_ERROR_CODE = 500024;

const TRAIL_USER_MOBILE_ERROR_CODES = [
  TRAILING_ERROR_CODE,
  TRAIL_OVER_ERROR_CODE
];
export const NON_MOBILE_AUTHENTICATION_OVER_48HOURS_ERROR_CODE = 500018;
export default {
  SAAS_ADMIN_RESOURCE_AUTH_MAP,
  INVALID_SMS_CODE_ERROR_CODE,
  UNAUTHORIZED_MOBILE_ERROR_CODES,
  NON_COOPERATIVE_ORGANIZATION_ERROR_CODE,
  NON_EXIST_MOBILE_ERROR_CODE,
  TRAIL_USER_MOBILE_ERROR_CODES,
  NON_AUTHENTICATION_ERROR_CODE
};
