import { Service } from '@alpha-pai/base';

class PublicAccountService extends Service {
  async getAllArticle(options) {
    const { publicAccountDao } = this.dataflow.dao;
    const { AccountArticleListResponse } = this.dataflow.formatters;
    const { data } = await publicAccountDao.getAllArticle(options);
    return new AccountArticleListResponse({ ...data, type: options.data.type });
  }

  async getFollowPublicAccount(options) {
    const { publicAccountDao } = this.dataflow.dao;
    const { FollowAccountListResponse } = this.dataflow.formatters;
    const { data } = await publicAccountDao.getFollowPublicAccount(options);
    return new FollowAccountListResponse({ data });
  }
}

export default PublicAccountService;
