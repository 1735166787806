/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-06-19 10:24:52
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-13 18:56:57
 */
import { createApp } from '@vue/composition-api';
import DedicatedAccountManager from './DedicatedAccountManager.vue';

export default function tip(store, otherOptions = {}) {
  setTimeout(() => {
    let options = {};
    options = store.state.readingStore.customerManager;
    return new Promise((resolve, reject) => {
      const el = document.createElement('div');
      document.body.appendChild(el);
      const instance = createApp(DedicatedAccountManager, {
        options,
        enterBy: otherOptions.enterBy || '',
        resolve,
        reject,
        callback: () => {
          instance.unmount();
          // debugger; 这里需要注意，vue3 写法需要验证下。
          // document.body.removeChild(el);
        }
      });
      instance.mount(el);
    });
  }, 300);
}
