<template>
  <Prompt :visible.sync="visible" @close="cancel">
    <template slot="body">
      <div class="content-warp">
        <div class="left"><i :class="iconClasName"></i></div>
        <div class="right">
          <div class="title">{{ title }}</div>
          <div class="content-text" v-if="info">
            <p>{{ info }}</p>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="btn-group">
        <custom-button
          class="btn"
          type="info"
          size="medium"
          :label="cancelBtnText"
          @click="cancel"
        />
        <custom-button
          class="btn"
          type="primary"
          size="medium"
          :label="confirmBtnText"
          @click="confirm"
        />
      </div>
    </template>
  </Prompt>
</template>

<script>
import Prompt from '@/modules/reading/components/prompt/Prompt.vue';

export default {
  components: {
    Prompt
  },
  data() {
    return {
      visible: false,
      messageData: {}
    };
  },
  computed: {
    type() {
      return this.messageData.type || 'warning';
    },
    title() {
      return this.messageData.title || '';
    },
    info() {
      return this.messageData.info || '';
    },
    cancelBtnText() {
      return this.messageData.cancelBtnText || '取消';
    },
    confirmBtnText() {
      return this.messageData.confirmBtnText || '确定';
    },
    iconClasName() {
      return ['iconfont', `icon-${this.type}`, this.type];
    }
  },
  methods: {
    async open({ message }) {
      this.visible = true;
      this.messageData = message;
    },
    closePrompt() {
      this.visible = false;
    },
    cancel() {
      this.$emit('cancel');
      this.closePrompt();
    },
    confirm() {
      this.$emit('confirm');
      this.closePrompt();
    },
    refresh() {
      this.$emit('refresh');
      this.closePrompt();
    }
  }
};
</script>

<style scoped lang="scss">
.content-warp {
  display: flex;
  justify-content: space-between;
  .left {
    height: 32px;
    line-height: 32px;
    .iconfont {
      font-size: 32px;
      margin-right: 16px;
      &.warning {
        color: $orange-1;
      }
      &.success {
        color: $green-1;
      }
      &.error {
        color: $red-1;
      }
    }
  }

  .right {
    flex: 1;
    .title {
      color: $font-b-1;
      font-size: 18px;
      font-weight: 600;
      margin-top: 4px;
      line-height: 26px;
      letter-spacing: 0.06em;
    }

    .content-text {
      color: $font-b-2;
      margin-top: 12px;
      word-break: break-all;
      font-size: 14px;
      min-height: 30px;

      p {
        line-height: 22px;
      }
    }
  }
}

.btn-group {
  display: flex;
  justify-content: flex-end;
  padding-left: 54px;
  .custom-button + .custom-button {
    margin-left: 10px;
  }

  .btn {
    width: 92px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}
</style>
