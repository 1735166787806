/*
 * @Copyright © 2022 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   : 默认文件描述
 * @Author        : silverbulllet@163.com
 * @Date          : 2022-07-27 15:46:13
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2024-11-06 11:28:08
 */
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import VueCompositionApi from '@vue/composition-api';
import CustomUI from '@/common/components/ui';
import plugins from '@/common/plugins';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(weekday);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekdays: [
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六'
  ]
});

Vue.use(VueCompositionApi);

Vue.use(Vuex);

Vue.use(VueRouter);

Vue.use(ElementUI);

Vue.use(CustomUI);

Vue.config.productionTip = false;

Vue.use(plugins);

Vue.config.ignoredElements = [
  ...Vue.config.ignoredElements,
  'wx-open-launch-weapp',
  'wx-open-launch-app'
];
