<template>
  <div class="cp-end-of-probation-modal" v-if="visible">
    <div class="cp-end-of-probation-content">
      <h1>试用已结束</h1>
      <template v-if="isPurchasers">
        <p>立即认证身份</p>
        <p>可获得30天机构付费会员权益</p>
        <p>
          <span>解锁更多投研用户专属内容</span>
          <a href="javascript:;" @click="handleIntroShow">
            了解更多<i class="el-icon-arrow-right" />
          </a>
        </p>
      </template>
      <template v-else>
        <p>立即认证身份</p>
        <p>开启自由产品使用</p>
        <p>解锁更多投研用户专属内容</p>
      </template>
      <div class="btn-box">
        <custom-button
          type="default"
          size="medium"
          label="我知道了"
          @click="handleClose"
          class="btn-confirm left"
        />
        <custom-button
          type="primary"
          size="medium"
          label="立即认证"
          @click="handleConfirm"
          class="btn-confirm right"
        />
      </div>
      <AuthenticationModal
        ref="authenticationModal"
        enter-by="试用结束弹窗"
        @close="onClose"
      />
    </div>
  </div>
</template>

<script>
import SetTracker from '@/common/utils/web-setTracker';
import { mapGetters, mapActions } from 'vuex';
import { CommonUtil } from '@alpha-pai/core/utils';
import { getCustomerManager } from '@/services/user';

export default {
  name: 'EndOfProbationModal',
  components: {
    AuthenticationModal: () => import('../authentication-modal')
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isPurchasers: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  mounted() {
    SetTracker('Web_Try_End_View');
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    isPurchasers: {
      immediate: true,
      handler(v) {
        if (!v) {
          return;
        }
        getCustomerManager().then(res => {
          if (res && res.data) {
            this.$store.commit('readingStore/setCustomerManager', res.data);
          }
        });
      }
    }
  },
  methods: {
    ...mapActions('homeStore', ['setGlobalAppData', 'removeGlobalAppData']),

    handleIntroShow() {
      this.setTracker('了解更多');
      this.$emit('intro', '试用结束');
    },
    /**
     * @description: 立即认证
     * @return {*}
     */
    async handleConfirm() {
      this.setTracker('立即认证');

      this.$refs.authenticationModal.handleOpen(result => {
        if (result.type === 'email') {
          setTimeout(() => {
            const { url = '/' } = this.$router.currentRoute.query;
            this.$router.push(url);
          }, 300);
        }
      });
    },
    onClose() {
      this.removeGlobalAppData().then(() => {
        this.$emit('close');
        CommonUtil.userLogout();
      });
    },
    handleClose() {
      this.setTracker('我知道了');
      this.$emit('close');
    },
    setTracker(button_name) {
      SetTracker('Web_Try_End_Button_Click', {
        data: {
          button_name
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/themes/default/variables/index.scss';

.cp-end-of-probation-modal {
  z-index: 1999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .cp-end-of-probation-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 486px;
    height: 250px;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #dfeaff 0%, #fff 45.05%);
    background-color: #fff;
    text-align: center;
    .btn-box {
      display: flex;
      align-content: center;
      justify-content: center;
      margin-top: 32px;
      .btn-confirm {
        &:first-child {
          margin-right: 24px;
        }
        &.right {
          margin-left: 0px;
        }
      }
    }
    h1 {
      margin-top: 48px;
      line-height: 26px;
      font-size: 18px;
      font-weight: 600;
      color: $font-b-1;
    }
    p {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $font-b-2;
      & + p {
        margin-top: 4px;
      }
    }
    .img-wrap {
      overflow: hidden;
      margin: 24px auto 0;
      width: 120px;
      height: 120px;
      border: 1px solid $theme-b-4;
      border-radius: 6px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .btn-confirm {
      // position: absolute;
      // bottom: 32px;
      // left: 50%;
      // transform: translate(-50%);
      width: 200px;
      &.left {
        width: 132px;
      }
    }
  }
}
</style>
