/*
 * @Copyright © 2023 讯兔科技, All Rights Reserved.
 * @该代码受知识产权法律保护.如有侵权，讯兔科技保留采用法律手段追究法律责任的权利。
 * @Description   :
 * @Author        : sunjx01@rabyte.cn
 * @Date          : 2023-05-17 10:53:55
 * @LastEditors   : sunjinxiang sunjx01@rabyte.cn
 * @LastEditTime  : 2023-09-15 13:44:22
 */
import Dao from '@/adapters/dao';
class ReadProfileDao extends Dao {
  // 获取我的收藏-列表
  async getFavoriteList(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/favorite/list',
      ...options,
      headers: {
        'x-from': 'web'
      }
    });
  }

  // 获取我的阅读历史-列表
  async getHistoryList(options = {}) {
    return await this.fetch({
      url: '/external/alpha/api/reading/history/list',
      method: 'get',
      ...options
    });
  }
}

export default ReadProfileDao;
